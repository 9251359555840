import { useSelector, useDispatch } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from "@mui/material";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import CheckCircle from "./CheckCircle";
import { planSelector, setShowCheckoutModel } from "../../store/payment";
import { userInfoSelector } from "../../store/user";
import { availablePlans } from "../../utils/paymentPlans";
import xclose from "../../assets/x-close.svg";

const styles = {
  currentPlanBtn: {
    width: "100%",
    border: "2px solid #E8ECEF",
    borderRadius: "100px",
    mb: "16px",
    mt: "16px",
    color: "#d0d4d6 !important",
    bgcolor: "#F7F7F7",
    pointerEvents: "auto !important",
    cursor: "not-allowed !important",
  },
  upgradeBtn: {
    width: "100%",
    border: "2px solid #0659F9",
    borderRadius: "100px",
    mb: "16px",
    mt: "16px",
    color: "#FEFEFE",
    backgroundColor: "#0659F9",
  },
};

const CompareFeature = () => {
  const plans = useSelector(planSelector);
  const { plan: defaultPlan } = useSelector(userInfoSelector);
  const dispatch = useDispatch();

  const onChoosePlan = (plan) => {
    dispatch(
      setShowCheckoutModel({ plan, show: true, isPayAsYouGo: false })
    );
  };

  return (
    <Box
      sx={{
        border: "2px solid #E8ECEF",
        borderRadius: "12px",
        mb: "24px",
        overflow: "hidden",
        fontFamily: "HelveticaNowDisplay",
      }}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandIcon sx={{ width: "24px", height: "24px", color: "#000000" }} />}
          id="compare-features-header"
          aria-controls="compare-features-content"
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "32px",
              color: "#141718",
            }}
          >
            Compare Features
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box
            sx={{
              borderTop: "1px solid #6C727526",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "40px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#141718",
              padding: "20px 0px",
            }}
          >
            PER UPLOAD you’ll get:
          </Box>

          <Box>
            <GroupCompareFeatures
              title={"WRITTEN CONTENT"}
              compareValues={[
                {
                  value: "Writing Engine",
                  result: [
                    "Deciphr AI 1.0",
                    "Deciphr AI 2.0",
                    "Deciphr AI 2.0",
                  ],
                },
                {
                  value: "Show Notes",
                  result: [1, 1, 1],
                },
                {
                  value: "Summary",
                  result: [1, 1, 1],
                },
                {
                  value: "Quotes",
                  result: [5, 10, 10],
                },
                {
                  value: "Chapter Summary",
                  result: [1, 1, 1],
                },
                {
                  value: "Transcript",
                  result: [1, 1, 1],
                },
                {
                  value: "Thought Leadership Articles",
                  result: [1, 1, 1],
                },
              ]}
            />

            <Box sx={{ height: "30px" }} />

            <GroupCompareFeatures
              title={"MEDIA CONTENT"}
              compareValues={[
                {
                  value: "Audiograms per Content Suite",
                  result: [0, 0, 5],
                },
                {
                  value: "Video Reels per Content Suite",
                  result: [0, 0, 5],
                },
                {
                  value: "AI Clip Finder",
                  result: [false, false, true],
                },
                {
                  value: "AI Face Tracking",
                  result: [false, false, true],
                },
                {
                  value: "Auto Video Splicing",
                  result: [false, false, true],
                },
                {
                  value: "Live captions",
                  result: [false, false, true],
                },
                {
                  value: "Max File Length",
                  result: ["40 minutes", "90 minutes", "Unlimited"],
                },
              ]}
            />

            <Box sx={{ height: "30px" }} />

            <GroupCompareFeatures
              title={"OTHER FEATURES"}
              compareValues={[
                {
                  value: "Encryption Security & Confidentiality",
                  result: [true, true, true],
                },
                {
                  value: "Batch file export",
                  result: [true, true, true],
                },
                {
                  value: ".mp4 and .mp3 compatibility",
                  result: [true, true, true],
                },
                {
                  value: "Filler word removal",
                  result: [
                    `“um” & “uh”`,
                    `“um” & “uh”`,
                    "20 filler words and repeated words",
                  ],
                },
                {
                  value: "Find & Replace",
                  result: [false, true, true],
                },
                {
                  value: "Audio clean-up",
                  result: [false, true, true],
                },
                {
                  value: "Auto speaker identification",
                  result: [false, true, true],
                },
                {
                  value: "Remove Default Watermark",
                  result: [false, true, true],
                },
                {
                  value: "Edit Summary, Show Notes, Timestamps",
                  result: [false, true, true],
                },
                {
                  value: "In-App Transcript Editor",
                  result: [false, true, true],
                },
                {
                  value: "Transcript Review & Proofread",
                  result: [false, true, true],
                },
                {
                  value: "Max File Size",
                  result: ["250MBS", "1GB", "Unlimited"],
                },
                {
                  value: ".srt export",
                  result: [false, false, true],
                },
                {
                  value: "Customer Support",
                  result: [
                    "48HRS: Email",
                    "24HRS: Email",
                    "24HRS: Email + Account Manager",
                  ],
                },
                {
                  value: "Batch Upload",
                  result: [false, false, "Contact Sales"],
                },
                {
                  value: "Personalized Search Engine",
                  result: [false, false, "Contact Sales"],
                },
                {
                  value: "Content Library Search",
                  result: [false, false, "Contact Sales"],
                },
                {
                  value: "API Access",
                  result: [false, false, "Contact Sales"],
                },
              ]}
            />

            <Grid
              container
              sx={{
                borderTop: "1px solid #6C727526",
              }}
            >
              <Grid item xs={2.4} sx={compareStyled.valueContainer}></Grid>

              <Grid
                item
                xs={3.2}
                sx={[
                  compareStyled.compareItem,
                  { padding: "20px 30px 40px 20px", bgcolor: "#F7F7F7" },
                ]}
              >
                <Button
                  disabled={plans[0].name === defaultPlan}
                  onClick={() => onChoosePlan(plans[0])}
                  sx={
                    (plans[0].name === defaultPlan) ? styles.currentPlanBtn : styles.upgradeBtn
                  }
                >
                  {
                    availablePlans[defaultPlan]?.includes(plans[0].name)
                      ? plans[0].name === defaultPlan
                        ? "Current Plan"
                        : "Downgrade"
                      : "Upgrade"
                  }
                </Button>
              </Grid>

              <Grid
                item
                xs={3.2}
                sx={[
                  compareStyled.compareItem,
                  { padding: "20px 30px 40px 20px", bgcolor: "#0200FF12" },
                ]}
              >
                <Button
                  disabled={plans[1].name === defaultPlan}
                  onClick={() => onChoosePlan(plans[1])}
                  sx={
                    (plans[1].name === defaultPlan) ? styles.currentPlanBtn : styles.upgradeBtn
                  }
                >
                  {
                    availablePlans[defaultPlan]?.includes(plans[1].name)
                      ? plans[1].name === defaultPlan
                        ? "Current Plan"
                        : "Downgrade"
                      : "Upgrade"
                  }
                </Button>
              </Grid>

              <Grid
                item
                xs={3.2}
                sx={[
                  compareStyled.compareItem,
                  { padding: "20px 30px 40px 20px", bgcolor: "#232627" },
                ]}
              >
                <Button
                  disabled={plans[2].name === defaultPlan}
                  onClick={() => onChoosePlan(plans[2])}
                  sx={
                    (plans[2].name === defaultPlan) ? styles.currentPlanBtn : styles.upgradeBtn
                  }
                >
                  {
                    availablePlans[defaultPlan]?.includes(plans[2].name)
                      ? plans[2].name === defaultPlan
                        ? "Current Plan"
                        : "Downgrade"
                      : "Upgrade"
                  }
                </Button>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* <Box
        sx={{
          padding: "22px 24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "32px",
            color: "#141718",
          }}
        >
          Compare Features
        </Box>
        <Box
          onClick={() => setIsExpanded((state) => !state)}
          sx={{
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        </Box>
      </Box> */}
    </Box>
  );
};

const GroupCompareFeatures = ({ title, compareValues }) => {
  return (
    <>
      <Box sx={compareStyled.headerTitle}>{title}</Box>
      {compareValues.map((item, index) => (
        <Grid
          container
          key={index}
          sx={{
            borderTop: "1px solid #6C727526",
          }}
        >
          <Grid item xs={2.4} sx={compareStyled.valueContainer}>
            {item.value}
          </Grid>

          <Grid
            item
            xs={3.2}
            sx={[
              {
                bgcolor: "#F7F7F7",
                color: "#141718",
              },
              compareStyled.compareItem,
            ]}
          >
            {typeof item.result[0] !== "boolean" ? (
              item.result[0]
            ) : item.result[0] === true ? (
              <CheckCircle color="#0659F9" />
            ) : (
              <img src={xclose} alt="Not available" />
            )}
          </Grid>

          <Grid
            item
            xs={3.2}
            sx={[
              {
                bgcolor: "#0200FF12",
                color: "#141718",
              },
              compareStyled.compareItem,
            ]}
          >
            {typeof item.result[1] !== "boolean" ? (
              item.result[1]
            ) : item.result[1] === true ? (
              <CheckCircle color="#0659F9" />
            ) : (
              <img src={xclose} alt="Not available" />
            )}
          </Grid>

          <Grid
            item
            xs={3.2}
            sx={[
              {
                bgcolor: "#232627",
                color: "#FFFFFF",
              },
              compareStyled.compareItem,
            ]}
          >
            {typeof item.result[2] !== "boolean" ? (
              item.result[2]
            ) : item.result[2] === true ? (
              <CheckCircle color="#FFFFFF" />
            ) : (
              <img src={xclose} alt="Not available" />
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const compareStyled = {
  compareItem: {
    padding: "20px 10px",
    fontFamily: "HelveticaNowDisplay",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  valueContainer: {
    padding: "20px 10px",
    fontFamily: "HelveticaNowDisplay",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 600,
    color: "#141718",
  },
  headerTitle: {
    height: "30px",
    backgroundColor: "#232627",
    fontSize: "15px",
    lineHeight: "40px",
    color: "#FFFFFF",
    fontWeight: 700,
    display: "flex",
    paddingLeft: "10px",
    alignItems: "center",
  },
};

export default CompareFeature;

import Rollbar from "rollbar";

export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_ENV,
  server: {
    root: process.env.REACT_APP_BACKEND_URL,
  },
};
let userId = null;
export const getUserId = () => {
  if (!userId) {
    userId = JSON.parse(localStorage.getItem("userInfo"))?.user?.localId;
  }
  return userId
}

export const rollbar = new Rollbar(rollbarConfig);

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function CheckoutInput({ id, label, placeholder, required = false, isValid, onChange }) {
  return (
    <Box sx={{ py: "20px", px: "4px" }}>
      <Typography
        component="label"
        htmlFor={id || label}
        sx={{
          display: "block",
          fontFamily: "HelveticaNowDisplay",
          fontSize: "14px",
          fontWeight: 500,
          color: "#343839",
          lineHeight: "24px",
          pb: "10px",
          pl: "8px",
        }}
      >
        {label}
      </Typography>

      <TextField
        id={id || label}
        placeholder={placeholder}
        required={required}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          display: "block",
          "& .MuiInputBase-root": {
            borderRadius: "12px",
            display: "block",
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
            borderColor: (isValid !== undefined)
              ?
              (isValid === true) ? "#45B26B" : "#EF466F"
              : "#e8ecee",
          },

          "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#0659F9",
            },
          },

          "& .MuiInputBase-input": {
            fontFamily: "HelveticaNowDisplay",
            fontWeight: 500,
            fontSize: "14px",
            color: "#141718",
          },
        }}
      />
    </Box>
  );
}

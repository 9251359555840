import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { setToast } from "./toastSlice";
import {
  exportContent,
  generateAudiogram,
  updateAudiogramBackground,
} from "../utils/apis";
import { downloadVideo } from "../pages/newViewTranscript/audiogram/downloadUtil";

const audiogramSlice = createSlice({
  name: "audiogram",
  initialState: {
    docId: "",
    audigramMeta: {},
    audioFile: "",
    selectedVideo: "",
    openAudioGramPreview: false,
    audiogram: [],
    audiogramBackground: [],
    time: 0,
    progress: 0,
    downloadLoader: null,
    apidelay: 10000, // 10 seconds
    isAudiogramAvailable: false,
    isAudiogramGenerated: false,
  },

  reducers: {
    setOpenAudioGramPreview: (state, action) => {
      state.openAudioGramPreview = action.payload.isOpen;
      state.audigramMeta = action.payload.meta;
    },
    selectedVideo: (state, action) => {
      state.selectedVideo = action.payload;
    },
    setAudiogramData: (state, action) => {
      state.audiogram = action.payload.audiogram;
      state.isAudiogramAvailable =
        action.payload.audiogram && action.payload.audiogram.length > 0;
      state.audioFile = action.payload.audioFile;
      state.audiogramBackground = action.payload.audiogramBackground;
      state.selectedVideo = action.payload.selectedVideo;
      state.docId = action.payload.docId;
      state.isAudiogramGenerated = action.payload.audiogram?.some(
        (v) => v.video_url
      );
    },
    setAudiogram: (state, action) => {
      state.audiogram = action.payload;
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setLoading: (state, action) => {
      state.downloadLoader = action.payload;
    },
  },
});

export const {
  setOpenAudioGramPreview,
  setAudioFile,
  selectedVideo,
  setAudiogramData,
  setAudiogram,
  setTime,
  setProgress,
  setLoading,
} = audiogramSlice.actions;

export const openAudioGramPreviewSelector = (state) =>
  state.audiogram.openAudioGramPreview;
export const audigramMetaSelector = (state) => state.audiogram.audigramMeta;
export const audioFileSelector = (state) => state.audiogram.audioFile;
export const selectedVideoSelector = (state) => state.audiogram.selectedVideo;
export const audiogramSelector = (state) => state.audiogram.audiogram;
export const audiogramBackgroundSelector = (state) =>
  state.audiogram.audiogramBackground;
export const searchAudioGramSelector = (state) => state.audiogram.search;
export const timeSelector = (state) => state.audiogram.time;
export const progressSelector = (state) => state.audiogram.progress;
export const downloadLoaderSelector = (state) => state.audiogram.downloadLoader;
export const isAudiogramAvailableSelector = (state) =>
  state.audiogram.isAudiogramAvailable;
export const isAudiogramGeneratedSelector = (state) =>
  state.audiogram.isAudiogramGenerated;

export const genAudiogram = createAsyncThunk(
  "audiogram/generateAudiogram",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { audiogram } = getState();
    const { docId } = audiogram;
    dispatch(setLoading(payload.audiogram_id));
    try {
      const res = await generateAudiogram(docId, payload);
      downloadVideo(res?.video_url || "", "Quote");
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
    dispatch(setLoading(null));
  }
);

export const changeBackground = createAsyncThunk(
  "audiogram/changeBg",
  async (payload) => {
    const { docId, backgroundId } = payload;

    try {
      await updateAudiogramBackground(docId, backgroundId);
    } catch (e) {
      // passively ignore
    }
  }
);

export const downloadAllAudiogram = createAsyncThunk(
  "audiogram/downloadAllAudiogram",
  async (type, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { audiogram } = getState();
    const { docId } = audiogram;
    const params = {
      audiogram: type === "audiograms" ? 1 : 0,
      videoreels: type === "video reels" ? 1 : 0,
      summary: 0,
      quotes: 0,
      chapters: 0,
      transcript: 0,
    };

    let url = "";
    if (type === "audiograms") {
      url = `${window.location.origin}/download?$summary=0&quotes=0&chapters=0&transcript=0&audiogram=1&videoreels=0`;
    } else if (type === "video reels") {
      url = `${window.location.origin}/download?$summary=0&quotes=0&chapters=0&transcript=0&audiogram=0&videoreels=1`;
    }

    try {
      const tab = window.open(url, Date.now());
      tab.focus();
      const data = await exportContent({ docId, params });
      tab.location = data.url;
      setTimeout(() => {
        tab.close();
      }, 3000);
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
  }
);

export default audiogramSlice.reducer;

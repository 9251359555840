import { createRef, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, TextField, Typography, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AuthInput from "./components/authInput";
import { GlobalData } from "../../context";
import { init, schema, loginFieldMap } from "../../utils/constants";
import { loginApi } from "../../utils/authSeq";
import { getUserInfo } from "../../store/user";
import { analytics } from "../../utils/GTM";
import EmailIcon from "../../assets/email.svg";
import PasswordIcon from "../../assets/password.svg";
import { get2FA } from "utils/apis";

const IconMap = {
  email: EmailIcon,
  password: PasswordIcon,
};

const CODE_LENGTH = 6;

const NewLogin = () => {
  const [currentUser, setCurrentUser] = useState();
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(null);
  const [loginStage, setLoginStage] = useState("login"); // stage: "login" | "2fa"
  const [twoFactorCode, setTwoFactorCode] = useState(["", "", "", "", "", ""]);
  const [inputRefs] = useState(() =>
    Array.from({ length: CODE_LENGTH }, () => createRef())
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { openAlert, setMessage, setSeverity, setLogin } =
    useContext(GlobalData);
  const formik = useFormik({
    initialValues: init("login"),
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema("login")),
    onSubmit: (value) => handleLogin(value),
  });

  const handleLogin = async (value) => {
    setLoader(true);
    try {
      // const response = await get2FA(value);
      // console.log("response", response);
      // setLoginStage("2fa");

      // TODO: remove this BLOCK once 2FA is working (waiting on AWS)
      await loginApi(
        value,
        location.state?.referrer,
        (authData) => {
          setLogin(true);
          setCurrentUser(authData.user);
          // dispatch(getUserInfo());

          analytics.track("User Successfully Logged in");
          analytics.identify(authData.user.localId, {
            email: authData.user.email,
            name: authData.user.displayName,
          });
        },
        errorHandler
      );
      // ENDBLOCK
    } catch (err) {
      errorHandler(err?.response?.data?.message);
    }
    setLoader(false);
  }

  const handle2FASubmit = async (code) => {
    setLoader(true);

    let verificationCode;
    if (code) {
      verificationCode = code;
    } else {
      verificationCode = twoFactorCode.join("");
    }

    const email = formik.values.email;
    const value = { email, code };

    await loginApi(
      value,
      location.state?.referrer,
      (authData) => {
        setLogin(true);
        setCurrentUser(authData.user);
        // dispatch(getUserInfo());

        analytics.track("User Successfully Logged in");
        analytics.identify(authData.user.localId, {
          email: authData.user.email,
          name: authData.user.displayName,
        });
      },
      errorHandler
    );

    setLoader(false);
  };

  const errorHandler = (err) => {
    setSeverity("error");
    openAlert();
    setMessage(err);
  };

  const handleInputChange = (value, index) => {
    const newValues = twoFactorCode.map((current, currIdx) => {
      if (currIdx === index) {
        return value;
      } else {
        return current;
      }
    });
    setTwoFactorCode(newValues);

    if (index < CODE_LENGTH - 1 && value !== "") {
      const nextInput = inputRefs[index + 1];
      nextInput?.current?.focus();
    }

    // If this is the final text box, and all values are filled,
    // automatically submit the form.
    if (index === CODE_LENGTH - 1) {
      const allInputsFilled = newValues.every(val => val.trim() !== "");
      if (allInputsFilled) {
        handle2FASubmit(newValues.join(""));
      }
    }
  };

  /**
   * When pasting on any of the 2FA input boxes,
   * check if it's the same length as the 2FA code.
   * If yes, overwrite the current text with the clipboard value
   * and automatically submit the form.
   */
  const handlePaste = (event) => {
    event.preventDefault();
    const pasted = event.clipboardData.getData("text/plain");
    const asList = pasted.split("");

    if (asList.length !== CODE_LENGTH) {
      errorHandler(`2FA code must be ${CODE_LENGTH} characters long`);
      return;
    }

    setTwoFactorCode(asList);
    handle2FASubmit(pasted);
  };

  useEffect(() => {
    if (location.state && location.state.message) {
      setAlert(location.state.message);
    }
  }, [location]);

  if (loginStage === "2fa") {
    return (
      <Box sx={styles.twoFactorRoot}>
        <Typography sx={styles.twoFactorHeading}>Check your email</Typography>
        <Typography sx={styles.twoFactorSubtext}>
          We've sent a verification code to <span style={{ color: "#000" }}>{formik.values.email}</span>
        </Typography>

        <Divider sx={{ mb: "50px", width: "100%", mt: "30px" }} />

        <Box sx={{ display: "flex", mt: "20px", mb: "70px" }}>
          {[...Array(CODE_LENGTH).keys()].map(num => (
            <input
              type="text"
              style={{
                border: "1px solid black",
                height: "48px",
                width: "48px",
                marginLeft: "5px",
                marginRight: "5px",
                borderRadius: "10px",
                borderColor: "#C5CBD3",
                paddingLeft: "15px",
                fontSize: "32px",
              }}
              maxLength={1}
              onChange={(e) => handleInputChange(e.target.value, num)}
              value={twoFactorCode[num]}
              ref={inputRefs[num]}
              key={num}
              onPaste={handlePaste}
            />
          ))}
        </Box>

        <LoadingButton
          sx={{
            ...styles.continueButton,
          }}
          disableElevation
          onClick={handle2FASubmit}
          loading={loader}
        >
          Verify Email
        </LoadingButton>

        <Typography sx={styles.twoFactorResend}>
          Didn't receive the code? <span onClick={formik.handleSubmit} style={styles.twoFactorResendSpan}>Resend.</span>
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={styles.root}>
      <Box>
        <Typography sx={styles.heading}>
          Welcome Back
        </Typography>

        <Typography sx={styles.subtext}>
          Don't have an account?&nbsp;
          <Typography sx={styles.subtextLink} onClick={() => navigate("/auth/signup")}>
            Sign up
          </Typography>
          .
        </Typography>
      </Box>

      <Divider sx={{ my: "30px" }}/>

      {alert !== null && (
        <Box
          sx={{
            p: "12px 16px",
            m: "15px auto",
            bgcolor: "#EF466F",
            borderRadius: "12px",
          }}
        >
          <Typography
            role="alert"
            sx={{
              color: "#FFF",
              fontSize: "15px",
              fontWeight: 600,
            }}
          >
            {alert}
          </Typography>
        </Box>
      )}

      <Box>
        <Box sx={{ ...styles.formControl, mr: "12px" }}>
          <Typography sx={styles.formLabel}>Email</Typography>
          <TextField
            variant="outlined"
            sx={styles.formInput}
            id="email"
            name="email"
            type="email"
            placeholder="Enter email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {Boolean(formik.errors.email && formik.touched.email) && (
            <Typography sx={styles.formError}>
              Email is {formik.errors.email}
            </Typography>
          )}
        </Box>
        <Box sx={styles.formControl}>
          <Typography sx={styles.formLabel}>Password</Typography>
          <TextField
            variant="outlined"
            sx={styles.formInput}
            id="password"
            name="password"
            type="password"
            placeholder="Enter password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {Boolean(formik.errors.password && formik.touched.password) && (
            <Typography sx={styles.formError}>
              Password is {formik.errors.password}
            </Typography>
          )}

        </Box>

        <Typography sx={styles.forgotPasswordLink} onClick={() => navigate("/auth/forgot")}>
          Forgot password?
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "32px", width: "100%" }}>
        <LoadingButton
          sx={{
            ...styles.continueButton,
          }}
          disableElevation
          onClick={() => {
            formik.handleSubmit();
          }}
          loading={loader}
        >
          Login
        </LoadingButton>
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    width: { xs: "100%", md: "45%" },
    background: "white",
    backgroundColor: "transparent",
    py: { xs: "100px", md: "calc(102px + 2vh)" },
    minHeight: { xs: "100vh", lg: "unset" },
    paddingBottom: "50px",
    mx: "auto",
    display: "flex",
    flexDirection: "column",
  },

  heading: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#10181E",
  },

  subtext: {
    fontSize: { xs: "14px", lg: "16px" },
    fontWeight: "400",
    lineHeight: "24px",
    mt: "24px",
    color: "#667085",
    display: "flex",
  },

  subtextLink: {
    color: "#0659F9",
    fontWeight: "700",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  description: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.02em",
    color: "#667085",
    mt: "5px",
  },

  formLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20.3px",
    letterSpacing: "0.2px",
    color: "#10181E",
    mb: "5px",
  },

  formInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: "12px",
        '& fieldset': {
          borderColor: "#DCE0E5",
        },
        '&.Mui-focused fieldset': {
          borderColor: "#DCE0E5",
        },
    },
    width: "100%",
  },

  formControl: {
    my: "18px",
    flex: 1,
  },

  continueButton: {
    background: "#0659F9",
    borderRadius: "44px",
    color: "#fff",
    height: "56px",
    width: "100%",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
  },

  formError: {
    color: "red",
    mt: "4px",
  },

  twoFactorRoot: {
    width: { xs: "100%", md: "fit-content" },
    background: "white",
    backgroundColor: "transparent",
    py: { xs: "100px", md: "calc(102px + 2vh)" },
    minHeight: { xs: "100vh", lg: "unset" },
    paddingBottom: "50px",
    mx: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  twoFactorHeading: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0.02em",
    textAlign: "center",
    mb: "30px",
  },

  twoFactorSubtext: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.02em",
    textAlign: "center",
    color: "#667085",
    mb: "25px",
  },

  twoFactorResend: {
    mt: "30px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "23px",
  },

  twoFactorResendSpan: {
    color: "#0659F9",
    cursor: "pointer",
  },

  forgotPasswordLink: {
    fontSize: "14px",
    fontWeight: "700",
    width: { xs: "100%", sm: "100%", md: "100%" },
    color: "#0659F9",
    textDecoration: "none",
    marginLeft: "5px",
    marginTop: "30px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

export default NewLogin;

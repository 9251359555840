import { Box, Typography, IconButton } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import mediaImg from "../../assets/promoBanner.svg";
import { styles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { analytics } from "../../utils/GTM";

function PromoBanner() {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const ref = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
    analytics.track("User Closed Promo Banner");
  };

  //hide banner after 0.5s when click close button
  //css animation doesn't work with display none
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        ref.current.style.display = "none";
        localStorage.setItem("promoBannerDisabled", true);
      }, 500);
    }
  }, [isOpen]);

  if (localStorage.getItem("promoBannerDisabled")) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={{
        ...styles.root,
        animation: !isOpen ? "closeBanner 0.5s ease-in-out forwards" : "none",
        "@keyframes closeBanner": {
          "0%": {
            transform: "translateY(0)",
            opacity: 1,
          },
          "100%": {
            transform: "translateY(-100%)",
            opacity: 0,
          },
        },
      }}
    >
      <Box>
        <img src={mediaImg} alt="media" />
      </Box>

      <Box>
        <Typography sx={styles.text}>
          Use promo code{" "}
          <span
            style={styles.highlight}
            onClick={() => {
              navigate("/plans");
              analytics.track("User Clicked Promo Banner");
            }}
          >
            LAUNCH2023
          </span>{" "}
          to get 50% off your first month!
        </Typography>
      </Box>

      <Box>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon sx={styles.icon} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default PromoBanner;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton, InputBase, Tooltip, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { getSearchTerm, searchFiles } from "../../store/dai/dashboardSlice";

const SearchWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#fff",
  flexGrow: 1,
  borderRadius: "8px",
  marginLeft: 0,
  width: "100%",
  border: "2px solid #E6E8EC",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#777E91",
  right: "0px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#777E91",
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "HelveticaNowDisplay",
  opacity: "1",
  width: "100%",
  paddingRight: "45px",

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "14px",
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export default function SearchBar() {
  const search = useSelector(getSearchTerm);
  const [value, setValue] = useState(search);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && !event.repeat) {
      event.preventDefault();
      dispatch(searchFiles(event.target.value));

      if (location.pathname !== "/library") {
        navigate("/library");
      }
    }
  };

  const onChange = (event) => {
    setValue(event.target.value);

    if (event.target.value === "") {
      dispatch(searchFiles(""));

      if (location.pathname !== "/library") {
        navigate("/library");
      }
    }
  };

  const onClear = () => {
    setValue("");
    dispatch(searchFiles(""));
  };

  useEffect(() => {
    setValue(search);
  }, [search]);

  return (
    <Tooltip title="Hit enter to search" arrow>
      <SearchWrapper
        sx={{
          display: { xs: "none", md: "flex" },
          mr: { xs: "10px", lg: "24px" },
        }}
      >
        <StyledInputBase
          placeholder="Search library"
          inputProps={{ "aria-label": "search" }}
          value={value}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />

        {value === "" && (
          <SearchIconWrapper>
            <IconButton disabled aria-hidden="true">
              <SearchIcon />
            </IconButton>
          </SearchIconWrapper>
        )}

        {value !== "" && (
          <SearchIconWrapper>
            <IconButton onClick={onClear} aria-label="Clear search">
              <CloseIcon />
            </IconButton>
          </SearchIconWrapper>
        )}
      </SearchWrapper>
    </Tooltip>
  );
}

import { Link, NavLink } from "react-router-dom";
import { Box } from "@mui/material";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
  },

  typography: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    textDecoration: "none",
    color: "#777E91",
    fontFamily: "HelveticaNowDisplay",
  },
};

export default function NavLinks({ show }) {
  if (show) {
    return (
      <Box sx={styles.root}>
        <NavLink
          to="/library"
          style={styles.typography}
          className={(navData) => (navData.isActive ? "active" : "link")}
        >
          Library
        </NavLink>

        <Link
          to="/upload_transcript"
          style={{
            background: "#0659F9",
            color: "#FFF",
            padding: "1px 20px",
            lineHeight: "38px",
            fontSize: "14px",
            fontWeight: "700",
            borderRadius: "999px",
            fontFamily: "HelveticaNowDisplay",
            textDecoration: "none",
            textAlign: "center",
            marginLeft: "34px",
          }}
        >
          Upload
        </Link>
      </Box>
    );
  }

  return null;
}

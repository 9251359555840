import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import PayUsage from "./payUsage";
import { payAsYouGo } from "../../utils/paymentPlans";

function Pay() {
  return (
    <Box sx={{ background: "#EBEAFD", borderRadius: "24px", padding: "58px" }}>
      <Typography
        sx={{
          fontFamily: "HelveticaNowDisplay",
          fontWeight: 700,
          color: "#23262F",
          lineHeight: "56px",
          fontSize: "48px",
          pb: " 16px",
        }}
      >
        Pay as you go
      </Typography>
      <Grid container spacing={2} sx={{ alignItems: "end" }}>
        <Grid item xs={6}>
          <Typography
            sx={{
              color: "#6C7275",
              fontSize: "20px",
              fontFamily: "HelveticaNowDisplay",
              pb: "48px",
              lineHeight: "140%",
            }}
          >
            Purchase additional minutes for written content and multimedia
            content of your podcast
          </Typography>

          <PayUsage payload={payAsYouGo[0]} />
        </Grid>
        <Grid item xs={6}>
          <PayUsage payload={payAsYouGo[1]} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Pay;

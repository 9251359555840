import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  setTranscriptData,
  appendTranscriptData,
  toggleStrike,
  changeSpeaker,
  rotateSpeaker,
  split,
  merge,
  replaceInSummary,
  replaceInChapters,
  simpleReplace,
  complexReplace,
} from './transcripts';
import {
  getWorkflowTranscript,
  getPaginatedWorkflowTranscript,
  saveWorkflow,
  updateSpeakers,
  addComment,
  deleteComment,
  completeWorkflow,
  exportTranscript,
  updateComment,
} from '../../utils/enterpriseApi';
import { setToast } from '../toastSlice';
import { ColorMap } from '../../utils/colorMaps';
import { download } from '../../pages/editTranscript/util/player';

const initialState = {
  workflowData: {},
  summary: '',
  transcripts: [],
  transcriptUpdates: [], // keeps track of updates to transcripts via find/replace
  speakers: [],
  speakerNames: [],
  entities: {},
  entitiesMap: {}, // map word indexes to entities, for highlighting purposes
  insights: [],
  keywords: [],
  accuracy: { bad: true, good: true, excellent: true },
  colorMap: {},
  comments: [],
  hasUnsavedChanges: false,
  hasUnsavedInsights: false,
  hasUnsavedKeywords: false,
  isSaving: false,
  lastSavedAt: null,
  editorMode: null,
  highlightedText: null,
  highlightAnchor: null,
  isEntityHighlightDisabled: false,
  searchTerm: '',
  replaceTerm: '',
  isCaseSensitive: false,
  triggerManualSave: false,
  isReplaceLoading: false,
  isWorkflowSaving: false,
  isWorkflowSaved: false,
  isDownloading: false, // flag for export as PDF/docx/txt downloads
  isDownloaded: false,
  isHintsOpen: false, // editor shorcut hints
};

const serialize = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  return data.map((item, i) => {
    const words = item.children[0].children;
    const previousEnd = i > 0 ? data[i - 1].children[0].children.slice(-1)[0].end : 0;
    const nextEnd = i < data.length - 1 ? data[i + 1].children[0].children[0].start : previousEnd;
    return {
      id: item.id.toString().substring(0, 8),
      sp: item.speaker,
      st: words[0].start || previousEnd,
      e: words[words.length - 1].end || nextEnd,
      w: words
        .filter(word => word.text !== '')
        .map((word, index) => ({
          t: word.text,
          st: word.start || (index > 0 ? words[index - 1].end : previousEnd),
          e: word.end || (index < words.length - 1 ? words[index + 1].start : nextEnd),
          c: word.confidence ?? 0,
          d: word.is_removed ?? false,
        })),
    };
  });
};

// Converts { x: [a, b, c], y: [d, e, f] } to { a: x, b: x, c: x, d: y, e: y, f: y }
const invertEntities = (entities) => {
  const entitiesMap = {};

  if (entities) {
    const keys = Object.keys(entities);

    keys.forEach((key) => {
      entities[key].forEach((item) => {
        entitiesMap[item] = key;
      });
    });
  }

  return entitiesMap;
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,

  reducers: {
    reset: () => initialState,

    initializeWorkflow: (state, action) => {
      state.workflowData = action.payload;
      state.summary = action.payload.processed_data.abstract;
      state.insights = action.payload.processed_data.insights || [];
      state.keywords = action.payload.processed_data.keywords || [];
      state.speakers = action.payload.processed_data.speakers || [];
      state.speakerNames = action.payload.processed_data.speakers?.map((speaker) => speaker.name);
      state.lastSavedAt = action.payload.transcript.updated_at || action.payload.updated_at;
    },

    initializeTranscripts: (state, action) => {
      const { lazy, transcripts } = action.payload;

      state.transcripts = transcripts.map((transcript) => {
        const snippet = {
          type: 'snippet',
          id: transcript.id,
          start_timestamp: transcript.start_timestamp,
          start: transcript.start,
          speaker: transcript.speaker,
          children: [{
            type: 'paragraph',
            snippetId: transcript.id,
            children: transcript.words.map((word, index) => {
              return {
                ...word,
                id: index,
                snippetId: transcript.id,
              };
            }),
          }],
        };

        return snippet;
      });

      if (lazy) {
        const event = new CustomEvent("transcript:initialized");

        window.dispatchEvent(event);
      }
    },

    initializeEntities: (state, action) => {
      const allEntities = { ...action.payload.processed_data.entities }; // Create a shallow copy
      delete allEntities['GPE']; // Remove GPE key/value
      const entities = Object.keys(allEntities || {});
      const colorMap = {};

      entities.forEach((entity, index) => {
        colorMap[entity] = { color: ColorMap[index] || ColorMap[0], enabled: true };
      });

      state.entities = allEntities;
      state.entitiesMap = invertEntities(allEntities);
      state.colorMap = colorMap;
    },

    initializeComments: (state, action) => {
      const { transcript } = action.payload;
      const comments = [];

      transcript.forEach((t) => {
        if (t.comments && t.comments.length) {
          t.comments.forEach((comment) => {
            comments.push({
              snippetId: t.id,
              commentId: comment.id,
              start: comment.start_pos,
              end: comment.end_pos,
              comment: comment.comment,
              timestamp: t.start_timestamp,
              name: comment.created_by.first_name,
            });
          });
        }
      });

      state.comments = comments;
    },

    updateSummary: (state, action) => {
      state.summary = action.payload;
      state.hasUnsavedChanges = true;
    },

    setTranscripts: setTranscriptData,
    appendTranscripts: appendTranscriptData,
    strikeToggle: toggleStrike,
    splitParagraph: split,
    mergeParagraph: merge,
    updateSpeaker: changeSpeaker,
    cycleSpeaker: rotateSpeaker,

    toggleAllEntities: (state, action) => {
      const colorMap = JSON.parse(JSON.stringify(state.colorMap));

      for (let key in colorMap) {
        colorMap[key] = { ...colorMap[key], enabled: action.payload };
      }

      state.colorMap = colorMap;
    },

    toggleEntity: (state, action) => {
      const colorMap = JSON.parse(JSON.stringify(state.colorMap));

      colorMap[action.payload.entity] = { ...colorMap[action.payload.entity], enabled: action.payload.enabled };
      state.colorMap = colorMap;
    },

    toggleAllAccuracy: (state, action) => {
      const setting = action.payload;

      state.accuracy = { bad: setting, good: setting, excellent: setting };
    },

    toggleAccuracy: (state, action) => {
      const accuracy = JSON.parse(JSON.stringify(state.accuracy));

      accuracy[action.payload.type] = action.payload.setting;

      state.accuracy = accuracy;
    },

    setIsSaving: (state, action) => {
      state.isSaving = action.payload;
    },

    updateHasUnsavedInsights: (state, action) => {
      state.hasUnsavedInsights = action.payload;
    },

    updateHasUnsavedKeywords: (state, action) => {
      state.hasUnsavedKeywords = action.payload;
    },

    updateWorkflowStatus: (state, action) => {
      state.workflowData.workflow_status = action.payload;
    },

    markChangesSaved: (state) => {
      state.hasUnsavedChanges = false;
    },

    changesSaved: (state) => {
      state.hasUnsavedChanges = false;
      state.isSaving = false;
      state.lastSavedAt = Date.now();
    },

    updateLastSaved: (state) => {
      // Used from async thunks to update the last saved time
      state.lastSavedAt = Date.now();
    },

    workflowChanged: (state) => {
      state.hasUnsavedChanges = true;
    },

    setEditorMode: (state, action) => {
      state.editorMode = action.payload;
    },

    highlight: (state, action) => {
      const selection = window.getSelection();

      const anchorParent = selection.anchorNode.parentElement;
      const focusParent = selection.focusNode.parentElement;
      const text = selection.toString().replace(/\n+/, ' ').trim().replace(/[.,?]$/g, '');
      const startIndex = parseInt(anchorParent.dataset.index);
      const endIndex = parseInt(focusParent.dataset.index);
      const snippetId = action.payload;
      const entity = anchorParent.parentElement.dataset.entity || null;

      state.highlightedText = { text, startIndex, endIndex, snippetId, entity };

      if (selection.type.toLowerCase() === 'range') {
        state.highlightAnchor = startIndex;
      }

      // check if the selection includes partial selection of the previous
      // node, and if so, disable entity selection.
      if (selection.toString().startsWith(' ')) {
        state.isEntityHighlightDisabled = true;
      } else {
        state.isEntityHighlightDisabled = false;
      }
    },

    setHighlightedText: (state, action) => {
      state.highlightedText = action.payload;
    },

    addEntity: (state, action) => {
      const entities = JSON.parse(JSON.stringify(state.entities)) || {};
      const term = action.payload;
      const cleaner = new RegExp(/[.?!]+$/);
      const thing = window.getSelection().toString().replace(cleaner, '');

      if (entities[term]) {
        // Does the currently highlighted thing(s) have an entity term?
        const existingTerm = state.entitiesMap[thing] || null;
        let mapToList;

        if (existingTerm) {
          // Remove previous term
          entities[term] = entities[term].filter((item) => item !== thing);
        }

        state.entitiesMap[thing] = term;
        mapToList = Object.entries(state.entitiesMap);

        // Group by terms
        state.entities = mapToList.reduce((acc, value) => {
          if (!acc[value[1]]) {
            acc[value[1]] = [];
          }

          acc[value[1]].push(value[0]);

          return acc;
        }, {});

        state.hasUnsavedChanges = true;
      }
    },

    updateInsights: (state, action) => {
      const insights = JSON.parse(JSON.stringify(state.insights));
      if (action.payload.summary) {
        insights[action.payload.index].summary = action.payload.summary;
      }
      if (action.payload.timestamp) {
        insights[action.payload.index].timestamp = action.payload.timestamp;
      }
      state.insights = insights;
      state.hasUnsavedInsights = true;
      state.hasUnsavedChanges = true;
    },

    addKeyword: (state, action) => {
      state.keywords.push(action.payload);
      state.hasUnsavedKeywords = true;
      state.hasUnsavedChanges = true;
    },

    removeKeyword: (state, action) => {
      const index = state.keywords.indexOf(action.payload);

      if (index > -1) {
        state.keywords.splice(index, 1);
        state.hasUnsavedKeywords = true;
        state.hasUnsavedChanges = true;
      }
    },

    updateComments: (state, action) => {
      const { snippetId, data } = action.payload;
      const index = state.transcripts.findIndex((t) => t.id === snippetId);

      state.comments.push({
        snippetId,
        commentId: data.id,
        comment: data.comment,
        start: data.start_pos,
        end: data.end_pos,
        timestamp: state.transcripts[index].start_timestamp,
        name: data.created_by.first_name,
      });
    },

    removeAComment: (state, action) => {
      const index = state.comments.findIndex((comment) => comment.commentId === action.payload);

      if (index > -1) {
        state.comments.splice(index, 1);
      }
    },

    updateAComment: (state, action) => {
      state.comments = state.comments.map((comment) => {
        if (comment.commentId === action.payload.id) {
          return { ...comment, comment: action.payload.text }
        } else {
          return comment
        }
      });
    },

    updateSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },

    updateReplaceTerm: (state, action) => {
      state.replaceTerm = action.payload;
    },

    setFindCaseSensitivity: (state, action) => {
      state.isCaseSensitive = action.payload;
    },

    toggleManualSave: (state, action) => {
      state.triggerManualSave = action.payload;
    },

    setReplaceLoading: (state, action) => {
      state.isReplaceLoading = action.payload;
    },

    findReplaceInSummary: replaceInSummary,
    findReplaceInChapters: replaceInChapters,
    simpleFindReplace: simpleReplace,
    complexFindReplace: complexReplace,

    updateSpeakersList: (state, action) => {
      state.speakers = action.payload;
      state.speakerNames = action.payload.map((speaker) => speaker.name);
    },

    setIsWorkflowSaving: (state, action) => {
      state.isWorkflowSaving = action.payload;
    },

    setIsWorkflowSaved: (state, action) => {
      state.isWorkflowSaved = action.payload;
    },

    toggleIsDownloading: (state) => {
      state.isDownloading = !state.isDownloading;
    },

    toggleIsDownloaded: (state) => {
      state.isDownloaded = !state.isDownloaded;
    },

    toggleEditorHints: (state) => {
      state.isHintsOpen = !state.isHintsOpen;
    },
  },
});

export const {
  reset,
  initializeWorkflow,
  initializeEntities,
  initializeComments,
  appendComment,
  updateSummary,
  setTranscripts,
  appendTranscripts,
  initializeTranscripts,
  strikeToggle,
  updateSpeaker,
  cycleSpeaker,
  splitParagraph,
  mergeParagraph,
  toggleAllEntities,
  toggleEntity,
  toggleAllAccuracy,
  toggleAccuracy,
  setIsSaving,
  updateHasUnsavedInsights,
  updateHasUnsavedKeywords,
  updateWorkflowStatus,
  changesSaved,
  markChangesSaved,
  updateLastSaved,
  setEditorMode,
  workflowChanged,
  highlight,
  setHighlightedText,
  addEntity,
  updateInsights,
  addKeyword,
  removeKeyword,
  updateComments,
  removeAComment,
  updateAComment,
  updateSearchTerm,
  updateReplaceTerm,
  setFindCaseSensitivity,
  toggleManualSave,
  setReplaceLoading,
  findReplaceInSummary,
  findReplaceInChapters,
  simpleFindReplace,
  complexFindReplace,
  updateSpeakersList,
  setIsWorkflowSaving,
  setIsWorkflowSaved,
  toggleIsDownloading,
  toggleIsDownloaded,
  toggleEditorHints,
} = editorSlice.actions;

// Selectors
export const getWorkflowData = (state) => state.editor.workflowData;
export const getCurrentStatus = (state) => state.editor.workflowData?.workflow_status;
export const selectHeadline = (state) => state.editor.workflowData?.processed_data.headline;
export const getSummary = (state) => state.editor.summary;
export const getKeywords = (state) => state.editor.keywords;
export const getAudioUri = (state) => state.editor.workflowData?.public_url;
export const getOriginalAudioUri = (state) => state.editor.workflowData?.public_url_original;
export const getDuration = (state) => state.editor.workflowData?.processed_data.duration;
export const getTitle = (state) => state.editor.workflowData?.title;
export const getEditingStart = (state) => state.editor.workflowData?.editing_start_time;
export const getAssignees = (state) => state.editor.workflowData?.assignees;
export const selectTranscripts = (state) => state.editor.transcripts;
export const selectTranscriptUpdates = (state) => state.editor.transcriptUpdates;
export const getSpeakers = (state) => state.editor.speakers;
export const getSpeakerNames = (state) => state.editor.speakerNames;
export const selectEntities = (state) => state.editor.entities;
export const selectEntitiesMap = (state) => state.editor.entitiesMap;
export const selectInsights = (state) => state.editor.insights;
export const selectKeywords = (state) => state.editor.keywords;
export const selectComments = (state) => state.editor.comments;
export const selectAccuracy = (state) => state.editor.accuracy;
export const selectEditorMode = (state) => state.editor.editorMode;
export const selectHasUnsavedChanges = (state) => state.editor.hasUnsavedChanges;
export const getLastSavedAt = (state) => state.editor.lastSavedAt;
export const selectColorMap = (state) => state.editor.colorMap;
export const getHighlightedText = (state) => state.editor.highlightedText;
export const getHighlightAnchor = (state) => state.editor.highlightAnchor;
export const getIsEntityHighlightDisabled = (state) => state.editor.isEntityHighlightDisabled;
export const selectSearchTerm = (state) => state.editor.searchTerm;
export const selectReplaceTerm = (state) => state.editor.replaceTerm;
export const getFindCaseSensitivity = (state) => state.editor.isCaseSensitive;
export const shouldSaveManually = (state) => state.editor.triggerManualSave;
export const getIsReplaceLoading = (state) => state.editor.isReplaceLoading;
export const getIsSaving = (state) => state.editor.isSaving;
export const getIsWorkflowSaving = (state) => state.editor.isWorkflowSaving;
export const getIsWorkflowSaved = (state) => state.editor.isWorkflowSaved;
export const getIsDownloading = (state) => state.editor.isDownloading;
export const getIsDownloaded = (state) => state.editor.isDownloaded;
export const getIsHintsOpen = (state) => state.editor.isHintsOpen;

// Async Thunks
export const getWorkflow = createAsyncThunk('editor/getWorkflow', async (id, thunkAPI) => {
  const { dispatch } = thunkAPI;

  try {
    const data = await getWorkflowTranscript(id);

    dispatch(initializeWorkflow(data));
    dispatch(initializeTranscripts({ transcripts: data.transcript.transcript })); // render immediately

    setTimeout(async () => {
      // Fetch any remaining transcript portions lazily and re-initialize
      const transcript = await getPaginatedWorkflowTranscript(id);

      if (transcript) {
        dispatch(
          initializeTranscripts({
            transcripts: data.transcript.transcript.concat(transcript.transcript),
            lazy: true,
          })
        );
      }

      // Terms and highlighting
      setTimeout(() => {
        dispatch(initializeEntities(data));
      }, 500);

      // Comments
      setTimeout(() => {
        dispatch(
          initializeComments({
            transcript: data.transcript.transcript.concat(transcript.transcript)
          })
        );
      }, 1000);
    }, 300);
  } catch (e) {
    const errorMessage = e.response?.data?.message || e.message;

    dispatch(setToast({ message: errorMessage, severity: 'error' }));
  }
});

export const updateData = createAsyncThunk('editor/updateData', async (payload, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { editor } = getState();

  if (editor.hasUnsavedChanges === false) {
    // Nothing to do here, move on
    return;
  }

  if (editor.isSaving) {
    // Don't trigger another update while in the midst of saving
    return;
  }
  const request = {
    // abstract: editor.summary,
    // headline: editor.workflowData.processed_data.headline,
    // entities: editor.entities,
    tr: serialize(editor.transcripts),
    // tr: payload.transcriptUpdates,
    ...(editor.hasUnsavedInsights && { insights: editor.insights }),
    ...(editor.hasUnsavedKeywords && { keywords: editor.keywords }),
  };
  const { id } = editor.workflowData;

  dispatch(setIsSaving(true));

  try {
    await saveWorkflow(id, request);

    dispatch(changesSaved());
    if (payload && payload.shouldReload) {
      window.location.reload();
    }
    dispatch(updateHasUnsavedInsights(false));
    dispatch(updateHasUnsavedKeywords(false));
  } catch (e) {
    const errorMessage = e.response?.data?.message || e.message;
    dispatch(setToast({ message: errorMessage, severity: 'error' }));
  } finally {
    dispatch(setIsSaving(false));
  }
});

export const findAndReplace = createAsyncThunk('editor/findReplace', async (_, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { editor } = getState();
  const { searchTerm, replaceTerm, isCaseSensitive } = editor;
  const findListLength = searchTerm.split(' ').length;
  const replaceType = (findListLength > 1) ? 'complex' : 'simple';

  // Find and replace in summary
  dispatch(findReplaceInSummary({ find: searchTerm, replaceTerm, isCaseSensitive }));

  // Find and replace in chapters
  dispatch(findReplaceInChapters({ find: searchTerm, replaceTerm, isCaseSensitive }));

  // Find and replace in transcripts
  if (replaceType === 'simple') {
    dispatch(simpleFindReplace({ find: searchTerm, replaceTerm, isCaseSensitive }));
  } else {
    dispatch(complexFindReplace({ find: searchTerm, replaceTerm, isCaseSensitive }));
  }
});

export const saveSpeakers = createAsyncThunk('editor/saveSpeakers', async (speakersList, thunkAPI) => {
  const { dispatch, getState, rejectWithValue } = thunkAPI;
  const { editor } = getState();
  const { id } = editor.workflowData;

  if (editor.isSaving) {
    return;
  }

  dispatch(setIsSaving(true));

  try {
    const response = await updateSpeakers(id, { speakers: speakersList });

    dispatch(updateSpeakersList(speakersList));
    dispatch(updateLastSaved());
    dispatch(setToast({
      message: 'Speakers have been updated',
      severity: 'success',
      autoClose: true,
    }));

    return response;
  } catch (e) {
    const errorMessage = e.response?.data?.message || e.message;
    dispatch(
      setToast({
        message: 'Speakers could not be saved. Please try again later. ' + errorMessage,
        severity: 'error',
      })
    );

    return rejectWithValue(e);
  } finally {
    dispatch(setIsSaving(false));
    dispatch(getWorkflow(id));
  }
});

export const saveComment = createAsyncThunk('editor/saveComment', async (payload, { dispatch, getState }) => {
  const { editor } = getState();
  const { id } = editor.workflowData;
  const { comment, snippetId, startIndex, endIndex, highlightedText } = payload;
  const request = {
    highlighted_text: highlightedText,
    start_pos: startIndex,
    end_pos: endIndex,
    comment,
  };

  try {
    const data = await addComment(id, snippetId, request);

    dispatch(updateComments({ snippetId, data }));
    dispatch(updateLastSaved());
  } catch (e) {
    const errorMessage = e.response?.data?.message || e.message;
    dispatch(setToast({
      message: 'Comment could not be saved. Please try again. ' + errorMessage,
      severity: 'error',
    }))
  }
});

export const saveUpdatedComment = createAsyncThunk('editor/updateComment', async (payload, { dispatch, getState }) => {
  const { editor } = getState();
  const { id } = editor.workflowData;
  const { snippetId, commentId, comment, callBack } = payload;
  const body = {
    _id: commentId,
    comment
  };

  try {
    await updateComment({ docid: id, itemId: snippetId, commentId, body });
    dispatch(updateAComment({ id: commentId, text: comment }));
  } catch (e) {
    const errorMessage = e.response?.data?.message || e.message;
    dispatch(setToast({
      message: 'Comment could not be updated. Please try again. ' + errorMessage,
      severity: 'error',
    }));
  }
  callBack()
});

export const removeComment = createAsyncThunk('editor/removeComment', async (payload, { dispatch, getState }) => {
  const { editor } = getState();
  const { id } = editor.workflowData;
  const { snippetId, commentId, callBack } = payload;

  try {
    await deleteComment(id, snippetId, commentId);
    dispatch(removeAComment(commentId));
    dispatch(updateLastSaved());
  } catch (e) {
    const errorMessage = e.response?.data?.message || e.message;
    dispatch(setToast({
      message: 'Comment could not be deleted. Please try again. ' + errorMessage,
      severity: 'error',
    }));
  }
  callBack()
});

export const updateWorkflow = createAsyncThunk('editor/updateWorkflow', async (_, thunkAPI) => {
  const { editor } = thunkAPI.getState();
  const { id } = editor.workflowData;
  const { dispatch } = thunkAPI;

  dispatch(setIsWorkflowSaving(true));

  try {
    await completeWorkflow(id);

    dispatch(updateLastSaved());
    dispatch(setToast({
      message: 'File has been marked as completed.',
      severity: 'success',
      autoClose: true,
    }));

    dispatch(setIsWorkflowSaved(true));
  } catch (e) {
    const errorMessage = e.response?.data?.message || e.message;
    dispatch(setToast({
      message: 'File could not be saved. Please try again. ' + errorMessage,
      severity: 'error',
    }));
  } finally {
    dispatch(getWorkflow(id));
    setTimeout(() => {
      dispatch(setIsWorkflowSaving(false));
      dispatch(setIsWorkflowSaved(false));
    }, 2500);
  }
});

export const downloadTranscript = createAsyncThunk('editor/download', async (config, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { editor } = getState();
  const { id } = editor.workflowData;

  dispatch(toggleIsDownloading());
  await dispatch(updateData());

  try {
    const promises = config.formats.map(async (format) => {
      const res = await exportTranscript(id, format, config.options);
      const fileName = `${editor.workflowData.title}`.replace('.mp3', '');

      download(res, fileName, format);
    });

    // Give the DB enough time to save all the changes
    setTimeout(async () => {
      await Promise.all(promises);

      dispatch(toggleIsDownloaded());

      setTimeout(() => {
        // Reset
        dispatch(toggleIsDownloaded());
      }, 1000);

      config.callback && config.callback();
    }, 2000);
  } catch (e) {
    const errorMessage = e.response?.data?.message || e.message;

    dispatch(setToast({
      message: 'Transcript could not be exported at this time. Please try again. ' + errorMessage,
      severity: 'error',
    }));
  } finally {
    dispatch(toggleIsDownloading());
  }
});

export default editorSlice.reducer;

import { Box } from "@mui/material";
import NavbarV2 from "../dashboardNav/indexV2";
import Footer from "../footer";
import PromoBanner from "hoc/promoBanner";

const debug = false;
const LayoutV2 = ({ children, background = "#FCFCFD" }) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavbarV2 />
      <PromoBanner />

      <Box
        sx={{
          flex: 1,
          background,
          "*": { outline: debug ? "1px solid #00990077" : "not-set" },
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default LayoutV2;

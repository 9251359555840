import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import AntSwitch from "./antSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  setGloablMonthlyCheck,
  globalMonthlyCheckSelector,
} from "../../store/payment";

const MonthlyCheck = () => {
  const globalMonthlyCheck = useSelector(globalMonthlyCheckSelector);
  const dispatch = useDispatch();

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        alignSelf={"center"}
      >
        <Typography
          sx={{
            fontFamily: "HelveticaNowDisplay",
            fontSize: "14px",
            lineHeight: "24px",
            color: "#141718",
            fontWeight: 600,
            mr: "20px",
          }}
        >
          Monthly billing
        </Typography>

        <AntSwitch
          inputProps={{ "aria-label": "ant design" }}
          onChange={() => dispatch(setGloablMonthlyCheck(!globalMonthlyCheck))}
          checked={globalMonthlyCheck}
        />

        <Typography
          sx={{
            fontFamily: "HelveticaNowDisplay",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            color: "#141718",
            opacity: 0.3,
            pl: "20px",
          }}
        >
          Yearly billing
        </Typography>
        <Box
          sx={{
            padding: "0px 8px",
            backgroundColor: "#3FDD7826",
            color: "#0C923C",
            fontSize: "14px",
            lineHeight: "24px",
            fontFamily: "HelveticaNowDisplay",
            fontWeight: 500,
            borderRadius: "4px",
          }}
        >
          Save 20%
        </Box>
      </Stack>
    </Box>
  );
};

export default MonthlyCheck;

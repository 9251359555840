import Box from "@mui/material/Box";
import React from "react";
import { Grid, Typography } from "@mui/material";
import CheckCircle from "../../pages/pricing/CheckCircle";

const PlanItem = ({
  title,
  description,
  price,
  expiredTime,
  features,
  config,
  actionButton,
  textSizeSmall,
}) => {
  const { mainColor, secondaryColor, highlightColor, backgroundColor } = config;
  return (
    <Grid
      item
      xs={6}
      md={3.2}
      sx={{
        width: "319px",
      }}
    >
      <Box
        sx={{
          padding: "9%",
          fontFamily: "HelveticaNowDisplay",
          background: backgroundColor,
          borderRadius: "24px",
        }}
      >
        <Typography
          component="h2"
          sx={{
            fontWeight: 700,
            fontSize: "28px",
            lineHeight: "40px",
            letterSpacing: "-0.01em",
            color: secondaryColor,
            mt: "4px",
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "-0.02em",
            color: mainColor,
          }}
        >
          {description}
        </Typography>
        <Box
          sx={{
            fontWeight: 700,
            fontSize: "48px",
            lineHeight: "56px",
            color: mainColor,
            flexDirection: "row",
            letterSpacing: "-0.02em",
            display: "flex",
            alignItems: "flex-end",
            mt: "24px",
          }}
        >
          {price}
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "28px",
              lineHeight: "40px",
              color: "#6C727580",
              ml: "8px",
              letterSpacing: "-0.01em",
            }}
          >
            / mo
          </Typography>
        </Box>

        <Typography
          sx={{
            fontWeight: 500,
            fontSize: textSizeSmall ? "12px" : "16px",
            lineHeight: "24px",
            color: "#6C7275",
            mt: "8px",
            letterSpacing: "-0.02em",
          }}
        >
          {expiredTime}
        </Typography>

        <Typography
          component="a"
          href="mailto:hello@deciphr.ai?subject=Enquiry about Deciphr AI Professional plan"
          target="_blank"
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#fff",
            mt: "8px",
            letterSpacing: "-0.02em",
            textDecoration: "underline",
            display: "block",
            visibility: title === "Professional" ? "visible" : "hidden",
          }}
        >
         Contact Sales
        </Typography>

        <Box
          sx={{
            borderTop: "1px solid #E8ECEF",
            width: "100%",
            pt: "24px",
            mt: "24px",
          }}
        >
          {features?.map((item, index) => (
            <Box sx={{ display: "flex", alignItems: "center", mb: "16px" }} key={index}>
              <Box sx={{ mr: "13px" }}>
                <CheckCircle />
              </Box>
              <Box
                sx={{
                  fontWeight: 700,
                  color: highlightColor,
                  display: "flex",
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
              >
                {item.main}
                <Box
                  sx={{
                    fontWeight: 500,
                    ml: "3px",
                    fontSize: "14px",
                    lineHeight: "24px",
                    color: mainColor,
                  }}
                >
                  {item.sub}
                </Box>
              </Box>
            </Box>
          ))}
          {actionButton}
        </Box>
      </Box>
    </Grid>
  );
};

export default PlanItem;

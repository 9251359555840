export function playPauseMedia(audioPlayer, callback) {
  if (audioPlayer.current.isPlaying()) {
    callback(false);
    audioPlayer.current.audio?.current.pause();
  } else {
    callback(true);
    audioPlayer.current.audio?.current.play();
  }
}

export function playerPlaybackRate(audioPlayer, playback, callback) {
  const playbackRateArr = [0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.5, 3, 3.5, 4];
  const playbackRate = audioPlayer.current.audio.current.playbackRate;
  const index = playbackRateArr.indexOf(playbackRate);
  if (playback === "increase") {
    if (index === -1) {
      audioPlayer.current.audio.current.playbackRate = 0.4;
    } else if (index === 0) {
      audioPlayer.current.audio.current.playbackRate = 0.4;
    } else {
      audioPlayer.current.audio.current.playbackRate =
        playbackRateArr[index - 1];
    }
    callback(
      `${
        playbackRateArr[index - 1]
          ? playbackRateArr[index - 1]
          : playbackRateArr[index]
      }x`
    );
  } else if (playback === "decrease") {
    if (index === -1) {
      audioPlayer.current.audio.current.playbackRate = 4;
    } else if (index === playbackRateArr.length - 1) {
      audioPlayer.current.audio.current.playbackRate = 4;
    } else {
      audioPlayer.current.audio.current.playbackRate =
        playbackRateArr[index + 1];
    }
    callback(
      `${
        playbackRateArr[index + 1]
          ? playbackRateArr[index + 1]
          : playbackRateArr[index]
      }x`
    );
  } else if (playback === "reset") {
    audioPlayer.current.audio.current.playbackRate = 1;
    callback("1x");
  }
}

export function playerSkipTime(audioPlayer, skip, rate) {
  if (skip === "forward") {
    audioPlayer.current.audio.current.currentTime += rate;
  } else if (skip === "backward") {
    audioPlayer.current.audio.current.currentTime -= rate;
  }
}

export function strikethrough(savecallback, delcallback) {
  const selection = window.getSelection();
  const selectedParentNode =
    selection.anchorNode.parentNode.parentNode.parentNode;
  const snippetId = selectedParentNode.getAttribute("dataid");

  const range = [
    parseInt(selection.anchorNode.parentElement.getAttribute("dataindice")),
    parseInt(selection.focusNode.parentElement.getAttribute("dataindice")),
  ];
  const childlist = selectedParentNode.children;
  const strikedwordRange = [];
  const delStrikewordRange = [];
  let counter = 0;
  for (const child of childlist) {
    const indice = child.children[0]?.getAttribute("dataindice");
    if (indice >= range[0] && indice <= range[1]) {
      if (child.getAttribute("class") === "strike") {
        delStrikewordRange.push(range[0] + counter);
        child.removeAttribute("class");
      } else {
        strikedwordRange.push(range[0] + counter);
        child.setAttribute("class", "strike");
      }
    }
    counter++;
  }
  if (strikedwordRange.length !== 0) {
    savecallback(snippetId, {
      word_indices: strikedwordRange,
    });
  }
  if (delStrikewordRange.length !== 0) {
    delcallback(snippetId, {
      word_indices: delStrikewordRange,
    });
  }
}

export function download(data, filename, ext) {
  const url = URL.createObjectURL(data);
  const a = document.createElement("a");
  a.download = `${filename}.${ext}`;
  a.href = url;
  a.target = "_self";

  a.click();

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    a.remove();
    URL.revokeObjectURL(url);
  }, 100);
}

import Alert from "../components/alert";
import Toast from "../components/toast";

function Wrapper({ children }) {
  return (
    <div>
      {children}

      <Alert />

      <Toast />
    </div>
  );
}

export default Wrapper;

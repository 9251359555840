import { useEffect, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Auth from "../../pages/Auth/indexV2";
import Signup from "../../pages/Auth/newSignup";
import Login from "../../pages/Auth/newLogin";
import ForgotPassword from "../../pages/Auth/forgotPassword";
import PricingPage from "../../pages/pricing";

const ResetPass = lazy(() => import("../../pages/Auth/resetPass"));
const MarketingHome = lazy(() => import("../../LPSaleEnterprise/pages/Podcaster"));
const TranscriptDetail = lazy(() => import("../../LPSaleEnterprise/pages/TranscriptDetail"))

function DefaultRouteHandler() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/qa') {
      localStorage.setItem('batch', 'gym_b');
    } else {
      localStorage.setItem('batch', 'gym_a');
    }
  }, [location.pathname]);

  return <Navigate to="/auth/login" />;
}

export default function CoreRoutes({ isEnabled }) {
  if (isEnabled) {
    return (
      <Routes>
        <Route path="auth" element={<Auth />}>
          {/* RESET OR EMAIL VERIFICATION ROUTE */}
          <Route path="password-reset" element={<ResetPass />} />
          <Route path="forgot" element={<ForgotPassword />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
        </Route>

        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/marketing/transcript/:id" element={<TranscriptDetail />} />
        <Route path="/marketing" element={<MarketingHome />} />
        <Route path="*" element={<DefaultRouteHandler />} />
      </Routes>
    );
  }

  return null;
}

import { Link as A } from "react-router-dom";
import Link from "@mui/material/Link";

const normalLinkStyle = {
  padding: "16px 24px",
  border: "2px solid transparent",
  borderRadius: "16px",
  display: "inline-block",
  margin: "0 4px",
  fontSize: "15px",
  fontWeight: 600,
  color: "#6E757C",
  textDecoration: "none",
  backgroundColor: "transparent",
  transition: "all .4s ease-out",
  "&:hover": {
    borderColor: "#0659F9",
    color: "#0659F9",
    backgroundColor: "#FFF",
  },
};

const ctaLinkStyle = {
  ...normalLinkStyle,
  backgroundColor: "#0659F9",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#FFF",
    borderColor: "#0659F9",
    color: "#0659F9",
  },
};

export default function AuthLinks({ show }) {
  if (show) {
    return (
      <>
        <Link
          component={A}
          to="/auth/login"
          sx={normalLinkStyle}
        >
          Login
        </Link>

        <Link
          component={A}
          to="/auth/signup"
          sx={ctaLinkStyle}
        >
          Sign up
        </Link>
      </>
    );
  }

  return null;
}

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckoutInput from "../../components/Input";
import { selectPlanDuration, selectedPlanSelector, isPayAsYouGoSelector, setDiscount } from "store/payment";
import { applyPromoCode } from "utils/apis";
import { planMap, addonsMap } from "utils/paymentPlans";
import { analytics } from "utils/GTM";

const getColor = (alertType) => {
  switch (alertType) {
    case "success":
      return "#45B26B";
    case "danger":
      return "#EF466F";
    default:
      return "";
  }
};

const getBgColor = (alertType) => {
  switch (alertType) {
    case "success":
      return "#d7f0e4";
    case "danger":
      return "#ffc5db";
    default:
      return "";
  }
};

export default function PromoCode() {
  const selectedPlan = useSelector(selectedPlanSelector);
  const planDuration = useSelector(selectPlanDuration);
  const isPayAsYouGo = useSelector(isPayAsYouGoSelector);
  const [code, setCode] = useState('');
  const [isValid, setIsValid] = useState(undefined);
  const [isApplied, setIsApplied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();

  const onChange = (value) => {
    setCode(value.toUpperCase());

    if (isApplied) {
      setIsApplied(false);
    }

    if (isValid !== undefined) {
      setIsValid(undefined);
    }
  };

  const onApply = async () => {
    setIsApplied(true);
    setIsLoading(true);
    setMessage(null);

    try {
      const plan = (isPayAsYouGo === false)
        ? planMap[selectedPlan.name][planDuration]
        : addonsMap[selectedPlan?.price?.onetime];

      const data = await applyPromoCode(code, plan);

      dispatch(
        setDiscount({
          discountPercent: data.discount,
          discountPeriod: data.valid_for,
        }),
      );
      setIsValid(true);
      setMessage(data);

      analytics.track("promo_code_applied", {
        code,
        discount: data.discount,
        period: data.valid_for || 1,
      });
    } catch (e) {
      setIsValid(false);
      dispatch(
        setDiscount({
          discountPercent: 0,
          discountPeriod: 0,
        }),
      );

      if (e.response && e.response.data) {
        setMessage(e.response.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box sx={{ position: "relative" }}>
        <CheckoutInput
          id="promo"
          label="Promo code"
          placeholder="Your promo code"
          required={false}
          isValid={isValid}
          onChange={onChange}
        />

        {!isApplied && (
          <LoadingButton
            variant="text"
            size="small"
            loading={isLoading}
            disabled={isLoading}
            onClick={onApply}
            sx={{
              position: "absolute",
              right: "15px",
              top: "66%",
              transform: "translateY(-66%)",
              color: "#0659F9",
              fontSize: "14px",
              fontWeight: 700,
              fontFamily: "HelveticaNowDisplay"
            }}
          >
            Apply
          </LoadingButton>
        )}

        {isApplied && (
          <IconButton
            size="small"
            disabled
            sx={{
              color: "#777E90",
              position: "absolute",
              right: "15px",
              top: "66%",
              transform: "translateY(-66%)",
            }}
          >
            <CancelIcon />
          </IconButton>
        )}
      </Box>

      {message !== null && (
        <Typography
          sx={{
            color: getColor(message.type),
            fontSize: "14px",
            fontWeight: "600",
            padding: "6px 12px",
            backgroundColor: getBgColor(message.type),
            mb: "20px",
          }}
        >
          {message.message}
        </Typography>
      )}
    </Box>
  );
}

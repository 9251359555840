// Helper functions for the Enterprise Editor
import { Node, Transforms } from 'slate';
import { sha1 } from 'object-hash';

export const isMultiBlockSelection = (selection) => {
  if (selection && selection.anchor && selection.focus) {
    return selection.anchor.path[0] !== selection.focus.path[0];
  }

  return false;
};

export const shouldMerge = (selection) => {
  if (selection && selection.anchor) {
    // Is cursor in the first position of the first word in the paragraph?
    return selection.anchor.offset === 0 && selection.anchor.path[2] === 0;
  }

  return false;
};

export const shouldMergeWithNext = (editor) => {
  const selection = editor.selection;

  if (selection && selection.anchor) {
    const snippetIndex = selection.anchor.path[0];
    const lastWordIndex = editor.children[snippetIndex].children[0].children.length - 1;
    const lastWordOffset = editor.children[snippetIndex].children[0].children[lastWordIndex].text.length;

    // Is the cursor in the last position of the last word in the paragraph?
    return selection.anchor.offset === lastWordOffset && selection.anchor.path[2] === lastWordIndex;
  }

  return false;
};

const formatTime = (milliseconds) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const mergeNodes = (editor, path, withNext = false) => {
  const index = withNext ? path : path - 1;
  const nextIndex = index + 1;
  const prevWords = [...editor.children[index].children[0].children]; // first paragraph
  const words = [...editor.children[nextIndex].children[0].children]; // second paragraph
  const nodeOffset = prevWords.length - 1;
  const snippetId = editor.children[index].id;
  const charOffset = prevWords[nodeOffset].text.length;
  let cursorPath = [index, 0, nodeOffset];
  const node = {
    type: 'snippet',
    id: snippetId,
    start_timestamp: editor.children[index].start_timestamp,
    start: editor.children[index].start,
    speaker: editor.children[index].speaker,
    children: [{
      type: 'paragraph',
      snippetId,
      children: prevWords.concat(words),
    }],
  };

  Transforms.removeNodes(editor, { at: [index] });
  Transforms.removeNodes(editor, { at: [index] });
  Transforms.insertNodes(editor, [node], {
    at: [index],
  });

  Transforms.select(editor, { path: cursorPath, offset: charOffset });
};

export const getSplitData = (state, snippetIndex, splitIndex, offset) => {
  const snippet = state[snippetIndex];
  const newId = sha1(snippet.id + Date.now()).substring(0, 8); // Ensure unique ID

  // Handle case where splitIndex is out of bounds
  const safeSlitIndex = Math.min(Math.max(splitIndex, 0), snippet.children[0].children.length);

  // TODO: Get offset in param, and snippet 1 words should create a new word if offset > 1

  // HACK: Convert Proxy object to normal JS object using JSON.stringify
  const snippet1words = JSON.parse(JSON.stringify(snippet.children[0].children.slice(0, safeSlitIndex)));
  const snippet2words = JSON.parse(JSON.stringify(snippet.children[0].children.slice(safeSlitIndex)));

  if (offset >= 0) {
    // cursor is inside a word, we must split the word into two
    const currentWord = snippet.children[0].children[safeSlitIndex - 1]?.text || "";

    const word1 = currentWord.substring(0, offset).trimEnd();
    const word2 = currentWord.substring(offset, currentWord.length);
    const lastIndex = snippet1words.length - 1;

    if (word1) {
      // Append to the end of the first snippet/paragraph
      snippet1words[lastIndex].text = word1;
    } else {
      // Cursor was in front of the word, remove the whole word from this paragraph
      snippet1words.pop();
    }

    if (word2) {
      const word2Obj = { ...snippet1words[lastIndex] };

      snippet2words.unshift({ ...word2Obj, text: word2 });
    }
  }

  // Calculate split time and timestamp
  const splitTime = snippet2words[0]?.start ??
    (snippet1words[snippet1words.length - 1]?.end ?? snippet.start);
  // const splitTimestamp = formatTime(splitTime);

  // Process first snippet words
  snippet1words.forEach((word, index) => {
    word.id = index;
    word.snippetId = snippet.id;
  });

  // Process second snippet words
  snippet2words.forEach((word, index) => {
    // Ignore empty (0 length) "words"
    if (word.text) {
      word.id = index;
      word.snippetId = newId;

      if (index === 0) {
        word.text = word.text.trimStart();
      }
    }
  });

  // Ensure there's always at least one word in each split
  if (snippet1words.length === 0) {
    snippet1words.push({
      text: '',
      snippetId: snippet.id,
      start: snippet.start,
      // start_timestamp: snippet.start_timestamp,
      end: splitTime,
      // end_timestamp: splitTimestamp
    });
  }

  if (snippet2words.length === 0) {
    snippet2words.push({
      text: '',
      snippetId: newId,
      start: splitTime,
      // start_timestamp: splitTimestamp,
      end: snippet.end,
      // end_timestamp: snippet.end_timestamp
    });
  }

  // Ensure last word of first snippet and first word of second snippet have proper end and start times
  if (snippet1words.length > 0) {
    snippet1words[snippet1words.length - 1].end = splitTime;
    // snippet1words[snippet1words.length - 1].end_timestamp = splitTimestamp;
  }
  if (snippet2words.length > 0) {
    snippet2words[0].start = splitTime;
    // snippet2words[0].start_timestamp = splitTimestamp;
  }

  // Create split snippets
  const splitSnippets = [
    {
      ...snippet,
      children: [{
        ...snippet.children[0],
        children: snippet1words,
      }],
      start: snippet.start,
      start_timestamp: formatTime(snippet.start),
      end: splitTime,
      end_timestamp: formatTime(splitTime)
    },
    {
      ...snippet,
      id: newId,
      children: [{
        ...snippet.children[0],
        snippetId: newId,
        children: snippet2words,
      }],
      start: splitTime,
      start_timestamp: formatTime(splitTime),
      end: snippet.end,
      end_timestamp: formatTime(snippet.end)
    }
  ];

  return splitSnippets;
};

export const splitNodes = (editor, snippetIndex, splitIndex, offset) => {
  const splitSnippets = getSplitData(editor.children, snippetIndex, splitIndex, offset);

  Transforms.removeNodes(editor, { at: [snippetIndex] });
  Transforms.insertNodes(editor, [splitSnippets[0]], { at: [snippetIndex] });
  Transforms.insertNodes(editor, [splitSnippets[1]], { at: [snippetIndex + 1] });

  Transforms.select(editor, { path: [snippetIndex + 1, 0, 0], offset: 0 });
};

export const updateSpeakerNode = (editor, snippetId, speaker) => {
  const index = editor.children.findIndex((t) => t.id === snippetId);
  const snippet = { ...editor.children[index] };

  snippet.speaker = speaker;

  Transforms.setNodes(editor, snippet, { at: [index] });
};

export const cycleSpeakerNode = (editor, snippetId, speakers) => {
  const index = editor.children.findIndex((snippet) => snippet.id === snippetId);
  const currentSpeaker = editor.children[index].speaker;
  const currentSpeakerIndex = speakers.indexOf(currentSpeaker);
  const nextIndex = (currentSpeakerIndex === (speakers.length - 1)) ? 0 : currentSpeakerIndex + 1;

  updateSpeakerNode(editor, snippetId, speakers[nextIndex]);
};

export const getStrikeOp = (editor, snippetIndex, startIndex, endIndex) => {
  let operation = 'strike';

  if (endIndex === undefined || endIndex === startIndex) {
    // single word selected
    if (editor.children[snippetIndex].children[0].children[startIndex].is_removed) {
      operation = 'unstrike';
    }

    return operation;
  }

  // multi-word selection, check if one or more words are struck
  const snippets = [];

  for (let i = startIndex; i <= endIndex; i++) {
    snippets.push(editor.children[snippetIndex].children[0].children[i]);
  }

  if (snippets.every((s) => s.is_removed === true)) {
    // Is every selected word striked?
    operation = 'unstrike';
  }

  // By default, all unstriked and mixed strike/unstrike selections will be striked

  return operation;
};

export const toggleStrikeNode = (editor, snippetIndex, startIndex, endIndex, isStruck) => {
  // Flip start and end if the selection is in reverse
  const start = (startIndex <= endIndex) ? startIndex : endIndex;
  const end = (endIndex >= startIndex) ? endIndex : startIndex;

  for (let i = start; i <= end; i++) {
    Transforms.setNodes(editor, { is_removed: isStruck }, { at: [snippetIndex, 0, i], hanging: false });
  }
};

// Finds the nearest spoken word (unstruck word) from the target word
// Returns the start timestamp (in ms) of the next spoken word
export const findNextSpokenWordTime = (fromTargetWord) => {
  let nextSpokenWord = null;

  // If the current target is not deleted, return it and exit early
  if (fromTargetWord.children[0].dataset["isDeleted"] !== "true") {
    return parseInt(fromTargetWord.children[0].dataset["start"]);
  }

  if (fromTargetWord.nextElementSibling) {
    // Inspect the next sibling element to see if it's struck or not
    if (fromTargetWord.nextElementSibling.children[0].dataset["isDeleted"] === undefined) {
      nextSpokenWord = fromTargetWord.nextElementSibling.children[0];
    } else {
      // Loop until we find an unstruck word in the current paragraph
      return findNextSpokenWordTime(fromTargetWord.nextElementSibling);
    }
  } else {
    // If the entire paragraph was struck, go to the next paragraph and try the above logic again
    const currentParagraph = fromTargetWord.closest(".transcript-data");
    const nextParagraph = currentParagraph.nextElementSibling;

    if (nextParagraph) {
      const firstWord = nextParagraph.querySelector("[data-slate-node='text']");

      return findNextSpokenWordTime(firstWord);
    } else {
      return null;
    }
  }

  return nextSpokenWord ? parseInt(nextSpokenWord.dataset["start"]) : null;
};

export const replaceParagraphNode = (editor, index, node) => {
  Transforms.removeNodes(editor, { at: [index] });
  Transforms.insertNodes(editor, node, { at: [index] });
};

// Save current player and editor state for the current file ID
// for later restoration
export const saveEditorState = (fileId, audioPlayer) => {
  const fileStates = JSON.parse(localStorage.getItem("fileStates")) || {};

  if (audioPlayer) {
    let shouldResumePlayback = false;

    if (audioPlayer.paused === false) {
      // pause briefly to take note of the current time
      audioPlayer.pause();

      shouldResumePlayback = true;
    }

    const currentTime = audioPlayer.currentTime;
    const word = document.querySelector(".word[data-playing=true]");

    if (shouldResumePlayback) {
      // Now keep playing
      audioPlayer.play();
    }

    if (word) {
      fileStates[fileId] = { currentTime, snippetId: word.dataset["snippetId"] };

      localStorage.setItem("fileStates", JSON.stringify(fileStates));
    }
  }
};

export const restoreEditorState = (fileId, audioPlayer) => {
  const fileStates = JSON.parse(localStorage.getItem("fileStates")) || {};

  if (fileStates[fileId] && audioPlayer) {
    const fileState = fileStates[fileId];
    const word = document.querySelector(`.snippet[data-id="${fileState.snippetId}"]`);

    audioPlayer.currentTime = fileState.currentTime;

    if (word) {
      word.scrollIntoView({ block: "start", behavior: "smooth" });
      word.dataset.playing = "true";
    }
  }
};

export const removeFileState = (fileId) => {
  const fileStates = JSON.parse(localStorage.getItem("fileStates")) || {};

  try {
    delete fileStates[fileId];

    localStorage.setItem("fileStates", JSON.stringify(fileStates));
  } catch (e) {
    // noop
  }
};

export const addEntity = (editor, snippetIndex, startIndex, endIndex, entity) => {
  for (let i = startIndex; i <= endIndex; i++) {
    Transforms.setNodes(editor, { isEntity: true, entity }, { at: [snippetIndex, 0, i] });

    if (i === endIndex) {
      Transforms.setNodes(editor, { isEntity: true, entity, isLastWord: true }, { at: [snippetIndex, 0, i] });
    }
  }
};

export const initEntities = (editor, entities) => {
  const entries = Object.entries(entities);
  const snippetsLength = editor.children.length;
  const entriesLength = entries.length;

  for (let snippetIndex = 0; snippetIndex < snippetsLength; snippetIndex++) {
    const text = Node.string(editor.children[snippetIndex]);

    for (let eIndex = 0; eIndex < entriesLength; eIndex++) {
      const entityValue = entries[eIndex][0];

      if (text.indexOf(entityValue) > -1) {
        // Entity exists in this paragraph, proceed further
        const windowSize = entityValue.split(" ").length;
        const words = editor.children[snippetIndex].children[0].children;
        const wordsLength = words.length;

        for (let wordIndex = 0; wordIndex < wordsLength; wordIndex++) {
          const slice = words.slice(wordIndex, (wordIndex + windowSize));
          const slicedArr = slice.map((s) => s.text);
          const slicedText = slicedArr.join("");

          if (slicedText.indexOf(entityValue) > -1) {
            const endIndex = (wordIndex + windowSize) - 1;
            const entityType = entries[eIndex][1];

            if (endIndex >= wordsLength) {
              break;
            }

            addEntity(editor, snippetIndex, wordIndex, endIndex, entityType);
          }
        }
      }
    }
  }
};

export const removeEntities = (editor) => {
  const snippetsLength = editor.children.length;

  for (let snippetIndex = 0; snippetIndex < snippetsLength; snippetIndex++) {
    const wordsLength = editor.children[snippetIndex].children[0].children.length;

    for (let wordIndex = 0; wordIndex < wordsLength; wordIndex++) {
      if (editor.children[snippetIndex].children[0].children[wordIndex].isEntity) {
        Transforms.setNodes(editor, { isEntity: false, entity: null }, { at: [snippetIndex, 0, wordIndex] });
      }
    }
  }
};

export const addComment = (editor, snippetIndex, startIndex, endIndex) => {
  for (let i = startIndex; i <= endIndex; i++) {
    Transforms.setNodes(editor, { isComment: true }, { at: [snippetIndex, 0, i] });
  }
};

export const initComments = (editor, comments) => {
  comments.forEach((comment) => {
    const snippetIndex = editor.children.findIndex((s) => s.id === comment.snippetId);

    addComment(editor, snippetIndex, comment.start, comment.end);
  });
};

export const removeComments = (editor) => {
  const snippetsLength = editor.children.length;

  for (let snippetIndex = 0; snippetIndex < snippetsLength; snippetIndex++) {
    const wordsLength = editor.children[snippetIndex].children[0].children.length;

    for (let wordIndex = 0; wordIndex < wordsLength; wordIndex++) {
      if (editor.children[snippetIndex].children[0].children[wordIndex].isComment) {
        Transforms.setNodes(editor, { isComment: false }, { at: [snippetIndex, 0, wordIndex] });
      }
    }
  }
};

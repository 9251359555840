import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  generateArticle,
  checkArticalStatus,
  getArticle,
  getAllArticles,
} from "../utils/apis";
import { setToast } from "./toastSlice";

const articleSlice = createSlice({
  name: "article",
  initialState: {
    articles: [],
    loadingArticles: false,
    docId: null,
    result: null,
    isLoading: false,
    open: false,
    isGeneratingNewArticle: false,
  },

  reducers: {
    setArticles: (state, action) => {
      state.articles = action.payload.articles;
      state.docId = action.payload.docId;
    },
    setLoadingArticles: (state, action) => {
      state.loadingArticles = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsOpen: (state, action) => {
      state.open = action.payload;
    },
    setIsGeneratingNewArticle: (state, action) => {
      state.isGeneratingNewArticle = action.payload;
    },
  },
});

export const {
  setArticles,
  setLoadingArticles,
  setResult,
  setIsLoading,
  setIsOpen,
  setIsGeneratingNewArticle,
} = articleSlice.actions;

export const articlesSelector = (state) => state.article.articles;
export const loadingArticlesSelector = (state) => state.article.loadingArticles;
export const resultSelector = (state) => state.article.result;
export const isLoadingSelector = (state) => state.article.isLoading;
export const isOpenSelector = (state) => state.article.open;
export const isGeneratingNewArticleSelector = (state) => state.article.isGeneratingNewArticle;

export const getArticles = createAsyncThunk(
  "article/getArticles",
  async (docId, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setLoadingArticles(true));
    try {
      const { articles } = (await getAllArticles(docId)) || {};
      dispatch(setArticles({ articles, docId }));
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
    dispatch(setLoadingArticles(false));
  }
);

export const genArticle = createAsyncThunk(
  "article/generateArticle",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { inspiration, context, type, format, tone, docId } = payload;
    dispatch(setIsLoading(true));
    dispatch(setIsOpen(true));
    dispatch(setIsGeneratingNewArticle(true));
    try {
      const article = await generateArticle({
        docId,
        inspiration,
        context,
        type,
        format,
        tone,
      });
      if (!article) {
        throw new Error("Failed to generate article.");
      }
      const { id } = article;
      const checkArticleStatus = async () => {
        try {
          const { status } = (await checkArticalStatus(id)) || {};
          if (status === "Processed") {
            const data = await getArticle(id);
            dispatch(setResult(data));
            dispatch(setIsLoading(false));
            dispatch(getArticles(docId));
          } else if (status === "Failed") {
            dispatch(
              setToast({
                message: "Failed to generate article.",
                severity: "error",
                autoClose: true,
              })
            );
            dispatch(setIsLoading(false));
            dispatch(setIsOpen(false));
          } else {
            setTimeout(checkArticleStatus, 10000);
          }
        } catch (error) {
          dispatch(setToast({ message: error.message, severity: "error" }));
          dispatch(setIsLoading(false));
          dispatch(setIsOpen(false));
        }
      };
      checkArticleStatus();
    } catch (e) {
      dispatch(setIsLoading(false));
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
  }
);

export const openArticle = createAsyncThunk(
  "article/openArticle",
  async (articleId, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(setIsLoading(true));
    dispatch(setIsOpen(true));
    dispatch(setIsGeneratingNewArticle(false));
    try {
      const data = await getArticle(articleId);
      dispatch(setResult(data));
      dispatch(setIsLoading(false));
    } catch (e) {
      dispatch(setIsLoading(false));
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
  }
);

export default articleSlice.reducer;

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function Footer(props) {
  return (
    <Box
      sx={{
        textAlign: "center",
        height: "100px",
        background: "#FCFCFD",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "solid 1px #E6E8EC ",
      }}
    >
      <Typography
        sx={{
          color: "#777E91",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "20px",
        }}
      >
        Copyright &copy; {new Date().getFullYear()} Inflexion AI Pty Ltd. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;

import { useState } from "react";
import { AppBar, Box, Container, Divider } from "@mui/material";
import LibrarySearchBar from "./LibrarySearchBar";
import NotificationBar from "./NotificationBar";
import UpgradeLink from "./upgrade";
import UserMenu from "./userMenu";
import UserMenuDropdown from "./userMenuDropdown";
import AuthLinks from "./AuthLinks";
import NavLinks from "./navLinks";
import InflexionLogo from '../../pages/editorDashboard/Header/InflexionLogo';


export default function PrimarySearchAppBarV2({ bgcolor = "#fff", logoSize = "md" }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isLoggedIn = localStorage.getItem("userInfo") !== null;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      sx={{
        height: "80px",

        "& p": {
          fontFamily: "HelveticaNowDisplay"
        },
      }}>
      <AppBar position="fixed"
        sx={{
          background: bgcolor,
          borderBottom: "thin solid #E5E8EB",
        }}
        elevation={0}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              position: "relative",
              py: "20px",
              px: "0px !important",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
              <InflexionLogo />

              {isLoggedIn && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    border: "1px solid #E6E8EC",
                  }}
                />
              )}

              <NavLinks show={isLoggedIn} />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LibrarySearchBar show={isLoggedIn} />

              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <NotificationBar show={isLoggedIn} />

                <UpgradeLink show={isLoggedIn} />

                <UserMenu show={isLoggedIn} onOpen={handleProfileMenuOpen} />

                {/* <AuthLinks show={!isLoggedIn} /> */}
              </Box>
            </Box>
          </Box>
        </Container>
      </AppBar>

      <UserMenuDropdown
        show={isLoggedIn}
        anchorEl={anchorEl}
        onClose={setAnchorEl}
      />
    </Box>
  );
}

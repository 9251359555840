import Typography from "@mui/material/Typography";

export default function CardError({ message }) {
  if (message) {
    return (
      <Typography
        sx={{
          color: "#9e2146",
          fontSize: "12px",
          fontWeight: 500,
          p: "6px 10px",
          mt: "5px",
          bgcolor: "#f6e6e7"
        }}
      >
        {message}
      </Typography>
    );
  }

  return null;
}

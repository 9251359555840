export const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: " 56px",
    overflow: "hidden",
    backgroundColor: "#0659F9",
  },
  text: {
    fontFamily: "HelveticaNowDisplay",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "140%",
    color: "#ffffff",
    cursor: "pointer",
  },
  highlight: {
    fontWeight: "700",
    textDecoration: "underline",
  },
  icon: {
    color: "#ffffff",
  },
};

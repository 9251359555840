import { getSplitData } from "../../pages/editTranscript/util/editor";

export const setTranscriptData = (state, action) => {
  state.transcripts = action.payload.transcripts;
};

export const appendTranscriptData = (state, action) => {
  state.transcripts = state.transcripts.concat(action.payload);
};

export const toggleStrike = (state, action) => {
  let { startIndex, endIndex, snippetId, is_removed } = action.payload;
  const index = state.transcripts.findIndex((t) => t.id === snippetId);

  if (index > -1) {
    const words = state.transcripts[index].children[0].children;

    // text selection was backwards, swap the indexes
    if (startIndex > endIndex) {
      [startIndex, endIndex] = [endIndex, startIndex];
    }

    for (let i = startIndex; i <= endIndex; i++) {
      words[i].is_removed = is_removed;
    }

    state.transcripts[index].children[0].children = words;
    state.hasUnsavedChanges = true;
  }
};

export const changeSpeaker = (state, action) => {
  const { snippetId, speaker } = action.payload;
  const transcripts = [...state.transcripts];

  transcripts.find((t) => t.id === snippetId).speaker = speaker;
  state.hasUnsavedChanges = true;
};

export const rotateSpeaker = (state, action) => {
  const snippetId = action.payload;
  const speakers = state.speakerNames;
  const currentSpeaker = state.transcripts.find((t) => t.id === snippetId).speaker;
  const currentIndex = speakers.indexOf(currentSpeaker);
  const nextIndex = (currentIndex === (speakers.length - 1)) ? 0 : currentIndex + 1;

  state.transcripts.find((t) => t.id === snippetId).speaker = speakers[nextIndex];
  state.hasUnsavedChanges = true;
};

export const split = (state, action) => {
  const { offset, snippetIndex, splitIndex } = action.payload;
  const splitSnippets = getSplitData(state.transcripts, snippetIndex, splitIndex, offset);

  state.transcripts[snippetIndex] = splitSnippets[0];
  state.transcripts.splice(snippetIndex + 1, 0, splitSnippets[1]);

  state.hasUnsavedChanges = true;
};

export const merge = (state, action) => {
  const { snippetIndex, withNext } = action.payload;
  const startIndex = withNext ? snippetIndex : (snippetIndex - 1);

  if (snippetIndex === 0 && !withNext) {
    return false;
  }

  if (snippetIndex === (state.transcripts.length - 1) && withNext) {
    return false;
  }

  const firstSnippet = state.transcripts[startIndex]; //current paragraph
  const secondSnippet = state.transcripts[startIndex + 1]; // next paragraph
  const firstWordsLength = firstSnippet.children[0].children.length;

  const mergedSnippet = {
    ...firstSnippet,
    children: [{
      type: "paragraph",
      snippetId: firstSnippet.id,
      children: firstSnippet.children[0].children.concat(
        secondSnippet.children[0].children.map((word, index) => {
          word.id = firstWordsLength + index;
          word.snippetId = firstSnippet.id;

          return word;
        })
      )
    }],
  };

  state.transcripts.splice(startIndex, 2, mergedSnippet);

  state.hasUnsavedChanges = true;
};

const escapeRegExp = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const replace = ({ target, find, replaceTerm, isCaseSensitive = false }) => {
  const searchTerm = isCaseSensitive ? find : new RegExp(escapeRegExp(find), "ig");

  return target.replace(searchTerm, replaceTerm);
};

export const replaceInSummary = (state, action) => {
  const { find, replaceTerm, isCaseSensitive } = action.payload;

  state.summary = replace({ target: state.summary, find, replaceTerm, isCaseSensitive });
};

export const replaceInChapters = (state, action) => {
  const { find, replaceTerm, isCaseSensitive } = action.payload;

  state.insights = state.insights.map((insight) => {
    return {
      ...insight,
      summary: replace({ target: insight.summary, find, replaceTerm, isCaseSensitive }),
    };
  });
};

export const simpleReplace = (state, action) => {
  const { find, replaceTerm, isCaseSensitive } = action.payload;
  const transcripts = JSON.parse(JSON.stringify(state.transcripts));
  const transcriptsLength = transcripts.length;

  for (let itemIndex = 0; itemIndex < transcriptsLength; itemIndex++) {
    const text = transcripts[itemIndex].children[0].children.map((word) => word.text).join("");

    if (text.toLowerCase().indexOf(find.toLowerCase()) > -1) {
      const wordsLength = transcripts[itemIndex].children[0].children.length;

      for (let wordIndex = 0; wordIndex < wordsLength; wordIndex++) {
        const word = transcripts[itemIndex].children[0].children[wordIndex].text;

        if (word.toLowerCase().indexOf(find.toLowerCase()) > -1) {
          transcripts[itemIndex].children[0].children[wordIndex].text = replace({
            target: transcripts[itemIndex].children[0].children[wordIndex].text,
            find,
            replaceTerm,
            isCaseSensitive,
          });
        }
      }

      const event = new CustomEvent("findAndReplace", {
        detail: {
          index: itemIndex,
          transcript: transcripts[itemIndex],
        },
      });

      window.dispatchEvent(event);
    }
  }

  state.hasUnsavedChanges = true;
};

export const complexReplace = (state, action) => {
  const { find, replaceTerm, isCaseSensitive } = action.payload;
  const findList = find.split(' ');
  const windowSize = findList.length;
  const transcripts = JSON.parse(JSON.stringify(state.transcripts)); // all updates happen here
  const tCopy = JSON.parse(JSON.stringify(transcripts)); // original copy
  const transcriptsLength = transcripts.length;

  // Loop through each paragraph
  for (let itemIndex = 0; itemIndex < transcriptsLength; itemIndex++) {
    const text = transcripts[itemIndex].children[0].children.map((word) => word.text).join("");
    const findLowerCase = find.toLowerCase();

    // If we find a potential match, let's take a look
    if (text.toLowerCase().indexOf(findLowerCase) > -1) {
      const words = tCopy[itemIndex].children[0].children;
      const wordsLength = words.length;
      const searchTerm = isCaseSensitive ? find : new RegExp(escapeRegExp(find), "ig");

      for (let wordIndex = 0; wordIndex < wordsLength; wordIndex++) {
        const slice = words.slice(wordIndex, (wordIndex + windowSize));
        const sliceTextArr = slice.map((s) => s.text);
        const slicedText = sliceTextArr.join("");

        if (slicedText.toLowerCase().indexOf(findLowerCase) > -1) {
          const updateIndex = (wordIndex + windowSize) - 1;

          if (updateIndex >= wordsLength) {
            break;
          }

          transcripts[itemIndex].children[0].children[updateIndex].text
            = slicedText.replace(searchTerm, replaceTerm);

          // Loop in reverse windowSize number of times to clear out text
          for (let i = (updateIndex - 1); i > (updateIndex - windowSize); i--) {
            transcripts[itemIndex].children[0].children[i].text = "";
          }
        }
      }

      // Filter out emptied out words from the above inner-most for loop
      transcripts[itemIndex].children[0].children
        = transcripts[itemIndex].children[0].children.filter((word) => word.text !== "");

      // HACK: Because Slate is not a controlled component any more, we have to
      // HACK: do this ugly thing to copy the updated model to editor.children
      // https://github.com/ianstormtaylor/slate/pull/4540
      const event = new CustomEvent("findAndReplace", {
        detail: {
          index: itemIndex,
          transcript: transcripts[itemIndex],
        },
      });

      window.dispatchEvent(event);
    }
  }

  state.hasUnsavedChanges = true;
};

import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Divider, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { GlobalData } from "../../context";
import { getUserInfo } from "store/user";
import { init, schema } from "../../utils/constants";
import { analytics } from "../../utils/GTM";
import { signupApi } from "../../utils/authSeq";
import { ReactComponent as Avatars } from "../../assets/avatars.svg";

function NewSignUp() {
  const [currentUser, setCurrentUser] = useState();
  const [loader, setLoader] = useState(false);
  const { openAlert, setMessage, setSeverity } = useContext(GlobalData);
  const history = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: init("newSignup"),
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema("newSignup")),
    onSubmit: (value) => SubmitHandler(value),
  });

  const SubmitHandler = async (value) => {
    setLoader(true);
    await signupApi(
      value,
      (authData) => {
        history("/library");
        setCurrentUser(authData.user);
        // dispatch(getUserInfo());
        setSeverity("success");
        // openAlert();
        // setMessage(
        //   "Signed up successfully. Please check your email for an activation link."
        // );

        analytics.track("User Successfully Signed up");
        analytics.identify(authData.user.localId, {
          email: authData.user.email,
          name: authData.user.displayName,
        });
        formik.resetForm();
      },
      errorHandler
    );
    setLoader(false);
  };

  const errorHandler = (err) => {
    setSeverity("error");
    openAlert();
    setMessage(err);
  };

  return (
    <Box sx={styles.root}>
      <Box>
        <Typography sx={styles.heading}>
          Join Inflexion
        </Typography>
        {/* <Avatars /> */}
        {/* <Typography sx={styles.description}> */}
        {/*   Join these and other 466+ editors now */}
        {/* </Typography> */}
      </Box>

      <Divider sx={{ my: "40px" }}/>

      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ ...styles.formControl, mr: "12px" }}>
            <Typography sx={styles.formLabel}>First Name</Typography>
            <TextField
              variant="outlined"
              sx={styles.formInput}
              id="firstName"
              name="firstName"
              placeholder="Enter First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
            {Boolean(formik.errors.firstName && formik.touched.firstName) && (
              <Typography sx={styles.formError}>
                First name is {formik.errors.firstName}
              </Typography>
            )}
          </Box>
          <Box sx={styles.formControl}>
            <Typography sx={styles.formLabel}>Last Name</Typography>
            <TextField
              variant="outlined"
              sx={styles.formInput}
              id="lastName"
              name="lastName"
              placeholder="Enter Last Name "
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
            {Boolean(formik.errors.lastName && formik.touched.lastName) && (
              <Typography sx={styles.formError}>
                Last name is {formik.errors.lastName}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={styles.formControl}>
          <Typography sx={styles.formLabel}>Email</Typography>
          <TextField
            variant="outlined"
            sx={styles.formInput}
            id="email"
            name="email"
            placeholder="Enter Email"
            value={formik.values.email}
            type="email"
            onChange={formik.handleChange}
          />
          {Boolean(formik.errors.email && formik.touched.email) && (
            <Typography sx={styles.formError}>
              Email is {formik.errors.email}
            </Typography>
          )}
        </Box>
        <Box sx={styles.formControl}>
          <Typography sx={styles.formLabel}>Create Password</Typography>
          <TextField
            variant="outlined"
            sx={styles.formInput}
            id="password"
            name="password"
            placeholder="Enter Password"
            value={formik.values.password}
            type="password"
            onChange={formik.handleChange}
          />
          {Boolean(formik.errors.password && formik.touched.password) && (
            <Typography sx={styles.formError}>
              Password is {formik.errors.password}
            </Typography>
          )}
        </Box>
      </Box>

      {/* href="https://www.deciphr.ai/terms-and-conditions" */}
      {/* href="https://www.deciphr.ai/privacy-policy" */}

      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "32px", width: "100%" }}>
        <LoadingButton
          sx={{
            ...styles.continueButton,
            opacity: formik.values.isAgree ? 1 : 0.5,
            cursor: formik.values.isAgree ? "pointer" : "not-allowed",
          }}
          disableElevation
          onClick={() => {
            if (!formik.values.isAgree) {
              return;
            }
            formik.handleSubmit();
          }}
          loading={loader}
          disabled={!formik.values.isAgree}
        >
          Continue
        </LoadingButton>

        <Typography sx={styles.loginText}>
          Already have an account?&nbsp;
          <Typography sx={styles.loginLink} onClick={() => history("/auth/login")}>
            Login
          </Typography>
          .
        </Typography>
      </Box>
    </Box>
  );
}

const styles = {
  root: {
    width: { xs: "100%", md: "45%" },
    background: "white",
    backgroundColor: "transparent",
    py: { xs: "100px", md: "calc(102px + 2vh)" },
    minHeight: { xs: "100vh", lg: "unset" },
    paddingBottom: "50px",
    mx: "auto",
    display: "flex",
    flexDirection: "column",
  },

  heading: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#10181E",
  },

  description: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.02em",
    color: "#667085",
    mt: "5px",
  },

  formLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20.3px",
    letterSpacing: "0.2px",
    color: "#10181E",
    mb: "5px",
  },

  formInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: "12px",
        '& fieldset': {
          borderColor: "#DCE0E5",
        },
        '&.Mui-focused fieldset': {
          borderColor: "#DCE0E5",
        },
    },
    width: "100%",
  },

  formControl: {
    my: "18px",
    flex: 1,
  },

  continueButton: {
    background: "#0659F9",
    borderRadius: "44px",
    color: "#fff",
    height: "56px",
    width: "100%",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
  },

  formError: {
    color: "red",
    mt: "4px",
  },

  loginText: {
    fontSize: { xs: "14px", lg: "16px" },
    fontWeight: "400",
    lineHeight: "24px",
    mt: "24px",
    display: "flex",
  },

  loginLink: {
    color: "#0659F9",
    textDecoration: "none",
    fontWeight: "700",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

export default NewSignUp;

// TODO: Implement callback actions
import { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { selectToast, hideToast } from "../../store/toastSlice";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} variant="outlined" {...props} />;
});

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

export default function Toast() {
  const toast = useSelector(selectToast);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideToast());
  };

  return (
    <div>
      <Snackbar
        autoHideDuration={toast?.autoClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={toast?.show}
        TransitionComponent={SlideTransition}
        key={SlideTransition.name}
        onClose={handleClose}
        sx={{
          "&.MuiSnackbar-anchorOriginBottomRight": {
            right: "80px",
          },
        }}
      >
        <Alert
          severity={toast?.severity}
          sx={{ width: "100%", borderRadius: "9px", bgcolor: "#fff" }}
          onClose={handleClose}
        >
          {toast?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

import axios from "./axiosConfig";
import { rollbar, getUserId } from 'Core/helpers/rollbar';

//Account Management apis
export const getAcceptedMembers = async () => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/enterprise/get_company_members/accepted",
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const copyWorkflow = async (id, numCopies, startIndex) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/enterprise/workflow/${id}/copy`,
      data: { num_copies: parseInt(numCopies), start_index: parseInt(startIndex) },
    });
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const deleteWorkflow = async (id) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: `/enterprise/workflow/${id}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateWorkflowData = async (id, { title, status }) => {
  try {
    if (title) {
      const { data } = await axios({
        method: "PUT",
        url: `/enterprise/workflow/${id}/rename`,
        data: { new_title: title },
      })
      return data;
    } else if (status) {
      const { data } = await axios({
        method: "POST",
        url: `/enterprise/workflow/${id}/workflow_status`,
        data: { workflow_status: status },
      })
      return data;
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

//workflow apis
export const getCompletedWorkflow = async ({ pageParam, activeTab = "All Files", columnFilters = [],
  globalFilter = '', sorting = [], fetchSize = 50 }) => {
  try {
    const url = new URL('/enterprise/workflow', axios.defaults.baseURL);
    url.searchParams.set('processing_status', 'Completed');
    if (activeTab !== 'All Files') {
      url.searchParams.set('workflow_status', activeTab.replace(" ", "_"));
    }
    if (pageParam) {
      url.searchParams.set('start', `${pageParam * fetchSize}`);
    }
    url.searchParams.set('limit', `${fetchSize}`);
    url.searchParams.set('filters', JSON.stringify(columnFilters));
    url.searchParams.set('text', globalFilter);
    if (sorting.length) {
      url.searchParams.set('sort_by', sorting[0].id);
      url.searchParams.set('sort_order', sorting[0].desc ? 'desc' : 'asc');
    }
    const { data } = await axios({
      method: "GET",
      url: url.href
    });

    return data;
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};

export const getWorkflowTranscript = async (id) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: "/enterprise/workflow/" + id,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getPaginatedWorkflowTranscript = async (id, start = 25, limit = 1000) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/enterprise/transcript/${id}?start=${start}&limit=${limit}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const completeWorkflow = async (id) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/enterprise/workflow/${id}/workflow_status`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const reviewWorkflow = async (id) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/enterprise/workflow/${id}/review`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createWorkflow = async (payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/enterprise/workflow",
      data: payload,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateMultipleWorkflowStatuses = async (workflowIds, workflowStatus) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/enterprise/workflows/update_status",
      data: {
        workflow_ids: workflowIds,
        workflow_status: workflowStatus,
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const assignMemberToWorkflows = async (workflowIds, assignee) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: "/enterprise/workflow/assign_member",
      data: {
        workflow_ids: workflowIds,
        assignee: assignee,
      },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const assignWorkflow = async (docid, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/enterprise/workflow/${docid}/assignee`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveWorkflow = async (docid, payload) => {
  const userId = getUserId()
  try {
    rollbar.info(`Saving workflow ${docid} ${userId}`);
    const { data } = await axios({
      method: "POST",
      url: `/v2/enterprise/transcript/${docid}`,
      data: payload,
    });

    return data;
  } catch (e) {
    rollbar.error(`Error saving workflow ${docid} ${userId}`, { error: e });
    return Promise.reject(e);
  }
};

export const removeAssignedWorkflow = async (docid, payload) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: `/enterprise/workflow/${docid}/assignee`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateSpeakers = async (docid, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/enterprise/workflow/${docid}/speakers`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateSpecificSpeaker = async (docid, itemid, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/enterprise/transcript/${docid}/item/${itemid}/speaker`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const strikeText = async (docid, itemid, payload) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/enterprise/transcript/${docid}/item/${itemid}/strikethrough`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteStrikedText = async (docid, itemid, payload) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: `/enterprise/transcript/${docid}/item/${itemid}/strikethrough`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const findReplace = async (docid, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/enterprise/transcript/find_and_replace/${docid}`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addComment = async (docid, itemId, payload) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `/enterprise/comments/${docid}/transcript/${itemId}`,
      data: payload,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateComment = async ({ docid, itemId, body }) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/enterprise/comments/${docid}/transcript/${itemId}/comment/${body._id}`,
      data: body,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteComment = async (docid, itemId, commentId) => {
  try {
    const { data } = await axios({
      method: "DELETE",
      url: `/enterprise/comments/${docid}/transcript/${itemId}/comment/${commentId}`,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const exportTranscript = async (docid, format, options) => {
  try {
    const { data } = await axios({
      method: "GET",
      url: `/enterprise/transcript/export/${docid}/${format}`,
      responseType: 'blob',
      params: options,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const recalculateScore = async (id) => {
  const url = "/enterprise/hiring/score/recalculate";
  try {
    const { data } = await axios({
      method: "POST",
      data: { "ids": [id] },
      url,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTestFeedback = async (id) => {
  const url = `/enterprise/hiring/score/${id}`;
  try {
    const { data } = await axios({
      method: "GET",
      url,
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadAllZipFile = async () => {
  try {
    const url = `/enterprise/hiring/score/export_report_zip/all`;
    const response = await axios({
      method: "POST",
      url,
      data: {},
      responseType: "blob",
    });
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInsights,
  updateSpeaker,
  delSpeakerList,
  addSpeakerlist,
  updateTranscript,
  getAudiogramBackground,
  updateSummaryChapters,
  replaceAllTranscript,
} from "utils/apis";
import { updateContent } from "DAI/ProcessedData/services/apis";
import { setToast } from "store/toastSlice";
import { setAudiogramData } from "store/audiogramSlice";

const getClipboardCopyTitle = (title) => title;

const getClipboardCopySummary = (summary) => {
  return `[SUMMARY]:\n\n${summary}`;
};

const getClipboardCopyInsights = (insights, version) => {
  let insightsStr = "";

  if (version === null) {
    // Old format, iterate through list of insights
    insightsStr += "[CHAPTERS]\n\n";
    insightsStr += insights
      .map((insight) => `[${insight.timestamp}] ${insight.summary}`)
      .join("\n\n");
  } else {
    // Markdown
    insightsStr += insights;
  }

  return insightsStr;
};

const getClipboardCopyQuotes = (quotes, version) => {
  let quotesStr = "";

  if (version === null) {
    // Old format, iterate through list of quotes
    quotesStr += "[QUOTES]\n\n";
    quotesStr += quotes.map((quote) => {
      return `${quote.text}\n\n- ${quote.speaker}`;
    }).join("\n\n");
  } else {
    // Markdown
    quotesStr += quotes;
  }

  return quotesStr;
};

const getClipboardCopyTranscripts = (transcripts, fileVersion) => {
  let transcript = "[TRANSCRIPT]\n\n";

  if (fileVersion === null) {
    transcript += transcripts
    .map((t) => `[${t.timestamp}] *(${t.speaker})*: ${t.text}`)
    .join("\n\n");
  } else {
    transcript += transcripts
    // eslint-disable-next-line no-useless-escape
    .map((t) => `**${t.timestamp}** - \(${t.speaker}\): ${t.text}`)
    .join("\n\n");
  }

  return transcript;
};

const transcriptSlice = createSlice({
  name: "transcript",
  initialState: {
    transcript: [],
    insights: [],
    abstract: "",
    showNotes: "",
    article: null,
    tweet: null,
    caption: null,
    attribute: {},
    quotes: [],
    speakerList: [],
    error: false,
    loading: false,
    quotesno: 5,
    formatTranscriptPlainText: false,
    searchTranscript: "",
    searchTimestamp: "",
    searchQuotes: "",
    searchAudioGram: "",
    tabvalue: 1,
    speakerIndex: null,
    isShowSpeaker: false,
    isShowModal: false,
    sciptLoaded: false,
    docId: null,
    speakerUpdateLoading: false,
    isEditSpeakerModel: false,
    selectedSpeakerName: "",
    audiogram: [],
    audiogramBackground: [],
    videogram: [],
    fileName: "",
    anchorEl: null,
    speakerName: "",
    selectedVideoUrl: "",
    isVideoreelsGenerated: false,
    selecteTimeStamp: null,
    findText: "",
    replaceText: "",
    foundTextIndex: [],
    textFoundCount: 0,
    currentHighlightedIndex: { section: 0, wordIndex: 0, currentPointer: 0 },
    displayFindAndReplace: false,
    isSaving: false,
    fileVersion: null,
  },

  reducers: {
    initializeTanscripts: (state, action) => {
      state.transcript = action.payload.es_doc?.display_transcript || [];
      state.docId = action.payload.es_doc?.id;
      state.insights = action.payload.es_doc?.insight || [];
      state.abstract = action.payload.es_doc?.abstract || "";
      state.attribute = {
        name: action.payload.fb_doc?.author || "",
        date: action.payload.fb_doc?.display_datetime || "",
        title: action.payload.es_doc?.headline || action.es_doc?.title,
        headline: action.payload.es_doc?.headline,
        fileName: action.payload.es_doc?.title,
        fileType: action.payload.es_doc?.file_type,
      };
      state.quotes = action.payload.es_doc?.quotes || [];
      state.speakerList = action.payload.es_doc?.speakers || [];
      state.formatTranscriptPlainText =
        action.payload.es_doc?.display_transcript[0]?.hasOwnProperty("speaker");
      state.error = false;
      state.sciptLoaded = true;
      state.audiogram = action.payload.es_doc?.audiogram || [];
      state.videogram = action.payload.es_doc?.videogram;
      state.fileName = action.payload.fb_doc?.file_name || "";
      state.isVideoreelsGenerated = (action.payload.es_doc?.videogram || []).some(
        (v) => v.status === "Processed"
      );

      state.showNotes = action.payload.es_doc?.show_notes || "";
      state.article = action.payload.es_doc?.article || null;
      state.tweet = action.payload.es_doc?.tweet || null;
      state.caption = action.payload.es_doc?.caption || null;
      state.fileVersion = action.payload.es_doc?.version || null;
    },

    resetTranscripts: (state) => {
      state.transcript = [];
      state.docId = null;
      state.insights = [];
      state.abstract = "";
      state.quotes = [];
      state.speakerList = [];
      state.formatTranscriptPlainText = false;
      state.audiogram = [];
      state.videogram = [];
      state.fileName = "";
      state.showNotes = "";
      state.article = null;
      state.tweet = null;
      state.caption = null;
      state.fileVersion = null;
    },

    setAttributes: (state, action) => {
      state.attribute = action.payload;
    },

    setContent: (state, action) => {
      const { contentType } = action.payload;

      state[contentType] = action.payload.text;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setQuotesNo: (state, action) => {
      state.quotesno = action.payload;
    },
    setSearchTranscript: (state, action) => {
      state.searchTranscript = action.payload;
    },
    setSearchTimestamp: (state, action) => {
      state.searchTimestamp = action.payload;
    },
    setSearchQuotes: (state, action) => {
      state.searchQuotes = action.payload;
    },
    setSearchAudioGram: (state, action) => {
      state.searchAudioGram = action.payload;
    },
    setTabValue: (state, action) => {
      state.tabvalue = action.payload;
    },
    addSpeaker: (state, action) => {
      state.speakerList.push(action.payload);
    },
    delSpeaker: (state, action) => {
      state.speakerList = state.speakerList.filter(
        (speaker) => speaker !== action.payload
      );
    },
    updateAllSpeaker: (state, action) => {
      state.speakerList = state.speakerList.map((speaker) => {
        if (speaker === action.payload.oldSpeaker) {
          return action.payload.newSpeaker;
        }
        return speaker;
      });
      state.transcript.map((transcript) => {
        if (transcript.speaker === action.payload.oldSpeaker) {
          transcript.speaker = action.payload.newSpeaker;
        }
        return transcript;
      });
    },
    changeDisplayTranscriptSpeaker: (state, action) => {
      state.transcript[action.payload.index].speaker = action.payload.speaker;
    },
    updateTrancript: (state, action) => {
      console.log(action.payload.index);
      state.transcript[action.payload.index].text = action.payload.text;
    },
    handShowModal: (state, action) => {
      state.isShowSpeaker = true;
      state.speakerIndex = action.payload.index;
      state.anchorEl = action.payload.anchorEl;
      state.speakerName = action.payload.speaker;
    },
    handCloseModal: (state) => {
      state.isShowModal = false;
      state.isShowSpeaker = false;
    },
    handleOpenModal: (state, action) => {
      if (action.payload.isNew) {
        state.selectedSpeakerName = "Add New Speaker";
        state.isEditSpeakerModel = false;
      } else {
        state.selectedSpeakerName = action.payload.speaker;
        state.isEditSpeakerModel = true;
      }
      state.isShowModal = true;
      state.isShowSpeaker = false;
    },
    sciptLoaded: (state) => {
      state.sciptLoaded = true;
    },
    isEditSpeakerModel: (state, action) => {
      state.isEditSpeakerModel = action.payload;
    },
    selectedSpeakerName: (state, action) => {
      state.selectedSpeakerName = action.payload;
    },
    speakerUpdateLoading: (state, action) => {
      state.speakerUpdateLoading = action.payload;
    },
    setSelectedVideoUrl: (state, action) => {
      state.selectedVideoUrl = action.payload;
    },
    updateChapters: (state, action) => {
      state.insights = action.payload;
    },
    updateSummary: (state, action) => {
      state.abstract = action.payload;
    },
    setSelecteTimeStamp: (state, action) => {
      state.selecteTimeStamp = action.payload;
    },
    setFindText: (state, action) => {
      state.findText = action.payload;
      state.foundTextIndex = [];
      state.textFoundCount = 0;
      if (state.findText) {
        const regex = new RegExp(state.findText, "g");
        state.transcript.forEach((transcript, index) => {
          const match = transcript.text.match(regex);
          if (match) {
            const wordsFound = match.length;
            state.textFoundCount += wordsFound;
            state.foundTextIndex.push({
              index,
              textfound: match,
            });
          }
        });
      }
    },
    highlightForward: (state) => {
      if (state.foundTextIndex.length > 0) {
        let currentSection = state.currentHighlightedIndex.section;
        let currentPointer = state.currentHighlightedIndex.currentPointer;
        let currentWordIndex = state.currentHighlightedIndex.wordIndex;

        const currentSectionWordList =
          state.foundTextIndex[currentPointer].textfound;
        const nextWordIndex = currentWordIndex + 1;
        if (nextWordIndex < currentSectionWordList.length) {
          state.currentHighlightedIndex = {
            section: currentSection,
            wordIndex: nextWordIndex,
            currentPointer,
          };
        } else {
          currentPointer++;
          if (currentPointer === state.foundTextIndex.length) {
            currentPointer = 0;
          }
          currentSection = state.foundTextIndex[currentPointer].index;
          state.currentHighlightedIndex = {
            section: currentSection,
            wordIndex: 0,
            currentPointer,
          };
        }
      }
    },
    highlightBackward: (state) => {
      if (state.foundTextIndex.length > 0) {
        let currentSection = state.currentHighlightedIndex.section;
        let currentPointer = state.currentHighlightedIndex.currentPointer;
        let currentWordIndex = state.currentHighlightedIndex.wordIndex;

        const previousWordIndex = currentWordIndex - 1;
        if (previousWordIndex >= 0) {
          state.currentHighlightedIndex = {
            section: currentSection,
            wordIndex: previousWordIndex,
            currentPointer,
          };
        } else {
          currentPointer--;
          if (currentPointer < 0) {
            currentPointer = state.foundTextIndex.length - 1;
          }
          currentSection = state.foundTextIndex[currentPointer].index;
          const newSectionWordList =
            state.foundTextIndex[currentPointer].textfound;
          state.currentHighlightedIndex = {
            section: currentSection,
            wordIndex: newSectionWordList.length - 1,
            currentPointer,
          };
        }
      }
    },
    setReplaceText: (state, action) => {
      state.replaceText = action.payload;
    },
    replaceTextHandler: (state) => {
      if (state.foundTextIndex.length > 0) {
        const currentSection = state.currentHighlightedIndex.section;
        const currentWordIndex = state.currentHighlightedIndex.wordIndex;
        const currentPointer = state.currentHighlightedIndex.currentPointer;
        const currentSectionWordList =
          state.foundTextIndex[currentPointer].textfound;
        const currentWord = currentSectionWordList[currentWordIndex];
        const currentWordLength = currentWord.length;
        let currentWordStartIndex =
          state.transcript[currentSection].text.indexOf(currentWord);
        for (let i = 1; i <= currentWordIndex; i++) {
          currentWordStartIndex = state.transcript[currentSection].text.indexOf(
            currentWord,
            currentWordStartIndex + 1
          );
        }
        const currentWordEndIndex = currentWordStartIndex + currentWordLength;
        const beforeText = state.transcript[currentSection].text.substring(
          0,
          currentWordStartIndex
        );
        const afterText =
          state.transcript[currentSection].text.substring(currentWordEndIndex);
        state.transcript[currentSection].text =
          beforeText + state.replaceText + afterText;
      }
    },
    toggleDisplayFindAndReplace: (state) => {
      state.displayFindAndReplace = !state.displayFindAndReplace;
      state.findText = "";
      state.replaceText = "";
    },
    setFindAndReplace: (state, action) => {
      state.displayFindAndReplace = action.payload;
    },
    setTranscript : (state, action) => {
      state.transcript = action.payload;
    },

    setIsSaving: (state, action) => {
      state.isSaving = action.payload;
    },
  },
});

export const {
  initializeTanscripts,
  resetTranscripts,
  setAttributes,
  setContent,
  setError,
  setLoading,
  setQuotesNo,
  setSearchTranscript,
  setSearchTimestamp,
  setSearchQuotes,
  setTabValue,
  addSpeaker,
  changeDisplayTranscriptSpeaker,
  delSpeaker,
  updateAllSpeaker,
  handShowModal,
  handCloseModal,
  handleOpenModal,
  sciptLoaded,
  isEditSpeakerModel,
  selectedSpeakerName,
  speakerUpdateLoading,
  updateTrancript,
  setOpenAudioGramPreview,
  setSearchAudioGram,
  setintervalId,
  setSelectedVideoUrl,
  updateChapters,
  updateSummary,
  setSelecteTimeStamp,
  setFindText,
  textFoundCount,
  setReplaceText,
  setCurrentHighlightedIndex,
  setHighlightedText,
  highlightForward,
  highlightBackward,
  replaceTextHandler,
  setFindAndReplace,
  toggleDisplayFindAndReplace,
  setTranscript,
  setIsSaving,
} = transcriptSlice.actions;

export const transcriptSelector = (state) => state.transcript.transcript;
export const transcriptsSelector = (state) => state.transcript;
export const insightsSelector = (state) => state.transcript.insights;
export const abstractSelector = (state) => state.transcript.abstract;
export const selectShowNotes = (state) => state.transcript.showNotes;
export const selectArticle = (state) => state.transcript.article;
export const selectTweet = (state) => state.transcript.tweet;
export const selectCaption = (state) => state.transcript.caption;
export const isSaving = (state) => state.transcript.isSaving;
export const attributeSelector = (state) => state.transcript.attribute;
export const fileNameSelector = (state) => state.transcript.fileName;
export const quotesSelector = (state) => state.transcript.quotes;
export const speakerListSelector = (state) => state.transcript.speakerList;
export const errorSelector = (state) => state.transcript.error;
export const loadingSelector = (state) => state.transcript.loading;
export const quotesNoSelector = (state) => state.transcript.quotesno;
export const formatTranscriptPlainTextSelector = (state) =>
  state.transcript.formatTranscriptPlainText;
export const searchTimestampSelector = (state) =>
  state.transcript.searchTimestamp;
export const searchTranscriptSelector = (state) =>
  state.transcript.searchTranscript;
export const searchQuotesSelector = (state) => state.transcript.searchQuotes;
export const tabValueSelector = (state) => state.transcript.tabvalue;
export const speakerIndexSelector = (state) => state.transcript.speakerIndex;
export const isShowSpeakerSelector = (state) => state.transcript.isShowSpeaker;
export const isShowModalSelector = (state) => state.transcript.isShowModal;
export const sciptLoadedSelector = (state) => state.transcript.sciptLoaded;
export const isEditSpeakerModelSelector = (state) =>
  state.transcript.isEditSpeakerModel;
export const selectedSpeakerNameSelector = (state) =>
  state.transcript.selectedSpeakerName;
export const speakerUpdateLoadingSelector = (state) =>
  state.transcript.speakerUpdateLoading;
export const audiogramSelector = (state) => state.transcript.audiogram;
export const audiogramBackgroundSelector = (state) =>
  state.transcript.audiogramBackground;
export const searchAudioGramSelector = (state) =>
  state.transcript.searchAudioGram;
export const videogramSelector = (state) => state.transcript.videogram;
export const anchorElSelector = (state) => state.transcript.anchorEl;
export const speakerNameSelector = (state) => state.transcript.speakerName;
export const SelectedVideoUrlSelector = (state) =>
  state.transcript.selectedVideoUrl;
export const isVideoreelsGeneratedSelector = (state) =>
  state.transcript.isVideoreelsGenerated;
export const selecteTimeStampSelector = (state) =>
  state.transcript.selecteTimeStamp;
export const transcriptRefSelector = (state) => state.transcript.transcriptRef;
export const findTextSelector = (state) => state.transcript.findText;
export const textFoundCountSelector = (state) =>
  state.transcript.textFoundCount;
export const replaceTextSelector = (state) => state.transcript.replaceText;
export const foundTextIndexSelector = (state) =>
  state.transcript.foundTextIndex;
export const currentHighlightedIndexSelector = (state) =>
  state.transcript.currentHighlightedIndex;
export const displayFindAndReplaceSelector = (state) =>
  state.transcript.displayFindAndReplace;

export const copySummary = (state) => {
  const { abstract } = state.transcript;

  return getClipboardCopySummary(abstract);
};

export const copyQuotes = (state) => {
  const { audiogram, fileVersion, quotes } = state.transcript;
  let quotesData;

  if (fileVersion === null) {
    if (quotes && quotes.length) {
      quotesData = quotes;
    } else {
      quotesData = audiogram;
    }
  } else {
    // Markdown string
    quotesData = quotes;
  }

  return getClipboardCopyQuotes(quotesData, fileVersion);
};

export const copyChapters = (state) => {
  const { insights, fileVersion } = state.transcript;

  return getClipboardCopyInsights(insights, fileVersion);
};

export const copyTranscripts = (state) => {
  const { transcript } = state.transcript;

  return getClipboardCopyTranscripts(transcript);
};

export const copyShowNotes = (state) => {
  const { title } = state.transcript.attribute;
  const { abstract, fileVersion, insights, quotes, audiogram } = state.transcript;
  let quotesData;

  if (state.transcript.fileVersion === null) {
    // Old format
    quotesData = (quotes.length > 0) ? quotes : audiogram;

    return (
      `${getClipboardCopyTitle(title)}\n\n` +
      `${getClipboardCopySummary(abstract)}\n\n` +
      `${getClipboardCopyInsights(insights, fileVersion)}\n\n` +
      `${getClipboardCopyQuotes(quotesData, fileVersion)}\n\n`
    );
  } else {
    // Markdown
    return state.transcript.showNotes;
  }
};

export const copyArticle = (state) => state.transcript.article;
export const copyTweets = (state) => state.transcript.tweet;
export const copyCaption = (state) => state.transcript.caption;

export const copyContent = (state) => {
  const { title } = state.transcript.attribute;
  const { abstract, fileVersion, insights, quotes, audiogram, transcript } = state.transcript;
  let quotesData;

  if (fileVersion === null) {
    if (quotes && quotes.length) {
      quotesData = quotes;
    } else {
      quotesData = audiogram;
    }
  } else {
    // Markdown string
    quotesData = quotes;
  }

  return (
    `${getClipboardCopyTitle(title)}\n\n` +
    `${getClipboardCopySummary(abstract)}\n\n` +
    `${getClipboardCopyInsights(insights, fileVersion)}\n\n` +
    `${getClipboardCopyQuotes(quotesData, fileVersion)}\n\n` +
    `${getClipboardCopyTranscripts(transcript)}`
  );
};

export const getFileVersion = (state) => state.transcript.fileVersion;

export const getTranscript = createAsyncThunk(
  "transcript/fetchTranscript",
  async (id, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(setLoading(true));

    try {
      const response = await getInsights(id);

      dispatch(initializeTanscripts(response));

      if (response.error) {
        dispatch(setError(true));
        dispatch(setToast({ message: response.error, severity: "error" }));
      }

      const bg = await getAudiogramBackground();

      dispatch(
        setAudiogramData({
          audiogram: response.es_doc?.audiogram,
          audiogramBackground: bg,
          audioFile: response.es_doc?.serving_url,
          selectedVideo: bg[0],
          docId: id,
        })
      );
    } catch (e) {
      dispatch(setError(true));
      dispatch(setToast({ message: e.message, severity: "error" }));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const addSpeakerAsync = createAsyncThunk(
  "transcript/addSpeaker",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const {
      transcript: { docId },
    } = getState();

    dispatch(speakerUpdateLoading(true));

    try {
      await addSpeakerlist(docId, {
        speaker: payload.speaker,
      });

      dispatch(addSpeaker(payload.speaker));
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }

    dispatch(speakerUpdateLoading(false));
    dispatch(handCloseModal());
  }
);

export const delSpeakerAsync = createAsyncThunk(
  "transcript/delSpeaker",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const {
      transcript: { docId },
    } = getState();
    dispatch(speakerUpdateLoading(true));

    try {
      await delSpeakerList(docId, {
        speaker: payload.speaker,
      });

      dispatch(delSpeaker(payload.speaker));
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }

    dispatch(speakerUpdateLoading(false));
    dispatch(handCloseModal());
  }
);

export const updateAllSpeakerAsync = createAsyncThunk(
  "transcript/updateAllSpeaker",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    const {
      transcript: { docId },
    } = getState();
    const newPayload = {
      current_speaker: payload.oldSpeaker,
      new_speaker: payload.newSpeaker,
      replace_all: payload.isall,
      index: payload.index,
    };

    dispatch(speakerUpdateLoading(true));

    try {
      await updateSpeaker(docId, newPayload);
      if (payload.isall) {
        dispatch(updateAllSpeaker(payload));
      } else {
        dispatch(
          changeDisplayTranscriptSpeaker({
            index: payload.index,
            speaker: payload.newSpeaker,
          })
        );
      }
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error", autoClose: true }));
    } finally {
      dispatch(speakerUpdateLoading(false));
    }

    dispatch(handCloseModal());
  }
);

export const updateSpecficTrancript = createAsyncThunk(
  "transcript/updateSpecficTrancript",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const {
      transcript: { docId },
    } = getState();

    try {
      await updateTranscript(docId, payload);
      dispatch(
        updateTrancript({
          index: payload.index,
          text: payload.text,
        })
      );
      dispatch(setToast({ message: "Transcript Updated", severity: "success", autoClose: true }));
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error", autoClose: true }));
    }
  }
);

export const replaceSpecficTrancript = createAsyncThunk(
  "transcript/replaceSpecficTrancript",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    dispatch(replaceTextHandler());
    const {
      transcript: { docId, currentHighlightedIndex, transcript },
    } = getState();
    const index = currentHighlightedIndex.section;
    const text = transcript[index].text;
    try {
      await updateTranscript(docId, {
        index,
        text,
      });
      dispatch(setToast({ message: "Replaced", severity: "success", autoClose: true }));
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error", autoClose: true }));
    }
  }
);

export const replaceAll = createAsyncThunk(
  "transcript/replaceAllTranscript",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const {
      transcript: { docId, findText, replaceText },
    } = getState();

    try {
      const response = await replaceAllTranscript(docId, {
        find: findText,
        replace: replaceText,
      });
      dispatch(setTranscript(response));
      dispatch(setReplaceText(""));
      dispatch(setFindText(""));
      dispatch(setToast({ message: "Replaced All", severity: "success", autoClose: true }));
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error", autoClose: true }));
    }
  }
);

export const updateChaptersAndSummary = createAsyncThunk(
  "transcript/updateChaptersAndSummary",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const {
      transcript: { docId, abstract, insights },
    } = getState();

    const constructedPayload = {};
    const hasSummary = payload.hasOwnProperty("summary");
    const hasChapter = payload.hasOwnProperty("chapters");

    if (hasSummary) {
      constructedPayload.summary = payload.summary;
      constructedPayload.chapters = insights;
    } else if (hasChapter) {
      constructedPayload.summary = abstract;
      constructedPayload.chapters = payload.chapters;
    }

    try {
      await updateSummaryChapters(docId, constructedPayload);
      if (hasSummary) {
        dispatch(updateSummary(payload.summary));
        dispatch(
          setToast({
            message: "summary updated",
            severity: "success",
            autoClose: true,
          })
        );
      } else if (hasChapter) {
        dispatch(updateChapters(payload.chapters));
        dispatch(
          setToast({
            message: "chapters updated",
            severity: "success",
            autoClose: true,
          }),
        );
      }
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
  }
);

export const updateContentItem = createAsyncThunk("transcript/updateContent", async (payload, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { transcript } = getState();
  const { docId } = transcript;
  const { content, type } = payload;

  dispatch(setIsSaving(true));

  try {
    await updateContent(docId, type, content);

    let contentType = "";

    switch (type) {
      case "show_notes":
        contentType = "showNotes";
        break;
      case "summary":
        contentType = "abstract";
        break;
      case "chapters":
        contentType = "insights";
        break;
      default:
        contentType = type;
    }

    dispatch(setContent({ contentType, text: content }));

    dispatch(
      setToast({
        message: `${type} has been updated`,
        severity: "success",
        autoClose: true,
      }),
    )
  } catch (e) {
    dispatch(
      setToast({
        message: `${type} could not be updated at this time. Please retry in a bit.`,
        severity: "error",
      }),
    )
  } finally {
    dispatch(setIsSaving(false));
  }
});

export default transcriptSlice.reducer;

import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";

import {
  setShowCheckoutModel
} from "../../store/payment";

function PayUsage({ payload }) {
  const { name, price, minutes } = payload;
  const dispatch = useDispatch();

  const showModalCheckout = () => {
    dispatch(setShowCheckoutModel({
      show: true,
      plan: payload,
      isPayAsYouGo: true,
    }));
  };

  return (
    <Box
      sx={{
        padding: "35px 44px",
        borderRadius: "24px",
        background: "#fff",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <Box>
          <Typography sx={{ color: "#000", fontSize: "24px", fontWeight: 500 }}>
            {name}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "left", gap: "24px" }}>
            <Box sx={{ display: "flex", justifyContent: "left" }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "37px",
                  color: "#000",
                  pt: "29px",
                }}
              >
                $
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "96px", color: "#000" }}
              >
                {price.onetime}
              </Typography>
            </Box>
            <Box sx={{ pt: "38px" }}>
              <Typography
                sx={{ fontWeight: 500, fontSize: "32px", color: "#6C7275" }}
              >
                +{minutes}
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "16px", color: "#6C7275" }}
              >
                minutes
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Button
        sx={{
          padding: "12px 58px",
          lineHeight: "unset",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "HelveticaNowDisplay",
          background: "#0659F9",
          borderRadius: "100px",
          color: "#fff",
        }}
        onClick={showModalCheckout}
      >
        Purchase
      </Button>
    </Box>
  );
}

export default PayUsage;

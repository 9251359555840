import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import daiLoading from "../../../assets/dai-loading.gif";

export default function LazyLoadingFallback() {
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "rgba(255, 255, 255, 0.9)",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2000,
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img src={daiLoading} alt="" width="96" height="96" />
      </div>

      <Typography
        sx={{
          color: "#000",
          fontSize: "15px",
          fontWeight: "600",
          textAlign: "center",
          mt: "12px",
        }}
      >
        One moment please...
      </Typography>
    </Box>
  );
}

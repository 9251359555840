import { Suspense, lazy } from "react";
import MaterialLayout from "hoc/materialLayout";
import Wrapper from "hoc/wrapper";
import ScrollToTop from "components/ScrollToTop";
import CoreRoutes from "Core/routes";
import DAIRoutes from "DAI/routes";
// import DAIEditorRoutes from "DAIEditor/routes";
import LazyLoadingFallback from "Core/components/LazyLoadingFallback";

const DAIEditorRoutes = lazy(() => import("DAIEditor/routes"));

export default function DeciphrRoutes() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isDAIAccount = userInfo ? (userInfo.org_role === null) : false;
  const isEditorAccount = userInfo ? (userInfo.org_role !== null) : false;

  return (
    <Suspense fallback={<LazyLoadingFallback />}>
      <ScrollToTop />

      <MaterialLayout>
        <Wrapper>
          <CoreRoutes isEnabled={userInfo === null} />
          <DAIEditorRoutes isEnabled={userInfo !== null && isEditorAccount} />
          <DAIRoutes isEnabled={userInfo !== null && isDAIAccount} />
        </Wrapper>
      </MaterialLayout>
    </Suspense>
  );
}

export const ColorMap = ["#D4E2FD", "#EFDFFF", "#CEFDEE", "#9BDBFF", "#A6FFE0"];

export const accuracyMap = [
  {
    accuracy: "< 20%",
    color: "#D7343F",
    term: "bad",
  },
  {
    accuracy: "20% – 90%",
    color: "#FFC83E",
    term: "good",
  },
  {
    accuracy: "90% +",
    color: "#5CC05A",
    term: "excellent",
  },
];

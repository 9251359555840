import axios from "axios";
import { history } from "../utils/history";

const instance = axios.create({});
instance.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
instance.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo && userInfo.token) {
      config.headers.Authorization = `Bearer ${userInfo.token}`;
    }

    config.baseURL = process.env.REACT_APP_BACKEND_URL;

    if (process.env.NODE_ENV === "development"
      && process.env.REACT_APP_REMOTE_BACKEND === true) {
      // Prepend /dai_api to URLs to proxy requests to the staging
      // server on the development environment
      config.url = `/dai_api${config.url}`;
    }

    return config;
  },
  (error) => {
    console.log(error);
  }
);

instance.interceptors.response.use(
  (res) => res,
  (error) => {
    const err = error;
    if (err.code === "ERR_NETWORK") {
      err.message = "Network connectivity issue. Please check your internet connection.";
    } else if (error.response) {
      err.code = error.response.status;

      if (error.response.status === 401) {
        localStorage.removeItem("userInfo");

        if (history.location.pathname !== "/auth/login") {
          history.navigate('/auth/login', {
            state: {
              referrer: history.location.pathname,
              message: "You must sign in to your Inflexion AI account to continue.",
            },
          })
        }
      }
    }

    return Promise.reject(err);
  },
);

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance;

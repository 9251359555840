import React from "react";

import { Box } from "@mui/system";
import { Popover, Typography } from "@mui/material";

import { ReactComponent as CloseIcon } from "../../assets/close.svg";

import "./index.css";

const Notifications = ({ anchorEl, close }) => {
  const dummyNotificationData = [
    {
      heading: "Upload completed",
      description: "Your file has been uploaded.",
      isNew: true,
      timeAgo: "1h ago",
    },
    {
      heading: "Deciphr’ed ",
      description: "All of outputs of “DebitNoteMckee.mp3” are ready to go.",
      isNew: false,
      timeAgo: "1h ago",
      isFile: true,
    },
    {
      heading: "File deleted",
      description:
        "DebitNoteMckee.mp3 and all outputs have been removed from library",
      isNew: false,
      timeAgo: "1h ago",
    },
  ];

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      classes={{ paper: "notification-paper" }}
      anchorOrigin={{ horizontal: "center", vertical: "center" }}
      transformOrigin={{ horizontal: "right", vertical: "center" }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "346px",
          },
          height: {
            xs: "100vh",
            sm: "auto",
          },
          padding: {
            xs: "127px 24px 70px",
            sm: "16px",
          },
        }}
      >
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "none",
            },
            position: "absolute",
            top: "80px",
            left: "24px",
          }}
          onClick={close}
        >
          <CloseIcon />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: {
                xs: 700,
                sm: 500,
              },
              fontSize: {
                xs: "32px",
                sm: "16px",
              },
              lineHeight: {
                xs: "56px",
                sm: "24px",
              },
              color: "#23262F",
            }}
          >
            Notification
          </Typography>
          <Typography
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "20px",
              color: "#0659F9",
              cursor: "pointer",
            }}
          >
            Mark all as read
          </Typography>
        </Box>
        <Box
          sx={{
            maxHeight: {
              xs: `${window.innerHeight - 280}px`,
              sm: "425px",
            },
            overflowY: "scroll",
          }}
        >
          {dummyNotificationData.map((notification, i) => (
            <Box
              key={i}
              sx={{
                mt: "16px",
                backgroundColor: "#fff",
                borderRadius: "12px",
                padding: "16px 20px 16px 16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: "#141416",
                    lineHeight: "24px",
                  }}
                >
                  {notification.heading}
                </Typography>
                {notification.isNew && (
                  <span
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "#45B26B",
                      width: "12px",
                      height: "12px",
                    }}
                  />
                )}
              </Box>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#353945",
                  lineHeight: "20px",
                }}
              >
                {notification.description}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: "15px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#777E90",
                    lineHeight: "20px",
                  }}
                >
                  {notification.timeAgo}
                </Typography>
                {notification.isFile && (
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "12px",
                      color: "#0659F9",
                      lineHeight: "20px",
                      ml: "15px",
                      cursor: "pointer",
                    }}
                  >
                    View File
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Box>

        <Typography
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
            fontWeight: "600",
            fontSize: "12px",
            color: "#0659F9",
            lineHeight: "20px",
            mt: "16px",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          Show all
        </Typography>
        <Typography
          sx={{
            display: {
              xs: "inline",
              sm: "none",
            },
            position: "absolute",
            bottom: "60px",
            fontWeight: "600",
            fontSize: "14px",
            color: "#0659F9",
            lineHeight: "20px",
            mt: "16px",
            textAlign: "center",
            cursor: "pointer",
            transform: "translateX(-50%)",
            left: "50%",
          }}
        >
          Mark all as read
        </Typography>
      </Box>
    </Popover>
  );
};

export default Notifications;

import { useContext } from "react";
import { Box, Typography, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logodark.svg";
import mascort from "../../assets/Deciphr-mascot-party.svg";
import { resendVerification } from "../../utils/apis";
import { GlobalData } from "../../context";

const Ppr = ({ header, body }) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        background: "#F1F3F4",
        width: { xs: "100%", sm: "100%", lg: "228px" },
        height: "137px",
        border: " 1px solid #D9D9D9",
        borderRadius: "8px",
        alignItems: "center",
        padding: "18px",
        gap: 1,
        mb: { xs: "20px", md: "0px" },
      }}
      elevation={0}
    >
      <Typography sx={{ display: "flex", width: "100%", color: "#0659F9", fontSize: "34px", fontWeight: "700", alignItems: "center", justifyContent: 'center' }}>
        {header}
      </Typography>
      <Typography
        sx={{ fontSize: "14px", textAlign: "center", fontWeight: 700 }}
      >
        {body}
      </Typography>
    </Paper >
  );
};

const steps = [
  "Look for our verification  e-mail in your inbox",
  "Click on the button to verify your account",
  "Sign into your account and get Deciphr’ng!",
];

export default function FullScreenDialog() {
  const history = useNavigate();
  const { openAlert, setMessage, setSeverity } = useContext(GlobalData);

  const resendHandler = async () => {
    const {
      user: { email, displayName },
    } = JSON.parse(localStorage.getItem("userInfo")) || {};
    console.log(email, displayName);
    try {
      await resendVerification({
        email,
        user_name: displayName,
      });
      displayMsg("success", "Email Sent!");
    } catch (err) {
      console.log(err);
      displayMsg("error", err?.response?.data?.message);
    }
  };

  const displayMsg = (severity, payload) => {
    setSeverity(severity);
    openAlert();
    setMessage(payload);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: '46px',
        }}
      >
        <Box>
          <img
            src={mascort}
            alt="logo"
            style={{ width: "175px", height: "auto" }}
          />
        </Box>
        <Box sx={{ textAlign: "center", mt: "28px" }}>
          <Typography
            sx={{
              fontSize: "35px",
              fontWeight: 500,
            }}
          >
            You've successfully signed up for Deciphr!
          </Typography>
          <Typography
            sx={{
              fontFamily: "HelveticaNowDisplay",
              fontSize: "24px",
              mt: "0px",
              fontWeight: 500,
            }}
          >
            Here’s what comes next:
          </Typography>
        </Box>
        <Box
          sx={{
            display: { lg: "flex", },
            gap: "32px",
            mt: "28px",
            justifyContent: "space-between",
            padding: '0px 20px',

          }}
        >
          {steps.map((v, i) => (
            <Ppr key={i + "@steps"} header={i + 1} body={v} />
          ))}
        </Box>
        <Box sx={{ textAlign: "center", mt: { lg: "28px", xs: "8px" }, padding: "0px 20px" }}>
          <Typography sx={{ fontSize: "18px", color: "#070707" }}>
            Didn’t get an e-mail? Check your spam folder or{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "#0659F9",
                cursor: "pointer",
                fontWeight: "500",
              }}
              onClick={resendHandler}
            >
              click here to resend
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            my: "46px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            disableElevation
            sx={{
              background: "#0659F9",
              color: "#fff",
              width: "353px",
              height: "69px",
              fontSize: "20px",
              "&:hover": {
                background: "#0659F9",
                color: "#fff",
              },
            }}
            onClick={() => history("/upload_transcript")}
          >
            Upload your first transcript
          </Button>
          <Box>
            <img
              src={logo}
              alt="logo"
              style={{ width: "175px", height: "auto", marginTop: "54px" }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

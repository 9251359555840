// file name lolol
import { createSlice } from '@reduxjs/toolkit';

const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    show: false,
    message: '',
    severity: 'info',
    autoClose: null,
  },

  reducers: {
    showToast: (state) => {
      state.show = true;
    },

    hideToast: (state) => {
      state.show = false;
      state.message = '';
      state.severity = 'info';
      state.autoClose = null;
    },

    setToast: (state, action) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity || 'info';
      state.autoClose = action.payload.autoClose ? 4000 : null;
      state.show = true;
    },
  },
});

export const { showToast, hideToast, setToast } = toastSlice.actions;

export const selectToast = (state) => state?.toast;

export default toastSlice.reducer;

import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import NavbarV2 from "../dashboardNav/indexV2";

const PageLoader = ({ customLoader = null }) => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavbarV2 />

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {customLoader || <CircularProgress size={80} sx={{ color: "#0659F9" }} />}
      </Box>
    </Box>
  );
};

export default PageLoader;

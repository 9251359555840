import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function BilledAmount({ totalAmount = 0, periodicCharge, duration = "oneOff" }) {
  const getRepeatChargeText = () => {
    let text = " now";
    const adjectiveMap = {
      monthly: "every month",
      yearly: "every year",
    };
    const period = (duration === "yearly") ? 12 : 1;

    if (duration !== "oneOff") {
      text += `, and $${(periodicCharge * period).toFixed(2)} `
        + `${adjectiveMap[duration]}, unless you cancel.`;
    }

    return text;
  };

  return (
    <>
      <Typography
        sx={{
          pt: "20px",
          pb: "16px",
          color: "#141718",
          fontWeight: 700,
          lineHeight: "40px",
          fontSize: "28px",
        }}
      >
        Billed now: ${totalAmount.toFixed(2)}
      </Typography>

      <Typography
        sx={{
          color: "#6C7275",
          fontWeight: 500,
          lineHeight: "20px",
          fontSize: "12px",
          pb: "16px",

          "& strong": {
            fontWeight: 700,
          },
        }}
      >
        By clicking <strong>"Make Payment"</strong>, you agree to be charged
        ${totalAmount.toFixed(2)} {getRepeatChargeText()}
      </Typography>
    </>
  );
}

BilledAmount.propTypes = {
  totalAmount: PropTypes.number.isRequired,
  periodicCharge: PropTypes.number,
  duration: PropTypes.oneOf(["monthly", "yearly", "oneOff"]),
};

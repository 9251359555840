import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckoutInput from "DAI/Pricing/components/Input";
import CountrySelect from "DAI/Pricing/components/CountrySelect";
import PromoCode from "DAI/Pricing/containers/PromoCode";
import CardError from "DAI/Pricing/components/CardError";
import CardInfo from "DAI/Pricing/components/CardInfo";
import CheckoutTotal from "DAI/Pricing/containers/CheckoutTotal";
import PayTab from "./payTab";
import Addons from "../../components/addons";
import {
  selectedPlanSelector,
  getCardError,
  setShowCheckoutModel,
  showingCheckoutModelSelector,
  pay,
  loadingSelector,
  globalMonthlyCheckSelector,
  isPayAsYouGoSelector,
  payAsYouGoSelector,
  setSelectedPlan,
  addonsSelector,
  setAddons,
  setPlanDuration,
  setGloablMonthlyCheck,
  setCardError,
} from "../../store/payment";
import { getDefaultCard } from "../../store/user";
import { addonsMap, planMap } from "../../utils/paymentPlans";
import {
  IconCheckCircle,
  IconClose,
  IconPadlockModal,
} from "../../components/icons";
import { analytics } from "../../utils/GTM";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "14px",
          color: "#424770",
          "::placeholder": {
            color: "rgba(108, 114, 117, 0.5)",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

function ModalCheckout() {
  const [planSelector, setPlanSelector] = useState("monthly");
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const defaultCard = useSelector(getDefaultCard);
  const addons = useSelector(addonsSelector);
  const plan = useSelector(selectedPlanSelector);
  const payAsYouGo = useSelector(payAsYouGoSelector);
  const open = useSelector(showingCheckoutModelSelector);
  const loading = useSelector(loadingSelector);
  const monthlyCheck = useSelector(globalMonthlyCheckSelector);
  const isPayAsYouGo = useSelector(isPayAsYouGoSelector);
  const cardError = useSelector(getCardError);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      setShowCheckoutModel({
        show: false,
        plan: {},
      })
    );
    analytics.track("User Closed Checkout Model");
  };

  const handleSubmit = async (event) => {
    let token;

    setError(null);

    event.preventDefault();

    if (!defaultCard) {
      if (!stripe || !elements) {
        return;
      }

      if (!name) {
        setNameError("Enter the name on card");

        return;
      } else {
        setNameError(null);
      }

      if (!countryCode) {
        setCountryError("Select your country");

        return;
      } else {
        setCountryError(null);
      }

      const payload = await stripe.createToken(
        elements.getElement(CardElement),
        { name, address_country: countryCode }
      );

      if (payload.error) {
        setError(payload.error.message);

        return;
      }

      token = payload.token.id;
    }

    dispatch(
      pay({
        token_id: token || "",
        plan: isPayAsYouGo ? "" : planMap[plan?.name]?.[planSelector] || "",
        add_on: isPayAsYouGo
          ? addonsMap[plan?.price?.onetime]
          : addonsMap[addons],
        cancel: false,
        isPayAsYouGo,
        addonPrice: isPayAsYouGo ? plan?.price?.onetime : addons,
      })
    );

    analytics.track("Payment Initiated", {
      plan: isPayAsYouGo ? "" : planMap[plan?.name]?.[planSelector] || "",
      add_on: isPayAsYouGo
        ? addonsMap[plan?.price?.onetime]
        : addonsMap[addons],
    });
  };

  const onCardDataChanged = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }

    if (cardError) {
      dispatch(setCardError(null));
    }
  };

  useEffect(() => {
    if (open) {
      const duration =
        isPayAsYouGo === false ? (monthlyCheck ? "monthly" : "yearly") : "";

      dispatch(setPlanDuration(duration));
      dispatch(setAddons(0));
      analytics.track(
        isPayAsYouGo
          ? "PAYG checkout opened with " + plan?.name + " Addon"
          : "Checkout opened with " + plan?.name + " " + planSelector + " plan"
      );
    }
  }, [dispatch, open, plan, planSelector, isPayAsYouGo, monthlyCheck]);

  useEffect(() => {
    if (monthlyCheck) {
      setPlanSelector("monthly");
      dispatch(setPlanDuration("monthly"));
    } else {
      setPlanSelector("yearly");
      dispatch(setPlanDuration("yearly"));
    }
  }, [monthlyCheck, dispatch]);

  return (
    <Box>
      <Dialog
        open={open}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "24px",
            overflowX: "hidden",

            "@media (max-width: 767px)": {
              borderRadius: "8px",
            },
          },
        }}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "24px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              paddingTop: "62px",
              fontWeight: 700,
              fontSize: "32px",
              color: "#23262F",

              "@media screen and (max-width: 767px)": {
                fontSize: "24px",
                paddingTop: "20px",
              },
            }}
            component="h2"
          >
            Checkout
          </Typography>

          <Box
            sx={{
              position: "absolute",
              top: "28px",
              right: "28px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <IconClose />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "50px",
              padding: "65px 40px",

              "@media screen and (max-width: 767px)": {
                flexFlow: "column",
                padding: "10px",
              },
            }}
          >
            <Box
              sx={{
                padding: "25px 25px 25px 30px",
                borderRadius: "12px",
                background: "#fff",
                border: "2px solid #E8ECEF",

                "& p": {
                  fontFamily: "HelveticaNowDisplay",
                },

                "@media screen and (max-width: 767px)": {
                  padding: "10px 10px 10px 15px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "12px",
                  pb: "30px",
                }}
              >
                <PayTab
                  isActive={planSelector === "monthly"}
                  onClick={() => {
                    setPlanSelector("monthly");
                    dispatch(setPlanDuration("monthly"));
                    dispatch(setGloablMonthlyCheck(true));
                    analytics.track("Monthly plan selected");
                  }}
                  tag={isPayAsYouGo ? plan.tag : "Pay Monthly"}
                  price={
                    isPayAsYouGo ? plan?.price?.onetime : plan?.price?.monthly
                  }
                  isOneTimeCharge={isPayAsYouGo}
                />

                {!isPayAsYouGo && (
                  <PayTab
                    isActive={planSelector === "yearly"}
                    onClick={() => {
                      setPlanSelector("yearly");
                      dispatch(setPlanDuration("yearly"));
                      dispatch(setGloablMonthlyCheck(false));
                      analytics.track("Yearly plan selected");
                    }}
                    tag={"Pay Yearly"}
                    price={plan?.price?.yearly}
                    isSale={true}
                  />
                )}
              </Box>

              <Typography
                sx={{
                  color: "#0659F9",
                  fontWeight: 700,
                  fontSize: "28px",
                  lineHeight: "40px",
                  pb: "4px",

                  "@media screen and (max-width: 767px)": {
                    fontSize: "20px",
                    lineHeight: "20px",
                  },
                }}
              >
                {plan.name}
              </Typography>

              <Typography
                sx={{
                  color: "#141718",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  pb: "24px",

                  "@media screen and (max-width: 767px)": {
                    fontSize: "14px",
                    pb: "12px",
                  },
                }}
              >
                {plan.description}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  gap: "8px",
                  alignItems: "end",
                  pb: "12px",
                }}
              >
                <Typography
                  sx={{
                    color: "#141718",
                    fontWeight: 700,
                    lineHeight: "48px",
                    fontSize: "56px",

                    "@media screen and (max-width: 767px)": {
                      fontSize: "40px",
                    },
                  }}
                >
                  $
                  {isPayAsYouGo
                    ? plan?.price?.onetime
                    : plan?.price?.[planSelector]}
                </Typography>

                {!isPayAsYouGo && (
                  <Typography
                    aria-label="per month"
                    sx={{
                      color: "rgba(108, 114, 117, 0.5)",
                      fontWeight: 700,
                      lineHeight: "40px",
                      fontSize: "28px",
                      mb: "-5px",

                      "@media screen and (max-width: 767px)": {
                        fontSize: "20px",
                      },
                    }}
                  >
                    / mo
                  </Typography>
                )}
              </Box>

              <Typography
                sx={{
                  color: "#6C7275",
                  fontWeight: 500,
                  lineHeight: "24px",
                  fontSize: "16px",
                  pb: "32px",
                }}
              >
                {plan.expiredTime}
              </Typography>

              {plan?.features?.map((feature, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: "13px",
                    pb: "16px",
                  }}
                >
                  <IconCheckCircle />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#141718",
                        fontWeight: 700,
                        lineHeight: "24px",
                        fontSize: "14px",
                      }}
                    >
                      {feature.main}
                    </Typography>

                    <Typography
                      sx={{
                        color: "#141718",
                        fontWeight: 500,
                        lineHeight: "24px",
                        fontSize: "14px",
                      }}
                    >
                      {feature.sub}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                "& p": {
                  fontFamily: "HelveticaNowDisplay",
                },
              }}
            >
              <Box
                sx={{
                  border: "2px solid #E8ECEF",
                  padding: "20px",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  sx={{
                    color: "#343839",
                    fontWeight: 500,
                    lineHeight: "24px",
                    fontSize: "14px",
                    pb: "20px",
                  }}
                >
                  Card details
                </Typography>

                {defaultCard === undefined && (
                  <>
                    <CardElement
                      options={options}
                      onChange={onCardDataChanged}
                    />

                    <CardError message={error || cardError} />
                  </>
                )}

                {defaultCard !== undefined && (
                  <>
                    <CardInfo card={defaultCard} />

                    <CardError message={error} />
                  </>
                )}
              </Box>

              {defaultCard === undefined && (
                <Grid container>
                  <Grid item xs={12}>
                    <CheckoutInput
                      id="name"
                      label="Name on card"
                      placeholder="Enter the cardholder name"
                      required
                      onChange={setName}
                    />

                    <CardError message={nameError} />
                  </Grid>

                  <Grid item xs={12}>
                    <CountrySelect onChange={setCountryCode} />

                    <CardError message={countryError} />
                  </Grid>
                </Grid>
              )}

              <Box
                sx={{
                  pt: "12px",
                  pb: "12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <IconPadlockModal />
                <Typography
                  sx={{
                    color: "#6C7275",
                    fontWeight: 500,
                    lineHeight: "20px",
                    fontSize: "12px",
                  }}
                >
                  Secured payment with Stripe
                </Typography>
              </Box>

              <PromoCode />

              <Box
                sx={{
                  "& p": {
                    color: "#343839",
                    fontWeight: 500,
                    lineHeight: "24px",
                    fontSize: "14px",
                  },
                }}
              >
                <Typography sx={{ pb: "10px" }}>Need more upload?:</Typography>

                {payAsYouGo
                  .filter((v) => {
                    if (isPayAsYouGo) {
                      return v.id !== plan.id;
                    }

                    return v;
                  })
                  .map((item, index) => (
                    <Addons
                      price={item.price.onetime}
                      key={index}
                      description={item.name}
                      disabled
                      selected={addons === item.price.onetime}
                      min={item.minutes}
                      add={() => {
                        if (isPayAsYouGo) {
                          dispatch(setSelectedPlan(item));
                        } else {
                          dispatch(setAddons(item.price.onetime));
                        }
                        analytics.track(item.name + " Add-on Selected");
                      }}
                      remove={() => {
                        dispatch(setAddons(0));
                        analytics.track(item.name + " Add-on removed");
                      }}
                    />
                  ))}
              </Box>

              <CheckoutTotal />

              <LoadingButton
                loading={loading}
                disabled={loading}
                sx={{
                  height: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderRadius: "999px",
                  color: "#fff",
                  background: "#0659F9",
                  fontWeight: 600,
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontFamily: "HelveticaNowDisplay",
                }}
                onClick={handleSubmit}
              >
                Make Payment
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default ModalCheckout;

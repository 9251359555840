import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import PlanItem from "./planItem";
import { planSelector, setShowCheckoutModel, globalMonthlyCheckSelector } from "../../store/payment";
import { userInfoSelector } from "../../store/user";
import { availablePlans } from "../../utils/paymentPlans";

const styles = {
  currentPlanBtn: {
    width: "100%",
    border: "2px solid #E8ECEF",
    borderRadius: "100px",
    mb: "16px",
    mt: "16px",
    color: "#d0d4d6 !important",
    bgcolor: "#F7F7F7",
    pointerEvents: "auto !important",
    cursor: "not-allowed !important",
  },
  upgradeBtn: {
    width: "100%",
    border: "2px solid #0659F9",
    borderRadius: "100px",
    mb: "16px",
    mt: "16px",
    color: "#FEFEFE",
    backgroundColor: "#0659F9",
  },
};

const PlanGroups = ({ noText, noheading }) => {
  const plan = useSelector(planSelector);
  const { plan: defaultPlan } = useSelector(userInfoSelector);
  const isMonthlyBilling = useSelector(globalMonthlyCheckSelector);
  const dispatch = useDispatch();
  const history = useNavigate();

  const showModalCheckout = (plan) => {
    const user = localStorage.getItem("userInfo");

    if (user !== null) {
      dispatch(
        setShowCheckoutModel({
          show: true,
          plan: plan,
          isPayAsYouGo: false,
        })
      );
    } else {
      history("/auth/signup", {
        state: {
          redirectTo: "/pricing",
        }
      });
    }
  };

  return (
    <Grid
      container
      spacing={"4px"}
      sx={{ mt: "52px", mb: "27px", justifyContent: "center" }}
    >
      {!noheading && (
        <Grid item xs={6} md={2.4}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "28px",
              lineHeight: "40px",
              color: "#141718",
            }}
          >
            Choose plan
          </Typography>
        </Grid>
      )}

      {plan.map((item) => (
        <PlanItem
          title={item.name}
          description={item.description}
          price={isMonthlyBilling ? `$${item.price.monthly}` : `$${item.price.yearly}`}
          expiredTime={item.expiredTime}
          features={noText ? [] : item.features}
          config={item.config}
          textSizeSmall={noText}
          key={item.id}
          actionButton={
            <>
              <Button
                disabled={item.name === defaultPlan}
                sx={
                  (item.name === defaultPlan) ? styles.currentPlanBtn : styles.upgradeBtn
                }
                onClick={() => showModalCheckout(item)}
              >
                {availablePlans[defaultPlan]?.includes(item.name)
                  ? item.name === defaultPlan
                    ? "Current Plan"
                    : "Downgrade"
                  : "Upgrade"}
              </Button>
            </>
          }
        />
      ))}
    </Grid>
  );
};

export default PlanGroups;

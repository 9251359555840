import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import visa from "../../../../assets/visa.svg"
import mastercard from "../../../../assets/mastercard.svg";
import amex from "../../../../assets/amex.svg";
import discover from "../../../../assets/discover-card.svg";

const iconMap = { visa, mastercard, amex, discover };

export default function CardInfo({ card }) {
  return (
    <Box sx={{ display: "flex", cursor: "pointer" }}>
      <Box
        sx={{
          display: "flex",
          border: "2px solid #E5E8EB",
          borderRadius: "12px",
          padding: "8px 12px",
          flex: 9,
          mr: "10px",
        }}
      >
        <img src={iconMap[card.brand.toLowerCase()]} alt="" style={{ width: "30px", height: "auto"}} />

        <Typography
          sx={{
            color: "#777E90",
            fontSize: "14px",
            fontWeight: 500,
            pl: "10px",
          }}
        >
          {card.brand} ending {card.last4}
        </Typography>
      </Box>

      <Box
        sx={{
          border: "2px solid #E5E8EB",
          borderRadius: "12px",
          padding: "8px 12px",
          flex: 3,
        }}
      >
        <Typography
          sx={{
            color: "#777E90",
            fontSize: "14px",
            fontWeight: 500,
            textAlign: "center"
          }}
        >
          {card.exp_month}/{card.exp_year}
        </Typography>
      </Box>
    </Box>
  );
}

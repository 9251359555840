export const paymentPlans = [
  {
    id: 1,
    name: "Flexi Free",
    description: "Free + pay per usage",
    expiredTime: "Free forever",
    isCurrentPlan: true,
    price: {
      monthly: 0,
      yearly: 0,
    },
    features: [
      { main: "TEXT ONLY", sub: "content suite" },
      { main: "", sub: "40 minutes content" },
      { main: "", sub: "Optional power ups" },
    ],
    maxFileSize: 250000000, // 250 MB per upload
    maxFileLength: 40, // minutes, per file
    editingFeatures: [],
    config: {
      mainColor: "#141718",
      secondaryColor: "#141718",
      highlightColor: "#141718",
      backgroundColor: "#F7F7F7",
    },
  },
  {
    id: 2,
    name: "Hobbyist",
    description: "For growing podcasts",
    expiredTime: "Per month, per team member",
    isCurrentPlan: false,
    price: {
      monthly: 29,
      yearly: 24,
    },
    features: [
      { main: "TEXT ONLY", sub: "content suite" },
      { main: "", sub: "240 minutes content " },
      { main: "", sub: "Real-time content editor" },
    ],
    maxFileSize: 1000000000, // 1 GB per upload
    maxFileLength: 90, // minutes, per file
    editingFeatures: ["findAndReplace", "summary", "show_notes", "quotes", "chapters", "transcripts", "tweet", "caption"],
    config: {
      mainColor: "#141718",
      secondaryColor: "#0659F9",
      highlightColor: "#141718",
      backgroundColor: "#0200FF12",
    },
  },
  {
    id: 3,
    name: "Professional",
    description: "For podcasting pros",
    expiredTime: "Per month, per team member",
    isCurrentPlan: false,
    price: {
      monthly: 69,
      yearly: 64,
    },
    features: [
      { main: "TEXT & MEDIA", sub: "content suite" },
      { main: "", sub: "240 minutes content " },
      { main: "", sub: "AI Face Tracking + Clip Finder" },
    ],
    maxFileSize: null, // no cap on file size
    maxFileLength: null, // no cap on file duration
    editingFeatures: ["findAndReplace", "summary", "show_notes", "quotes", "chapters", "transcripts", "tweet", "caption"],
    config: {
      mainColor: "#FEFEFE",
      secondaryColor: "#A6FFDF",
      highlightColor: "#A6FFDF",
      backgroundColor: "#232627",
    },
  },
];

export const payAsYouGo = [
  {
    id: 1,
    name: "Written Content",
    slogan: "Pay as you go",
    price: {
      onetime: 19,
    },
    expiredTime: "One time payment",
    features: [
      { main: "TEXT ONLY", sub: "content suite" },
      { main: "", sub: "120 minutes content " },
    ],
    tag: "120 mins of written content",
    minutes: 120,
  },
  {
    id: 2,
    name: "Written & Multimedia Content",
    slogan: "Pay as you go",
    price: {
      onetime: 39,
    },
    expiredTime: "One time payment",
    tag: "120 mins of written & media content",
    features: [
      { main: "TEXT & MEDIA", sub: "content suite" },
      { main: "", sub: "120 minutes content " },
    ],
    minutes: 120,
  },
];

export const addonsMap = {
  0: "",
  19: "limited",
  39: "full",
};

export const addonsMapForAnalytics = {
  limited: "Written Content",
  full: "Written & Multimedia Content",
};

export const SubscriptionHirearchy = {
  "Flexi Free": 0,
  Hobbyist: 1,
  Professional: 2,
};

export const planMap = {
  Hobbyist: {
    monthly: "hobbyist_monthly",
    yearly: "hobbyist_yearly",
  },
  Professional: {
    monthly: "professional_monthly",
    yearly: "professional_yearly",
  },
};

export const planMapReverse = {
  hobbyist_monthly: "Hobbyist",
  hobbyist_yearly: "Hobbyist",
  professional_monthly: "Professional",
  professional_yearly: "Professional",
  free: "Flexi Free",
  "": "Flexi Free",
  "Flexi Free": "Flexi Free",
  Hobbyist: "Hobbyist",
  Professional: "Professional",
};

export const availablePlans = {
  "Flexi Free": ["Flexi Free"],
  Hobbyist: ["Flexi Free", "Hobbyist"],
  Professional: ["Hobbyist", "Flexi Free", "Professional"],
};

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSubscriptionPlan } from "../../store/user";

export default function UpgradeLink({ show }) {
  const subscriptionPlan = useSelector(getSubscriptionPlan);
  const isFreePlan = subscriptionPlan === "Flexi Free";
  const link = isFreePlan ? "/plans" : "/profile?tab=2";

  if (show) {
    return (
      <Link
        to={link}
        style={{
          background: "#0659F9",
          color: "#FFF",
          padding: "1px 20px",
          lineHeight: "38px",
          fontSize: "14px",
          fontWeight: "700",
          borderRadius: "999px",
          fontFamily: "HelveticaNowDisplay",
          textDecoration: "none",
          textAlign: "center",
        }}
      >
        {isFreePlan ? "Upgrade" : "Add Credits"}
      </Link>
    );
  }

  return null;
}

import { Fragment, useCallback, useState } from "react";
import { Box, ClickAwayListener } from "@mui/material";
import Notifications from "../notification";
import notification from "../../assets/icon-notification.svg";

export default function NotificationBar({ show }) {
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const handleClickAway = useCallback(
    (e) => {
      const notificationPopover = document.querySelector(".notification-paper");
      if (notificationAnchorEl && !notificationPopover?.contains(e.target)) {
        setNotificationAnchorEl(null);
      }
    },
    [notificationAnchorEl]
  );

  if (show) {
    return (
      <>
        <Notifications
          anchorEl={notificationAnchorEl}
          close={() => setNotificationAnchorEl(null)}
        />

        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              position: "relative",
              display: "none",
              width: "40px",
              height: "40px",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={(e) => setNotificationAnchorEl(e.currentTarget)}
          >
            <Box
              sx={{
                position: "absolute",
                top: "0px",
                right: "0px",
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                background: "#45B26B",
              }}
            ></Box>
            <img src={notification} alt="notification" />
          </Box>
        </ClickAwayListener>
      </>
    );
  }

  return null;
}

import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";
import * as amplitude from "@amplitude/marketing-analytics-browser";

const pluginsArry = () => {
  const arry = [];
  if (process.env.REACT_APP_GTM_TURN_ON === "true") {
    arry.push(
      googleTagManager({
        containerId: process.env.REACT_APP_GMT_ID,
      })
    );
  }
  return arry;
};

export const GTM_Analytics = Analytics({
  app: "deciphr_app",
  plugins: pluginsArry(),
});

export const analytics = {
  /**
   *
   * @param {object} page properties of the page like url, title, etc
   */
  page: (page) => {
    GTM_Analytics.page(page);
  },
  /**
   * to track every event in amplitude and GTM
   * @param {string} event event name
   * @param {object} properties other event properties
   */
  track: (event, properties) => {
    if (process.env.REACT_APP_GTM_TURN_ON === "true") {
      GTM_Analytics.track(event, properties);
    }
    if (process.env.REACT_APP_AMPLITUDE_TURN_ON === "true") {
      amplitude.track(event, properties);
    }
  },
  /**
   * to set user properties in amplitude and GTM
   * @param {string} userId unique user id
   * @param {object} properties user properties
   */
  identify: (userId, properties) => {
    if (process.env.REACT_APP_GTM_TURN_ON === "true") {
      GTM_Analytics.identify(userId, properties);
    }
    if (process.env.REACT_APP_AMPLITUDE_TURN_ON === "true") {
      // to set user properties in amplitude
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set("user_id", userId);
      Object.keys(properties).forEach((key) => {
        identifyEvent.set(key, properties[key]);
      });
      amplitude.identify(identifyEvent);

      /**
       * to set user id in amplitude
       * this is required to link the user in amplitude
       */
      amplitude.setUserId(userId);
    }
  },
  /**
   * to initialize amplitude on page load if amplitude is turned on
   * this is required to link the user in amplitude
   * conditionally initialize Amplitude based on the environment variable
   */
  init: () => {
    if (process.env.REACT_APP_AMPLITUDE_TURN_ON === "true") {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, null, {
        attribution: {
          initialEmptyValue: "none",
          resetSessionOnNewCampaign: true,
        },
        // pageViewTracking: {
        //   trackHistoryChanges: 'pathOnly' // or 'all'
        // }
      });
    }
  },
};

import Box from "@mui/material/Box";
import SearchBar from "./searchInput";

export default function LibrarySearchBar({ show }) {
  if (show) {
    return (
      <Box sx={{ width: "391px", display: { xs: "none", md: "flex" } }}>
        <SearchBar />
      </Box>
    );
  }

  return null;
}

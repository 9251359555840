import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, LinearProgress, Typography, styled } from "@mui/material";
import deciphr from "../../../../assets/deciphr_mascot.png";

const CONTENT_STEPS = [
  "Preparing to download...",
  "Creating your DOCX file..",
  "Adding your content to DOCX file...",
];

const MEDIA_STEPS = [
  "Initializing media processor...",
  "Generating media files, this may take a while...",
  "Still working... I didn't get stuck!",
];

const FINAL_STEPS = [
  "Packaging your contents...",
  "Almost done...",
];

const STEP_WAIT_TIME = 5; // seconds, for each step

const FloatingImg = styled("img")({
  display: "block",
  animation: "floatAnimation 2.25s ease-in-out infinite",
  margin: "15px auto",
  width: "250px",
  height: "250px",
  borderRadius: "50%",

  "@keyframes floatAnimation": {
    "0%": {
      boxShadow: "0 5px 15px 0 rgba(0, 0, 0, 0.6)",
      transform: "translateY(0px)",
    },

    "50%": {
      boxShadow: "0 25px 15px 0 rgba(0, 0, 0, 0.2)",
      transform: "translateY(-20px)",
    },

    "100%": {
      boxShadow: "0 5px 15px 0 rgba(0, 0, 0, 0.6)",
      transform: "translateY(0px)",
    },
  },
});

export default function DownloadProgressBar() {
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [queryParams] = useSearchParams();

  useEffect(() => {
    const hasContent = queryParams.get("summary") === "1"
      || queryParams.get("quotes") === "1"
      || queryParams.get("chapters") === "1"
      || queryParams.get("transcript") === "1";
    const hasMedia = queryParams.get("audiogram") === "1" || queryParams.get("reels") === "1";
    let downloadSteps = [];

    if (hasContent) {
      downloadSteps = downloadSteps.concat(CONTENT_STEPS);
    }

    if (hasMedia) {
      downloadSteps = downloadSteps.concat(MEDIA_STEPS);
    }

    downloadSteps = downloadSteps.concat(FINAL_STEPS);

    setSteps(downloadSteps);
  }, [queryParams]);

  useEffect(() => {
    let interval;

    if (steps.length) {
      interval = setInterval(() => {
        if (progress === 100) {
          clearInterval(interval);

          return;
        }

        if (currentStep < (steps.length - 1)) {
          setCurrentStep((val) => val + 1);
        }

        const prog = (currentStep + 1) * (100 / steps.length);

        setProgress(Math.min(prog, 100));
      }, STEP_WAIT_TIME * 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [steps, progress, currentStep]);

  return (
    <Box sx={{ width: "400px" }}>
      <FloatingImg src={deciphr} alt="" width="250" />

      <Typography sx={{ fontSize: "16px", fontWeight: 600, textAlign: "center", m: "30px auto" }}>
        We're getting your files ready! Please don't reload or close this page.
      </Typography>

      <LinearProgress variant="determinate" color="primary" value={progress} />

      <Typography sx={{ fontSize: "14px", fontWeight: 400, textAlign: "center", m: "10px auto" }}>
        {steps[currentStep]}
      </Typography>
    </Box>
  );
}

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BilledAmount from "DAI/Pricing/components/BilledAmount";
import {
  addonsSelector,
  getDiscount,
  globalMonthlyCheckSelector,
  isPayAsYouGoSelector,
  selectedPlanSelector,
} from "store/payment";

export default function CheckoutTotal() {
  const [amount, setAmount] = useState(0);
  const [planDuration, setPlanDuration] = useState("");
  const plan = useSelector(selectedPlanSelector);
  const addons = useSelector(addonsSelector);
  const isPayAsYouGo = useSelector(isPayAsYouGoSelector);
  const isMonthlySubscription = useSelector(globalMonthlyCheckSelector);
  const { discountPercent, discountPeriod } = useSelector(getDiscount);
  // discountPercent is an integer, e.g. 25, 50, 75 and discountPeriod is an integer in months

  // Monthly and yearly plans
  useEffect(() => {
    if (isPayAsYouGo === false) {
      const duration = (isMonthlySubscription) ? "monthly" : "yearly";
      const planAmountInMonths = plan?.price?.[duration];

      setPlanDuration(duration);

      if (isMonthlySubscription === false) {
        // Yearly subscription plan
        const discountTotal = (planAmountInMonths * discountPercent) * discountPeriod;
        const nonDiscountTotal = (planAmountInMonths) * (12 - discountPeriod);

        setAmount(discountTotal + nonDiscountTotal + addons);
      } else {
        // Monthly subscription plan
        let total = planAmountInMonths;

        if (discountPercent > 0) {
          total = total * discountPercent;
        }

        if (addons > 0) {
          total += addons;
        }

        setAmount(total);
      }
    }
  }, [isPayAsYouGo, addons, discountPercent, discountPeriod, plan, isMonthlySubscription]);

  // Pay as you Go add-ons
  useEffect(() => {
    if (isPayAsYouGo) {
      const paygAmount = plan?.price?.onetime;
      let total = paygAmount;

      if (discountPercent > 0) {
        total = paygAmount * discountPercent;
      }

      setAmount(total);
    }
  }, [isPayAsYouGo, plan, discountPercent]);

  return (
    <BilledAmount
      totalAmount={amount}
      periodicCharge={plan?.price?.[planDuration]}
      duration={isPayAsYouGo ? "oneOff" : (isMonthlySubscription ? "monthly" : "yearly")}
    />
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { paymentPlans, payAsYouGo } from "../utils/paymentPlans";
import { payment } from "../utils/apis";
import { setToast } from "./toastSlice";
import { getUserInfo } from "./user";
import { Revenue, revenue } from "@amplitude/marketing-analytics-browser";
import { analytics } from "../utils/GTM";
import {
  addonsMapForAnalytics,
  SubscriptionHirearchy,
  planMapReverse,
} from "../utils/paymentPlans";

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    plans: paymentPlans,
    payAsYouGo: payAsYouGo,
    selectedPlan: {},
    planDuration: "",
    showingCheckoutModel: false,
    showingSuccessModel: false,
    invoice: {},
    loading: false,
    globalMonthlyCheck: true,
    isPayAsYouGo: false,
    openCancelSubscriptionModel: false,
    openChangeplanModel: false,
    addons: 0,
    discountPercent: 0,
    discountPeriod: 0, // In months
    mediaFileModal: false,
    errorMsgType: "",
    cardError: null,
  },

  reducers: {
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },

    setPlanDuration: (state, action) => {
      state.planDuration = action.payload;
    },

    setShowCheckoutModel: (state, action) => {
      state.showingCheckoutModel = action.payload.show;
      state.selectedPlan = action.payload.plan;
      state.isPayAsYouGo = action.payload.isPayAsYouGo;

      if (action.payload.show === false) {
        state.cardError = null;
        state.planDuration = "";
        state.discountPercent = 0;
        state.discountPeriod = 0;
      }
    },

    setShowSuccessModel: (state, action) => {
      state.showingSuccessModel = action.payload.show;
      state.invoice = action.payload.invoice;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setGloablMonthlyCheck: (state, action) => {
      state.globalMonthlyCheck = action.payload;
    },

    setOpenCancelSubscriptionModel: (state, action) => {
      state.openCancelSubscriptionModel = action.payload;
    },

    setOpenChangeplanModel: (state, action) => {
      state.openChangeplanModel = action.payload;
    },

    setAddons: (state, action) => {
      state.addons = action.payload;
    },

    setDiscount: (state, action) => {
      state.discountPercent = action.payload.discountPercent / 100;
      state.discountPeriod = action.payload.discountPeriod || 1;
    },

    setMediaFileModal: (state, action) => {
      state.mediaFileModal = action.payload.open;
      state.errorMsgType = action.payload.type;
    },

    setCardError: (state, action) => {
      state.cardError = action.payload;
    },
  },
});

export const {
  setSelectedPlan,
  setPlanDuration,
  setShowCheckoutModel,
  setShowSuccessModel,
  setLoading,
  setGloablMonthlyCheck,
  setOpenCancelSubscriptionModel,
  setOpenChangeplanModel,
  setAddons,
  setDiscount,
  setMediaFileModal,
  setCardError,
} = paymentSlice.actions;

export const planSelector = (state) => state.payment.plans;
export const selectPlanDuration = (state) => state.payment.planDuration;
export const payAsYouGoSelector = (state) => state.payment.payAsYouGo;
export const selectedPlanSelector = (state) => state.payment.selectedPlan;
export const showingCheckoutModelSelector = (state) =>
  state.payment.showingCheckoutModel;
export const showingSuccessModelSelector = (state) =>
  state.payment.showingSuccessModel;
export const invoiceSelector = (state) => state.payment.invoice;
export const loadingSelector = (state) => state.payment.loading;
export const globalMonthlyCheckSelector = (state) =>
  state.payment.globalMonthlyCheck;
export const isPayAsYouGoSelector = (state) => state.payment.isPayAsYouGo;
export const openCancelSubscriptionModelSelector = (state) =>
  state.payment.openCancelSubscriptionModel;
export const openChangeplanModelSelector = (state) =>
  state.payment.openChangeplanModel;
export const addonsSelector = (state) => state.payment.addons;
export const getDiscount = (state) => {
  return {
    discountPercent: state.payment.discountPercent,
    discountPeriod: state.payment.discountPeriod,
  };
};
export const mediaFileModalSelector = (state) => state.payment.mediaFileModal;
export const errorMsgTypeSelector = (state) => state.payment.errorMsgType;
export const getCardError = (state) => state.payment.cardError;

const revenueEventTracker = (
  plan,
  data,
  isPayAsYouGo,
  add_on,
  addonPrice,
  oldPlan
) => {
  const revenueEvent = new Revenue()
    .setProductId(isPayAsYouGo ? `PAYG ${addonsMapForAnalytics[add_on]}` : plan)
    .setPrice(data.map((item) => item.amount_paid).reduce((a, b) => a + b, 0))
    .setQuantity(1)
    .setRevenueType("income")
    .setEventProperties({
      addons: add_on,
      addonPrice: addonPrice,
      old_plan: oldPlan,
    });
  revenue(revenueEvent);
};

const trackSuccessfulPayment = (
  plan,
  isPayAsYouGo,
  add_on,
  addonPrice,
  oldPlan,
  oldPlanWithoutPeriod
) => {
  if (isPayAsYouGo) {
    analytics.track(
      `PAYG ${addonsMapForAnalytics[add_on]} Successfully Purchased`,
      {
        current_plan: oldPlan,
      }
    );
  } else {
    // track if plan is upgraded or downgraded
    if (
      SubscriptionHirearchy[oldPlanWithoutPeriod] >
      SubscriptionHirearchy[planMapReverse[plan]]
    ) {
      analytics.track("Plan Downgraded Successfully", {
        addons: add_on,
        addonPrice: addonPrice,
        old_plan: oldPlan,
      });
    } else {
      analytics.track("Plan Upgraded Successfully", {
        addons: add_on,
        addonPrice: addonPrice,
        old_plan: oldPlan,
      });
    }
  }
};

export const pay = createAsyncThunk(
  "payment/payment",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { token_id, plan, add_on, cancel, isPayAsYouGo, addonPrice } =
      payload;
    const oldPlan = getState().user.planType;
    const oldPlanWithoutPeriod = getState().user.plan;

    dispatch(setLoading(true));
    try {
      const { data } = await payment({
        token_id,
        plan,
        add_on,
        cancel,
      });

      setTimeout(() => {
        // We need to wait a bit for Stripe to trigger a successful
        // webhook so the backend can update the user info.
        dispatch(getUserInfo());
      }, 5000);

      dispatch(setShowCheckoutModel({ show: false, plan: {} }));
      dispatch(setOpenChangeplanModel(false));

      if (!payload.cancel) {
        dispatch(
          setShowSuccessModel({
            show: true,
            invoice: {
              invoice_date: data[0].invoice_date,
              invoice_id: data[0].invoice_id,
              invoice_pdf: data[0].invoice_pdf,
              invoice_amount: data
                .map((item) => item.amount_paid)
                .reduce((a, b) => a + b, 0),
            },
          })
        );

        setToast({
          message: "Plan Upgraded",
          severity: "success",
          autoClose: true,
        });

        //to track revenue event in amplitude
        revenueEventTracker(
          plan,
          data,
          isPayAsYouGo,
          add_on,
          addonPrice,
          oldPlan
        );

        trackSuccessfulPayment(
          plan,
          isPayAsYouGo,
          add_on,
          addonPrice,
          oldPlan,
          oldPlanWithoutPeriod
        );
        
      } else {
        analytics.track("Canceled Subscription Successfully", {
          plan: oldPlan,
        });

        setToast({
          message: "Plan Cancelled",
          severity: "success",
          autoClose: true,
        });

        dispatch(setOpenCancelSubscriptionModel(false));
      }
    } catch (e) {
      let message = e.message;

      if (e.response && e.response.data) {
        if (e.response.data.error) {
          message = e.response.data.error;

          dispatch(setCardError(message));
        }
      }

      dispatch(setToast({ message, severity: "error" }));

      // dispatch(setShowCheckoutModel({ show: false, plan: {} }));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export default paymentSlice.reducer;

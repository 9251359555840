import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { IconCheckCircle } from "../../components/icons";
import {
  showingSuccessModelSelector,
  setShowSuccessModel,
  invoiceSelector
} from "../../store/payment";

// TODO: Check parse format from backend
dayjs.extend(customParseFormat);
dayjs.extend(utc);

function ModalSuccess() {
  const dispatch = useDispatch();
  const open = useSelector(showingSuccessModelSelector);
  const invoice = useSelector(invoiceSelector);
  const [listPayment, setListPayment] = useState([]);

  useEffect(() => {
    if (invoice) {
      setListPayment([
        {
          name: "Invoice",
          content: `#${invoice.invoice_id || ""}`,
        },
        {
          name: "Date",
          content: invoice.invoice_date
            ? dayjs(
              dayjs.utc(invoice.invoice_date, "MMM DD, YYYY, hh:mm A").toDate()
            ).format("MMM D, YYYY, hh:mm A")
            : "",
        },
        {
          name: "Amount paid",
          content: `$${invoice.invoice_amount || ""}`,
        },
      ]);
    }
  }, [invoice]);


  const handleClose = () => {
    dispatch(setShowSuccessModel({
      show: false,
      invoice: {}
    }));
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "24px",

          "@media screen and (max-width: 767px)": {
            borderRadius: "8px",
          },
        },
      }}
    >
      <Box
        sx={{
          padding: "75px 50px 50px 50px",

          "@media screen and (max-width: 767px)": {
            padding: "50px 25px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconCheckCircle
            width={84}
            height={84}
            fill="#72C472"
            fillOpacity="1"
          />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            paddingTop: "47px",
            fontWeight: 700,
            fontSize: "32px",
            color: "#23262F",
            pb: "7px",
          }}
          component="h2"
        >
          Successful subscription
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "HelveticaNowDisplay",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#6C7275",
            pb: "47px",
          }}
        >
          Your subscription has been updated successfully!
        </Typography>
        <Box
          sx={{
            border: "2px solid #E8ECEF",
            borderRadius: "12px",
            "& div:last-child": {
              borderBottom: "unset",
            },
          }}
        >
          {listPayment.map((item, idx) => (
            <Box
              key={idx}
              sx={{
                height: "64px",
                padding: "0px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #E8ECEF",
                overflow: "hidden",

                "@media screen and (max-width: 767px)": {
                  height: "48px",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "HelveticaNowDisplay",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#23262F",
                  pr: "20px",

                  "@media screen and (max-width: 767px)": {
                    fontSize: "14px",
                    lineHeight: "16px",
                  },
                }}
              >
                {item.name}
              </Typography>

              <Typography
                sx={{
                  fontFamily: "HelveticaNowDisplay",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#23262F",
                  overflow: "hidden",
                  textOverflow: "ellipsis",

                  "@media screen and (max-width: 767px)": {
                    fontSize: "14px",
                    lineHeight: "16px",
                  },
                }}
              >
                {item.content}
              </Typography>
            </Box>
          ))}
        </Box>

        <Button
          onClick={handleClose}
          sx={{
            mt: "53px",
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            borderRadius: "100px",
            color: "#fff",
            background: "#0659F9",
            fontWeight: 600,
            lineHeight: "24px",
            fontSize: "16px",
            fontFamily: "HelveticaNowDisplay",
          }}
        >
          Done
        </Button>
      </Box>
    </Dialog>
  );
}

export default ModalSuccess;

import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { reset } from "../../store/user";
import { GlobalData } from "../../context";

const menuItemStyle = {
  padding: "16px",
};

const fontStyle = {
  fontFamily: "HelveticaNowDisplay",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  color: "#23262F",
  textDecoration: "none",
};

export default function UserMenuDropdown({ show, anchorEl, onClose }) {
  const { setLogin } = useContext(GlobalData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuClose = () => {
    onClose(null);
  };

  const logout = () => {
    localStorage.removeItem("userInfo");
    setLogin(false);
    navigate("/auth/login");
    dispatch(reset());
  };

  const handleNav = (link) => {
    handleMenuClose();
    navigate(link);
  };

  if (show) {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="primary-search-account-menu"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiMenu-root": { padding: "12px" },
          "& .MuiMenu-paper": {
            borderRadius: 3,
            mt: 5,
            maxWidth: "155px",
            width: "100%",
            overflow: "visible",
          },
        }}
        open={anchorEl !== null}
        onClose={handleMenuClose}
      >
        <Box
          sx={{
            minWidth: 155,
            height: 0,
            right: 90,
            borderRadius: "12px",
          }}
        />
        <MenuItem sx={menuItemStyle} onClick={() => handleNav("/profile")}>
          <Typography style={fontStyle}>Profile Settings</Typography>
        </MenuItem>

        <MenuItem sx={menuItemStyle} onClick={() => handleNav("/plans")}>
          <Typography style={fontStyle}>Upgrade</Typography>
        </MenuItem>

        <MenuItem onClick={logout} sx={menuItemStyle}>
          <Typography style={fontStyle}>Log Out</Typography>
        </MenuItem>
      </Menu>
    );
  }

  return null;
}

import { useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Box, Typography, Divider, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { GlobalData } from "../../context";
import { init, schema, ForgotFieldMap } from "../../utils/constants";
import { forgotInitApi } from "../../utils/authSeq";
import AuthInput from "./components/authInput";
import EmailIcon from "../../assets/email.svg";

const IconMap = {
  email: EmailIcon,
};

const ForgotPassword = () => {
  const [loader, setLoader] = useState(false);
  const { openAlert, setMessage, setSeverity } = useContext(GlobalData);
  const formik = useFormik({
    initialValues: init("forgotInit"),
    enableReinitialize: true,
    validationSchema: Yup.object().shape(schema("forgotInit")),
    onSubmit: (value) => SubmitHandler(value),
  });


  const SubmitHandler = async (value) => {
    setLoader(true);
    await forgotInitApi(
      value,
      () => {
        sucessHandler("Password reset request sent. Please check your email!");
      },
      errorHandler
    );
    setLoader(false);
  };

  const sucessHandler = (res) => {
    setSeverity("success");
    openAlert();
    setMessage(res);
  };

  const errorHandler = (err) => {
    setSeverity("error");
    openAlert();
    setMessage(err);
  };

  return (
    <Box sx={styles.root}>
      <Box>
        <Typography sx={styles.heading}>
          Forgot your password?
        </Typography>

        <Typography sx={styles.subtext}>
          Enter your email address to reset your password.
        </Typography>
      </Box>

      <Divider sx={{ my: "30px" }}/>

      <Box>
        <Box sx={{ ...styles.formControl, mr: "12px" }}>
          <Typography sx={styles.formLabel}>Email</Typography>
          <TextField
            variant="outlined"
            sx={styles.formInput}
            id="email"
            name="email"
            type="email"
            placeholder="Enter email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {Boolean(formik.errors.email && formik.touched.email) && (
            <Typography sx={styles.formError}>
              Email is {formik.errors.email}
            </Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <LoadingButton
          sx={styles.continueButton}
          disableElevation
          onClick={formik.handleSubmit}
          loading={loader}
        >
          Continue
        </LoadingButton>
      </Box>
    </Box>
  );
};

const styles = {
  root: {
    width: { xs: "100%", md: "45%" },
    background: "white",
    backgroundColor: "transparent",
    py: { xs: "100px", md: "calc(102px + 2vh)" },
    minHeight: { xs: "100vh", lg: "unset" },
    paddingBottom: "50px",
    mx: "auto",
    display: "flex",
    flexDirection: "column",
  },

  heading: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#10181E",
  },

  subtext: {
    fontSize: { xs: "14px", lg: "16px" },
    fontWeight: "400",
    lineHeight: "24px",
    mt: "24px",
    color: "#667085",
    display: "flex",
  },

  formLabel: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20.3px",
    letterSpacing: "0.2px",
    color: "#10181E",
    mb: "5px",
  },

  formInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: "12px",
        '& fieldset': {
          borderColor: "#DCE0E5",
        },
        '&.Mui-focused fieldset': {
          borderColor: "#DCE0E5",
        },
    },
    width: "100%",
  },

  formControl: {
    my: "18px",
    flex: 1,
  },

  formError: {
    color: "red",
    mt: "4px",
  },

  continueButton: {
    background: "#0659F9",
    borderRadius: "44px",
    color: "#fff",
    height: "56px",
    width: "100%",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    mt: "15px",
  },
};

export default ForgotPassword;

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

let customTheme = createTheme({
  typography: {
    fontFamily: "HelveticaNowDisplay",
    button: {
      textTransform: "capitalize",
    },
  },
});

customTheme = responsiveFontSizes(customTheme);

const MaterialLayout = (props) => {
  const { children } = props;
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MaterialLayout;

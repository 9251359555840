import { useState } from "react";
import countryList from "country-list";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

export default function CountrySelect({ onChange }) {
  const [countries] = useState(
    // Sort alphabetically by name instead of country code
    countryList
      .getData()
      .sort((a, b) => a.name > b.name)
  );

  return (
    <Box sx={{ py: "20px", px: "4px" }}>
      <Typography
        component="label"
        htmlFor="countries"
        id="countryLabel"
        sx={{
          display: "block",
          fontFamily: "HelveticaNowDisplay",
          fontSize: "14px",
          fontWeight: 500,
          color: "#343839",
          lineHeight: "24px",
          pb: "10px",
          pl: "8px",
        }}
      >
        Country
      </Typography>

      <Select
        id="countries"
        labelId="countryLabel"
        required
        defaultValue=""
        onChange={(e) => onChange(e.target.value)}
        sx={{
          display: "block",
          borderRadius: "12px",
          "& .MuiInputBase-root": {
            borderRadius: "12px",
            display: "block",
          },

          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
            borderColor: "#e8ecee",
          },

          "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#0659F9",
            },
          },

          "& .MuiInputBase-input": {
            fontFamily: "HelveticaNowDisplay",
            fontWeight: 500,
            fontSize: "14px",
            color: "#141718",
          },
        }}
      >
        {countries.map((country) => (
          <MenuItem value={country.code} key={country.code}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

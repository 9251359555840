import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getFilesList, DelInsights, getUnprocessedFileIds } from "../../utils/apis";
import { setAttributes } from "../transcriptSlice";
import { setToast } from "../toastSlice";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    files: [],
    filter: 'all',
    sort: 'date_desc',
    limit: 20,
    searchTerm: "",
    searchResults: [],
    isLoading: false,
    areAllFilesFetched: false,
  },

  reducers: {
    appendToFilesList: (state, action) => {
      if (action.payload) {
        state.files = state.files.concat(action.payload);
      }
    },

    removeFileFromList: (state, action) => {
      state.files = state.files.filter((file) => file.id !== action.payload);
    },

    clearFilesList: (state) => {
      state.files = [];
    },

    updateFileStatusToProcessed: (state, action) => {
      const index = state.files.findIndex((file) => file.id === action.payload);

      if (index > -1) {
        state.files[index].processed = "Y";
      }
    },

    updateFilter: (state, action) => {
      if (state.filter !== action.payload) {
        state.filter = action.payload;
        state.files = [];
        state.areAllFilesFetched = false;
      }
    },

    updateSort: (state, action) => {
      if (state.sort !== action.payload) {
        state.sort = action.payload;
        state.files = [];
        state.areAllFilesFetched = false;
      }
    },

    searchFiles: (state, action) => {
      const searchTerm = action.payload.toLowerCase();

      state.searchTerm = action.payload;

      if (searchTerm) {
        state.searchResults = state.files.filter((file) => {
          return file.title.toLowerCase().includes(searchTerm)
            || file.headline.toLowerCase().includes(searchTerm);
        });
      } else {
        state.searchResults = [];
      }
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setAllFilesFetched: (state, action) => {
      state.areAllFilesFetched = action.payload;
    },
  },
});

export const {
  appendToFilesList,
  removeFileFromList,
  clearFilesList,
  updateFileStatusToProcessed,
  updateFilter,
  updateSort,
  searchFiles,
  setIsLoading,
  setAllFilesFetched,
} = dashboardSlice.actions;

/**************
 * Selectors
**************/
export const selectFiles = (state) => state?.dashboard?.files || [];
export const selectFilters = (state) => ({ filter: state.dashboard.filter, sort: state.dashboard.sort });
export const selectParams = (state) => {
  return new URLSearchParams({
    sort: state.dashboard.sort,
    filter: state.dashboard.filter,
    start: state.dashboard.files.length,
    limit: state.dashboard.limit,
  });
};

export const selectSearchResults = (state) => state.dashboard.searchResults;
export const getSearchTerm = (state) => state.dashboard.searchTerm;
export const isLoading = (state) => state.dashboard.isLoading;
export const areAllFilesFetched = (state) => state.dashboard.areAllFilesFetched;

/****************
 * Async Thunks
****************/
export const getFiles = createAsyncThunk("dashboard/getFiles", async (payload, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;
  const { dashboard } = getState();
  const { files, limit, areAllFilesFetched, isLoading } = dashboard;
  const params = selectParams(getState());

  if (payload?.fromStart) {
    // Fetch from the beginning, but update the limit to retain existing files
    params.set("start", 0);
    params.set("limit", files.length || limit);

    dispatch(clearFilesList());
  }

  if (areAllFilesFetched) {
    return;
  }

  if (isLoading) {
    return;
  }

    dispatch(setIsLoading(true));

  try {
    const data = await getFilesList(params);

    dispatch(appendToFilesList(data));

    if (data.length < limit) {
      // No more files to fetch
      dispatch(setAllFilesFetched(true));
    }
  } catch (e) {
    dispatch(setToast({
      message: "Files could not be retrieved. Please try again.",
      severity: "error",
    }));
  } finally {
    dispatch(setIsLoading(false));
  }
});

export const setFileAttributes = createAsyncThunk("dashboard/setFile", async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;

  dispatch(setAttributes({
    name: "",
    date: payload.display_datetime,
    title: payload.headline,
    headline: payload.headline,
    fileName: payload.title,
    fileType: payload.file_type,
  }));
});

export const deleteFile = createAsyncThunk("dashboard/deleteFile", async (id, thunkAPI) => {
  const { dispatch } = thunkAPI;

  dispatch(removeFileFromList(id));

  try {
    await DelInsights(id);

    dispatch(setToast({
      message: "File was deleted successfully.",
      severity: "success",
      autoClose: true,
    }))
  } catch (e) {
    //
  }
});

export const statusCheck = createAsyncThunk("dashboard/status", async (_, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const { dashboard } = getState();
  const { files } = dashboard;
  const unprocessedFiles = files.filter((file) => file.processed === "N");

  try {
    const fileIds = await getUnprocessedFileIds();

    unprocessedFiles.forEach((file) => {
      if (fileIds.indexOf(file.id) === -1) {
        // File ID is not in unprocessed file IDs list, update it to processed
        dispatch(updateFileStatusToProcessed(file.id));
      }
    });
  } catch (e) {
    //
  }
});

export default dashboardSlice.reducer;

import { Provider } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { history } from "utils/history";
import { Provider as RollBarProvider, ErrorBoundary } from '@rollbar/react';
import { Analytics } from "@vercel/analytics/react"
import DeciphrRoutes from "routes";
import store from "store";
import { rollbarConfig } from "Core/helpers/rollbar";

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <Provider store={store}>
      <RollBarProvider config={rollbarConfig}>
        <ErrorBoundary>
          <DeciphrRoutes />
          <Analytics />
        </ErrorBoundary>
      </RollBarProvider>
    </Provider>
  );
}

export default App;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { updateUserInfo, userInfo } from "../utils/apis";
import { setToast } from "./toastSlice";
import { planMapReverse } from "../utils/paymentPlans";

dayjs.extend(customParseFormat);

const userSlice = createSlice({
  name: "user",
  initialState: {
    isFetchingUserInfo: false,
    firstName: "",
    lastName: "",
    email: "",
    podcastName: "",
    companyName: "",
    bio: "",
    plan: "",
    planType: "",
    transcriptUsed: 0,
    transcriptTotal: 0,
    audioUsed: 0,
    audioTotal: 0,
    mediaContent: false,
    planStartDate: "",
    nextBillingDate: "",
    amount: 0,
    invoices: [],
    cards: [],
    isYearly: false,
    audioWithMediaUsed: 0,
    audioWithMediaTotal: 0,
  },

  reducers: {
    toggleIsFetchingUserInfo: (state, action) => {
      state.isFetchingUserInfo = !state.isFetchingUserInfo;
    },

    setUserInfo: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.podcastName = action.payload.podcastName;
      state.companyName = action.payload.companyName;
      state.bio = action.payload.bio;
      state.plan = planMapReverse[action.payload.plan];
      state.transcriptUsed = action.payload.transcriptUsed;
      state.transcriptTotal = action.payload.transcriptTotal;
      state.audioUsed = action.payload.audioUsed;
      state.audioTotal = action.payload.audioTotal;
      state.mediaContent = action.payload.mediaContent;
      state.planStartDate = action.payload.planStartDate;
      state.nextBillingDate = action.payload.nextBillingDate;
      state.amount = action.payload.amount;
      state.invoices = action.payload.invoices;
      state.cards = action.payload.cards;
      state.isYearly = action.payload.plan.includes("yearly");
      state.audioWithMediaUsed = action.payload.audioWithMediaUsed;
      state.audioWithMediaTotal = action.payload.audioWithMediaTotal;
      state.planType = action.payload.plan;
    },

    setUserInfo2: (state, action) => {
      state.userInfo = action.payload;
    },

    reset: () => {
      // This resets the redux store when a user signs out. This is handled
      // in store/index.js as a catch-all reset mechanism.
    },
  },
});

export const { reset } = userSlice.actions;

export const { setUserInfo, toggleIsFetchingUserInfo, setUserInfo2 } =
  userSlice.actions;

export const userInfoSelector = (state) => state?.user;
export const isUserLoadingSelector = (state) => state.user.isFetchingUserInfo;

export const getDefaultCard = (state) => {
  return state.user.cards.find((card) => {
    const expiry = dayjs(`${card.exp_month}/${card.exp_year}`, "M/YYYY").endOf("month");

    return card.default === true && (expiry.toDate() >= new Date());
  });
};
export const getSubscriptionPlan = (state) => state?.user?.plan;

export const getUserInfo = createAsyncThunk(
  "user/fetchUserInfo",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(toggleIsFetchingUserInfo());
    try {
      const response = await userInfo();
      const {
        user: {
          first_name: firstName,
          email,
          last_name: lastName,
          podcast_name: podcastName,
          company_name: companyName,
          bio,
          plan,
          transcript_used: transcriptUsed,
          transcript_total: transcriptTotal,
          audio_used: audioUsed,
          audio_total: audioTotal,
          media_content: mediaContent,
          audio_with_media_used: audioWithMediaUsed,
          audio_with_media_total: audioWithMediaTotal,
        },
        plan: {
          plan: planName,
          start_date: planStartDate,
          next_billing_date: nextBillingDate,
          amount,
        },
        invoices,
        cards,
      } = response;
      dispatch(
        setUserInfo({
          firstName,
          lastName,
          email,
          podcastName,
          companyName,
          bio,
          transcriptTotal,
          transcriptUsed,
          audioUsed,
          audioTotal,
          plan,
          mediaContent,
          planStartDate,
          nextBillingDate,
          amount,
          invoices,
          cards,
          audioWithMediaUsed,
          audioWithMediaTotal,
        })
      );

      if (response.error) {
        dispatch(setToast({ message: response.error, severity: "error" }));
      }
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
    dispatch(toggleIsFetchingUserInfo(false));
  }
);

export const updateUserDetails = createAsyncThunk(
  "user/updateUserInfo",
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;

    dispatch(toggleIsFetchingUserInfo());

    try {
      const {
        firstName: first_name,
        lastName: last_name,
        podcastName: podcast_name,
        companyName: company_name,
        bio,
      } = payload;

      const response = await updateUserInfo({
        first_name,
        last_name,
        podcast_name,
        company_name,
        bio,
      });

      if (response.success) {
        dispatch(
          setUserInfo({
            ...getState().user,
            ...payload,
          })
        );

        dispatch(
          setToast({
            message: response.success,
            severity: "success",
            autoClose: true,
          })
        );
      } else {
        dispatch(setToast({ message: response.error, severity: "error" }));
      }
    } catch (e) {
      dispatch(setToast({ message: e.message, severity: "error" }));
    }
    dispatch(toggleIsFetchingUserInfo(false));
  }
);

export default userSlice.reducer;

import { createContext, useEffect, useState, useCallback } from "react";
import Slide from "@mui/material/Slide";
import { useLocation } from "react-router-dom";

export const GlobalData = createContext();

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const GlobalDataProvider = (props) => {
  const [login, setLogin] = useState(
    localStorage.getItem("userInfo") ? true : false
  );
  const [role, setRole] = useState("user");
  const { pathname } = useLocation();
  const [transUpload, setTransUpload] = useState({});
  const [email, setEmail] = useState("");

  //------ for alerts
  const [alertState, setAlertState] = useState({
    open: false,
    Transition: SlideTransition,
  });
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState();
  const [autoClose, setAutoClose] = useState(true);
  const [isAction, setIsAction] = useState(false);
  const [actionListner, setActionListner] = useState(null);
  //---------

  const openAlert = () => {
    setAlertState({
      ...alertState,
      open: true,
    });
  };

  const closeAlert = () => {
    setAlertState({
      ...alertState,
      open: false,
    });
  };

  const alertAction = (res) => {
    setActionListner(res);
  };

  useEffect(() => {
    if (!login) return;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))?.token;
    if (!userInfo) return;
    const token = userInfo.split(".")[1];
    setRole(JSON.parse(atob(token))?.org_role);
  }, [login]);

  return (
    <GlobalData.Provider
      value={{
        login,
        setLogin,
        alertState,
        setAlertState,
        message,
        setMessage,
        openAlert,
        severity,
        setSeverity,
        transUpload,
        setTransUpload,
        email,
        setEmail,
        alertAction,
        autoClose,
        setAutoClose,
        isAction,
        setIsAction,
        actionListner,
        setActionListner,
        closeAlert,
        role
      }}
    >
      {props.children}
    </GlobalData.Provider>
  );
};

export default GlobalDataProvider;

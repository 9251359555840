import { combineReducers, configureStore } from "@reduxjs/toolkit";
import editorReducer from "../store/editor";
import audioPlayerReducer from "../store/audioPlayerSlice";
import toastReducer from "../store/toastSlice";
import transcriptReducer from "../store/transcriptSlice";
import audiogramReducer from "../store/audiogramSlice";
import userReducer from "../store/user";
import downloadReducer from "../store/downloadSlice";
import dashboardSlice from "./dai/dashboardSlice";
import daiAudioPlayerSlice from "./daiAudioPlayerSlice";
import articleSlice from "./articleSlice";
import paymentSlice from "./payment";

const resetDataMiddleware = ({ getState }) => (next) => {
  // Caching initial app state
  const initialAppState = getState();

  return (action) => {
    if (action.type === "user/reset") {
      // We will reset redux state to initial app state
      const actionWithInitialAppState = {
        ...action,
        payload: initialAppState,
      };

      return next(actionWithInitialAppState);
    }

    return next(action);
  };
};

const combinedReducer = combineReducers({
  editor: editorReducer,
  audio: audioPlayerReducer,
  toast: toastReducer,
  transcript: transcriptReducer,
  audiogram: audiogramReducer,
  user: userReducer,
  download: downloadReducer,
  dashboard: dashboardSlice,
  diaAudioPlayer: daiAudioPlayerSlice,
  article: articleSlice,
  payment: paymentSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "user/reset") {
    return combinedReducer(action.payload, action);
  }

  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(resetDataMiddleware);
  },
});

import React from 'react';
import { Box } from '@mui/material';

// Note: Replace these with actual paths to your SVG files
import iconSvgPath from '../../../assets/inflexionlogo.svg';

const InflexionLogo = () => {
  return (
    <Box display="flex" alignItems="end" sx={{ height: '20px', paddingLeft: '9px', alignItems: 'center' }}>
      <Box component="img" src={iconSvgPath} alt="Inflexion icon" sx={{ height: '100%', pr: '7px' }} />
      <span style={{
        fontFamily: 'Helvetica Neue',
        fontSize: '23.05px',
        fontWeight: '700',
        lineHeight: '28.14px',
        letterSpacing: '-1px',
        color: '#000',
        textAlign: 'left',
      }}>Inflexion&nbsp;</span>
      <span style={{
        fontFamily: 'Helvetica Neue',
        fontSize: '23.05px',
        fontWeight: '700',
        lineHeight: '28.14px',
        letterSpacing: '-1px',
        textAlign: 'left',
        color: '#AFB3BB'
      }}>Transcribe</span>
    </Box >
  );
};

export default InflexionLogo;

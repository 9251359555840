import { Box, Typography } from "@mui/material";
import { IconCheck } from "../icons";

const PayTab = ({ isActive, isSale, tag, price, onClick, isOneTimeCharge = false }) => {
  return (
    <Box
      sx={{
        padding: "16px",
        borderRadius: "12px",
        border: `2px solid ${isActive ? "#0659F9" : "#fff"}`,
        width: isOneTimeCharge ? "393px" : "195px",
        background: `${isActive ? "#fff" : "#F3F5F7"}`,
        cursor: "pointer",

        "@media screen and (max-width: 767px)": {
          padding: "8px",
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          pb: "4px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            color: "#343839",
            lineHeight: "24px",
          }}
        >
          {tag}
        </Typography>

        {isActive && <IconCheck />}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            color: "#141718",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            fontFamily: "HelveticaNowDisplay",

            "@media screen and (max-width: 767px)": {
              fontSize: "14px",
            },
          }}
        >
          ${price}{!isOneTimeCharge && (<>/mo</>)}
        </Typography>

        {isSale && (
          <Typography
            sx={{
              color: "#0C923C",
              fontWeight: 400,
              fontSize: "14px",
              height: "24px",
              padding: "0px 8px",
              background: "rgba(63, 221, 120, 0.15)",
              display: "flex",
              alignItems: "center",

              "@media screen and (max-width: 767px)": {
                fontSize: "12px",
                padding: "0 4px",
              },
            }}
          >
            Save 20%
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PayTab;

export default function CheckCircle({ color = "#6C727580" }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8333 1C6.75812 1 1.83325 5.92487 1.83325 12C1.83325 18.0751 6.75812 23 12.8333 23C18.9084 23 23.8333 18.0751 23.8333 12C23.8333 5.92487 18.9084 1 12.8333 1ZM18.0404 9.70711C18.4309 9.31658 18.4309 8.68342 18.0404 8.29289C17.6498 7.90237 17.0167 7.90237 16.6261 8.29289L11.3333 13.5858L9.04036 11.2929C8.64983 10.9024 8.01667 10.9024 7.62615 11.2929C7.23562 11.6834 7.23562 12.3166 7.62615 12.7071L10.6261 15.7071C11.0167 16.0976 11.6498 16.0976 12.0404 15.7071L18.0404 9.70711Z"
        fill={color}
      />
    </svg>
  );
};

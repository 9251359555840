import React from "react";
import LayoutV2 from "../../components/LayoutV2";
import { Container, Typography } from "@mui/material";
import PlanGroups from "../../components/planGroups";
import CompareFeature from "./CompareFeature";
import ModalCheckout from "../../components/modelCheckout";
import ModalSuccess from "../../components/modalSuccess";
import Pay from "./pay";
import MonthlyCheck from "../../components/monthlyCheck";

const styles = {
  type: {
    fontFamily: "HelveticaNowDisplay",
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "56px",
    textAlign: "center",
    color: "#23262F",
    mt: "80px",
    mb: "34px",
  },
};

function PricingPage() {
  return (
    <LayoutV2>
      <Container>
        <Typography sx={styles.type}>Pricing</Typography>
        <MonthlyCheck />
        <PlanGroups />
        <CompareFeature />
        <Pay />
      </Container>
      <ModalCheckout />
      <ModalSuccess />
    </LayoutV2>
  );
}

export default PricingPage;

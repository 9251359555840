import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { userInfoSelector } from "store/user";
import shape from "assets/shape.svg";

export default function UserMenu({ show, onOpen }) {
  const userInfo = useSelector(userInfoSelector);

  if (show) {
    return (
      <Box
        aria-controls="primary-search-account-menu"
        onClick={onOpen}
        sx={{
          padding: "0px 16px",
          border: "2px solid #E6E8EC",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40px",
          borderRadius: "90px",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
            mr: "10px",
            width: "60px",
          }}
        >
          {/* <CustomAvatar size={24} avatar={user} /> */}
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "14px",
              color: "#23262F",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {userInfo?.firstName}
          </Typography>

          {/* <img src={coins} alt="icon"/> */}
        </Box>
        <img src={shape} alt="icon" />
        <Box></Box>
      </Box>
    );
  }

  return null;
}

import React from "react";

import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { TextField, Typography } from "@mui/material";

const TextFieldStyled = styled(TextField)({
  marginTop: 0,
  marginBottom: "8px",
  border: "none",
  input: {
    width: "100%",
    padding: "20px 24px",
    background: "#FFFFFF !important",
    borderRadius: "16px",
  },
  ".MuiInputAdornment-root": {
    marginRight: "0px",
  },

  ".MuiFormHelperText-root": {
    marginLeft: "20px",
    padding: 0,
  },

  ".MuiInputBase-root": {},
  fieldset: {
    borderRadius: "0px",
    height: "64px",
    border: "none",
    padding: "0",
  },
  "@media(max-width:900px)": {
    input: {
      marginLeft: "0px",
    },
    fieldset: {
      borderRadius: "8px",
      height: "64px",
      border: "none",
    },
  },
});

const AuthInput = ({ label, name, placeholder, formik, type }) => {
  return (
    <Box
      sx={{
        mb: 3,
        mt: label ? "-4px" : 3,
        "& > .css-9wy24x-MuiInputBase-root-MuiInput-root:before": {
          borderBottom: "none  !important",
        },
        "& .css-9wy24x-MuiInputBase-root-MuiInput-root:after": {
          borderBottom: "none  !important",
        },
      }}
    >
      {label && (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#6A7986",
            mb: "16px",
          }}
        >
          <label htmlFor={name}>
            {label}
            {name === "podcastName" ? "" : "*"}
          </label>
        </Typography>
      )}

      <TextFieldStyled
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        sx={{
          my: 1,
          width: "100%",
        }}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
      />
    </Box>
  );
};

export default AuthInput;

import axios from "utils/axiosConfig";

export const updateContent = async (docId, type, content) => {
  try {
    const { data } = await axios({
      method: "PUT",
      url: `/v1/up/processed_data/${docId}/${type}`,
      data: { content },
    });

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

import { lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Routes, Route } from "react-router-dom";
import DAIProtectedRoute from "../Auth/containers/ProtectedRoute";
import SuccessPage from "../../pages/Auth/success";
import PricingPage from "../../pages/pricing";
import PageLoader from "../../components/pageLoader";
import DownloadProgressBar from "../../pages/newViewTranscript/components/DownloadProgressBar";
import { getUserInfo } from "../../store/user";
import Auth from "../../pages/Auth/indexV2";

const Profile = lazy(() => import("../../pages/profile"));
const Dashboard = lazy(() => import("../../pages/dashboard/indexV2"));
const ViewTanscript = lazy(() => import("../../pages/newViewTranscript"));
const Upload = lazy(() => import("../../pages/newUpload/indexV2"));
const MainTopic = lazy(() => import("../../pages/mainTopic"));
const ResetPass = lazy(() => import("../../pages/Auth/resetPass"));
const TestFeedback = lazy(() => import("../../pages/testFeedback"));

export default function DAIRoutes({ isEnabled }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const token = userInfo?.token;
    const payload = token ? JSON.parse(atob(token.split(".")[1])) : null;

    if (userInfo !== null) {
      if (payload && payload.org_role === null) {
        dispatch(getUserInfo());
      }
    }
  }, [dispatch]);

  if (isEnabled) {
    return (
      <Routes>
        {/* PROTECTED ROUTES */}
        <Route element={<DAIProtectedRoute />}>
          <Route path="/view_transcript/:id" element={<ViewTanscript />} />
          <Route path="/main_topic/:id" element={<MainTopic />} />
          <Route path="/upload_transcript" element={<Upload />} />
          <Route path="/library" element={<Dashboard />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/plans" element={<PricingPage />} />
          <Route path="/testfeedback" element={<TestFeedback />} />
          <Route
            path="download"
            element={<PageLoader customLoader={<DownloadProgressBar />} />}
          />
          <Route path="auth" element={<Auth />}>
            <Route path="password-reset" element={<ResetPass />} />
            {/* RESET OR EMAIL VERIFICATION ROUTE */}
          </Route>
          <Route path="*" element={<Navigate to="/library" replace />} />
        </Route>

        {/* // TODO: 404 page  */}
      </Routes>
    );
  }

  return null;
}
